<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Programa</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/grupo-eventos" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveGrupoEventos">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="grupo_eventos.nombre_rias"
                    :disabled="disabled"
                    required
                  />
                </div>
                <div class="form-group col-sm-12">
                  <label>Color</label>
                  <Select2
                    v-model="grupo_eventos.color"
                    :options="options_colors"
                    required
                  />
                </div>
                <div class="form-group col-sm-12">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
export default {
  name: "gruposeventos_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    grupo_eventos: {
      idrias: "",
      nombre_rias: "",
      color: "",
    },
    options_colors: [
      { text: "Rojo", id: "marker-point-rojo.png" },
      { text: "Rosa", id: "marker-point-rosa.png" },
      { text: "Purpura", id: "marker-point-purpura.png" },
      { text: "Purpura Oscuro", id: "marker-point-purpura-oscuro.png" },
      { text: "Indigo", id: "marker-point-indigo.png" },
      { text: "Azúl", id: "marker-point-azul.png" },
      { text: "Azúl Claro", id: "marker-point-azul-claro.png" },
      { text: "Cyan", id: "marker-point-cyan.png" },
      { text: "Verde Azulado", id: "marker-point-verde-azulado.png" },
      { text: "Verde", id: "marker-point-verde.png" },
      { text: "Verde Claro", id: "marker-point-verde-claro.png" },
      { text: "Verde Lima", id: "marker-point-verde-lima.png" },
      { text: "Amarillo", id: "marker-point-amarillo.png" },
      { text: "Naranja", id: "marker-point-naranja.png" },
      { text: "Naranja Oscuro", id: "marker-point-naranja-oscuro.png" },
      { text: "Marrón", id: "marker-point-marron.png" },
      { text: "Gris", id: "marker-point-gris.png" },
    ],
  }),
  mounted() {
    this.getGrupoEventos();
  },
  computed: {},
  methods: {
    async getGrupoEventos() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(
            configApi + "grupo_eventos/grupos_eventos/" + this.$route.params.id,
            { headers }
          )
          .then((response) => {
            this.grupo_eventos.idrias = response.data.data.idrias;
            this.grupo_eventos.nombre_rias = response.data.data.nombre_rias;
            this.grupo_eventos.color = response.data.data.color;

            if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
              this.disabled = false;
              this.title = "Editar";
            }
          });
      }
    },
    async saveGrupoEventos() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi + "grupo_eventos/grupos_eventos/" + this.$route.params.id,
            this.grupo_eventos,
            { headers }
          )
          .then((response) => {
            this.getGrupoEventos();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(
            configApi + "grupo_eventos/grupos_eventos",
            this.grupo_eventos,
            { headers }
          )
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    resetForm() {
      this.grupo_eventos.idrias = "";
      this.grupo_eventos.nombre_rias = "";
      this.grupo_eventos.color = "";
    },
  },
};
</script>
