<template>
  <div ref='viewer'>
    <button id="downloadButton">Descargar</button>
  </div>
</template>

<script>
/* eslint-disable */
import FileSaver from 'file-saver'
import WebViewer from '@pdftron/pdfjs-express'
import ExpressUtils from '@pdftron/pdfjs-express-utils'

export default {
  name: 'WebViewer',
  props: {
    path: String,
    url: String
  },
  mounted: function () {
      WebViewer({
        disableFlattenedAnnotations: true,
        path: this.path,
        initialDoc: this.url, // replace with your own PDF file
      }, this.$refs.viewer).then((instance) => {
          // Create a new instance of the utility SDK
          const utils = new ExpressUtils();

          const { documentViewer, annotationManager } = instance.Core;

          // ... documentLoaded code from above

          // A function that gets called when the user clicks your download button.
          // This can be implemented any way you want.
          // In our example we will assume it is bounded to an '#download-button' button
          document.getElementById('downloadButton').onclick = () => {

            // Get the annotations and the documents data
            const xfdf = annotationManager.exportAnnotations({});
            const fileData =  documentViewer.getDocument().getFileData({});

            // Set the annotations and document into the Utility SDK, then merge them together
            const resp =  utils
              .setFile(fileData)
              .setXFDF(xfdf)
              .merge();

            // Get the resulting blob from the merge operation
            const mergedBlob =  resp.getBlob()

            // trigger a download for the user!
            FileSaver.saveAs(mergedBlob, 'myfile.pdf')
          }
      });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%; 
  height: 100vh;
}
</style>
