<template>
  <div>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <div class="row">
          <div class="form-group col-sm-12">
            <label>Seleccione Encuesta</label>
            <Select2
              v-model="encuesta"
              @change="
                disabled = false;
                loading = false;
                exportar = false;
                busqueda = true;
              "
              :options="options_encuestas"
              required
            />
          </div>
          <div class="form-group col-sm-12">
            <label>Seleccione Agente de Campo</label>
            <Select2
              v-model="agente"
              @change="
                disabled = false;
                loading = false;
                exportar = false;
                busqueda = true;
              "
              :options="options_agentes"
              required
            />
          </div>
          <div class="col-md-6">
            <label>Seleccione Fecha Inicio</label>

            <b-input-group class="mb-3">
              <b-form-input
                v-model="options_fech.fecha_inicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="true"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="options_fech.fecha_inicio"
                  button-only
                  right
                  locale="co-CO"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div class="col-md-6">
            <label>Seleccione Fecha Final</label>

            <b-input-group class="mb-3">
              <b-form-input
                v-model="options_fech.fecha_final"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="true"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="options_fech.fecha_final"
                  button-only
                  right
                  locale="co-CO"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="form-group col-sm-12">
            <center>
              <button
                v-show="busqueda"
                @click="getInforme"
                class="btn btn-success"
                :disabled="disabled"
              >
                <i class="mdi mdi-magnify"></i> Buscar Datos
              </button>

              <b-button variant="primary" disabled v-show="loading">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Buscando datos ...
              </b-button>

              <download-excel
                v-show="exportar"
                class="btn btn-success"
                :data="json_data"
                :name="
                  'informe_general_' + this.encuesta + '_' + Date.now() + '.xls'
                "
              >
                <i class="mdi mdi-file-excel"></i> Exportar Datos
              </download-excel>
              &nbsp;
              <button @click="resetForm" class="btn btn-danger">
                <i class="mdi mdi-delete"></i>
              </button>
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";

export default {
  data: () => ({
    encuesta: "",
    agente: 0,
    validafecha: "",
    validafechaf: "",
    disabled: true,
    busqueda: true,
    loading: false,
    exportar: false,
    options_encuestas: [],
    options_agentes: [],
    options_fech: {
      fecha_inicio: "",
      fecha_final: "",
    },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  mounted() {
    this.getEncuestas();
    this.getAgentes();
  },
  methods: {
    async getEncuestas() {
      return await this.axios
        .get(configApi + "encuestas/encuestas", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_encuestas.push({
              id: data[i].idproyecto,
              text: data[i].nombreproyecto,
            });
          }
        });
    },
    async getAgentes() {
      return await this.axios
        .get(configApi + "usuarios/usuarios_x_roles_informe/4", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            var estado = ""

            if (data[i].estado == 1) {
              estado = "ACTIVO"
            } else {
              estado = "INACTIVO"
            } 
              
            this.options_agentes.push({
              id: data[i].id,
              text: data[i].nombres + " " + data[i].apellidos + "- " + estado,
            });
          }
        });
    },
    async getInforme() {
      if (this.encuesta != "") {
        this.busqueda = false;
        this.loading = true;

        if (this.options_fech.fecha_inicio == "") {
          this.validafecha = "0";
        } else {
          this.validafecha = this.options_fech.fecha_inicio;
        }

        if (this.options_fech.fecha_final == "") {
          this.validafechaf = "0";
        } else {
          this.validafechaf = this.options_fech.fecha_final;
        }

        return await this.axios
          .get(
            configApi +
              "informes/general_encuesta/" +
              this.encuesta +
              "/1/" +
              this.agente +
              "/" +
              this.validafecha +
              "/" +
              this.validafechaf,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.data.length > 0) {
              this.json_data = response.data.data;

              this.json_data.forEach(function (item) {
                if (
                  item.latitud != null &&
                  item.latitud != "" &&
                  item.latitud != "LATITUD"
                ) {
                  //item.latitud = item.latitud.replace(".",",");
                  item.latitud = "'" + item.latitud;
                }

                if (
                  item.longitud != null &&
                  item.longitud != "" &&
                  item.longitud != "LONGITUD"
                ) {
                  //item.longitud = item.longitud.replace(".",",");
                  item.longitud = "'" + item.longitud;
                }
              });

              this.loading = false;
              this.exportar = true;
            } else {
              this.busqueda = true;
              this.loading = false;
              this.exportar = false;
              this.$Fun.alertaAccion(
                "warning",
                "No se encontraron registros...",
                false
              );
            }
          });
      }
    },
    resetForm() {
      this.encuesta = "";
      this.agente = 0;
      this.disabled = true;
      this.loading = false;
      this.exportar = false;
      this.busqueda = true;
      this.options_fech.fecha_inicio = "";
      this.options_fech.fecha_final = "";
    },
  },
};
</script>
<style>
.select2-container {
  min-width: 100% !important;
}
</style>
