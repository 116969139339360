<template>
    <div>

        <div class="card" style="max-width: 70%; display:block;margin:auto;">
            <div class="card-header"><b>Registro Datos Personales</b></div>
            <div class="card-body text-dark">

                <form @submit.prevent="saveDatos" method="POST" enctype="multipart/form-data">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <label>Nombres</label>
                            <input type="text" class="form-control" v-model="users.nombres" required>
                        </div> 
                        <div class="form-group col-sm-6">
                            <label>Apellidos</label>
                            <input type="text" class="form-control" v-model="users.apellidos" required>
                        </div>                              
                        <div class="form-group col-sm-6">
                            <label>Cédula de Ciudadanía</label>
                            <input type="number" class="form-control" v-model.number="users.documento" required>
                        </div>   
                        <div class="form-group col-sm-6">
                            <label>Teléfono</label>
                            <input type="number" class="form-control" v-model="users.telefono" required>
                        </div>                             
                        <div class="form-group col-sm-6">
                            <label>Nueva Contraseña</label>
                            <input type="password" class="form-control" v-model="users.password">
                        </div>                          
                        <div class="form-group col-sm-6">
                            <label>Repetir Nueva Contraseña</label>
                            <input type="password" class="form-control" v-model="users.new_password">
                        </div>                            
                        <div class="form-group col-sm-12">
                            <center>                                        
                                <button type="submit" class="btn btn-success">Guardar</button>                                    
                            </center>
                        </div>                                         
                    </div>
                </form>                                 

            </div>
        </div> 

    </div>
</template>
<script>

import {headers} from './../../../api/headers'
import {configApi} from './../../../api/configApi'

export default {
    name: 'datos_personales',
    data: () => ({
        disabled: false,
        foto_prev: '',
        title: '',
        items: [],
        roles: [],
        editedIndex: -1,
        users: {
            nombres: '',
            apellidos: '',        
            documento: '',
            telefono: '',
            password: '',
            new_password: ''
        },     
    }),
    mounted() {
        
    },
    created () {
        this.getDatosPersonales()
    },    
    methods: {      
        async getDatosPersonales() {

            return await this.axios.get(configApi+'usuarios/datos_personales', {headers})
            .then(response => {                
                
                this.users.nombres = response.data.data.nombres
                this.users.apellidos = response.data.data.apellidos
                this.users.documento = response.data.data.documento
                this.users.telefono = response.data.data.telefono


            })

        },
        async saveDatos () {     

            return await this.axios.post(configApi+'usuarios/datos_personales', this.users, {headers})
            .then(response => {                
                this.getDatosPersonales()
                this.$Fun.alertaAccion('success', response.data.message, false)
                this.users.password = ''
                this.users.new_password = ''
            })   

        },         
    },    
    computed: {
        image() {
            return this.foto_prev;
        }        
    },   
}
</script>