<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Seguimiento Profesional</b></div>
      <div class="card-body text-dark">
        <router-link to="/seguimiento-profesional" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveSeguimiento">
          <div class="card">
            <div class="card-body">
              <div class="row" style="margin: auto">
                <b-card-group deck>
                  <b-card
                    border-variant="info"
                    header="Información Paciente"
                    header-bg-variant="info"
                    header-text-variant="white"
                    class="col-sm-12 col-md-12 col-lg-6"
                  >
                    <div class="row">
                      <div class="form-group col-sm-6">
                        <label>Nombres Paciente</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.nombres"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Apellidos Paciente</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.apellidos"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-4">
                        <label>Género Paciente</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.genero"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-4">
                        <label>Tipo Documento</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.tipodoc"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-4">
                        <label>Documento Identidad</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.documento"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Correo Electrónico</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.email"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Número Celular</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="persona.celular"
                          :disabled="disabled"
                          required
                        />
                      </div>
                    </div>
                  </b-card>
                  <b-card
                    border-variant="info"
                    header="Información Encuesta"
                    header-bg-variant="info"
                    header-text-variant="white"
                    class="col-sm-12 col-md-12 col-lg-6"
                  >
                    <div class="row">
                      <div class="form-group col-sm-12">
                        <label>Nombre Encuesta</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="encuesta.nombre"
                          :disabled="disabled"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-12">
                        <label>Descripción</label>
                        <b-form-textarea
                          v-model="encuesta.descripcion"
                          rows="3"
                          max-rows="6"
                          :disabled="disabled"
                        ></b-form-textarea>
                      </div>
                    </div>
                  </b-card>
                </b-card-group>
              </div>
              <br />
              <b-card
                border-variant="info"
                header="Estado ante ADRES"
                header-bg-variant="info"
                header-text-variant="white"
                class="col-sm-12 col-md-12 col-lg-12"
              >
                <div class="row">
                  <div class="form-group col-sm-12">
                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        id="radio-group-1"
                        v-model="selected"
                        :options="options_estado"
                        :aria-describedby="ariaDescribedby"
                        name="flavour-1"
                      ></b-form-radio-group>
                    </b-form-group>
                    <a
                      href="https://www.adres.gov.co/consulte-su-eps"
                      v-b-tooltip.hover.bottom="'Click Para Acceder'"
                      target="_blank"
                      ><img
                        class="rounded"
                        style="cursor: pointer"
                        width="90px"
                        height="80px"
                        src="../../assets/adres/logo.jpg"
                        alt=""
                    /></a>
                  </div>
                </div>
              </b-card>
              <br />
              <b-card
                border-variant="info"
                header="Seguimiento Eventos Encontrados"
                header-bg-variant="info"
                header-text-variant="white"
                class="col-sm-12"
              >
                <div class="row">
                  <!-- <div class="form-group col-sm-12">
                    <label>Nombre Enfermedad</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="enfermedad.enfermedad"
                      :disabled="disabled"
                      required
                    />
                  </div> -->

                  <div class="form-group col-sm-12">
                    <label>Nombre Evento </label>
                    <select
                      v-model="enfermedad.enfermedad"
                      class="form-control"
                      @change="seleccionarRuta()"
                    >
                      <option
                        :value="enfe.ideventosalud"
                        v-for="enfe in enfermedades"
                        v-bind:key="enfe.ideventosalud"
                      >
                        {{ enfe.evento }}
                      </option>
                    </select>
                  </div>

                  <div class="col-sm-12">
                    <b-table
                      class="table responsive table-bordered"
                      stacked="md"
                      show-empty
                      sticky-header
                      head-variant="light"
                      small
                      :items="listado_rutas"
                      :fields="fields"
                    >
                      <template #cell(actions)="row">
                        <b-modal
                          :id="'procesos' + row.item.ideventosalud"
                          size="xl"
                          scrollable
                          :title="title"
                          hide-footer
                        >
                          <b-button
                            class="btn btn-success"
                            @click="
                              saveEventoRuta(
                                row.item.idruta,
                                row.item.ideventosalud
                              )
                            "
                            style="float: right"
                            ><i class="mdi mdi-content-save"></i>
                            Guardar</b-button
                          >
                          <br /><br />
                          <table
                            class="table table-hover thead-color table-striped table-bordered table-sm w-100 tables_menus"
                          >
                            <thead>
                              <tr>
                                <th>Nombre Flujograma</th>
                                <th>Descripción</th>
                                <th>Estado</th>
                                <th>Registrar Seguimiento</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="prorutas in listado_prorutas"
                                v-bind:key="prorutas.idruta"
                              >
                                <td>{{ prorutas.ruta }}</td>
                                <td>{{ prorutas.proruta }}</td>
                                <td class="text-center">
                                  <div v-if="prorutas.estadofecha == null">
                                    <button class="btn btn-danger btn-sm">
                                      Pendiente
                                    </button>
                                  </div>
                                  <div v-else>
                                    <button class="btn btn-success btn-sm">
                                      {{ prorutas.estadofecha }}
                                    </button>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <b-form-textarea
                                    v-model="prorutas.observaciones"
                                    placeholder="Ingrese una observación..."
                                    rows="3"
                                    max-rows="4"
                                  ></b-form-textarea>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-modal>
                        <b-button
                          class="btn btn-success"
                          @click="
                            $bvModal.show('procesos' + row.item.ideventosalud);
                            showModal(true, 'Ver Intervenciones');
                            getProRutas(
                              row.item.idruta,
                              row.item.ideventosalud
                            );
                          "
                          v-b-tooltip.hover
                          title="Ver Intervenciones"
                          ><i class="mdi mdi-eye"></i></b-button
                        >&nbsp;
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";
export default {
  name: "municipios_crear",
  data: () => ({
    disabled: false,
    selected: "",
    title: "",
    enfermedades: [],
    options_enfermedades: [],
    persona: {
      documento: "",
      nombres: "",
      apellidos: "",
      celular: "",
      email: "",
      genero: "",
      tipodoc: "",
    },
    options_estado: [
      { text: "Verificado", value: "1" },
      { text: "Por Verificar", value: "0" },
    ],
    encuesta: {
      nombre: "",
      descripcion: "",
    },
    enfermedad: {
      enfermedad: "",
    },
    listado_rutas: [],
    listado_prorutas: [],
    fields: [
      { key: "ruta", label: "Flujograma de Atención", sortable: true },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
  }),
  mounted() {
    this.getDatosPaciente();
    this.getDatosEncuesta();
    this.getEnfermedad();
  },
  computed: {},
  methods: {
    async getDatosPaciente() {
      return await this.axios
        .get(configApi + "personas/personas/" + this.$route.params.persona, {
          headers,
        })
        .then((response) => {
          this.persona.documento = response.data.data[0].documento;
          this.persona.tipodoc = response.data.data[0].nombre_doc;
          this.persona.nombres = response.data.data[0].nombres;
          this.persona.apellidos = response.data.data[0].apellidos;
          this.persona.genero =
            response.data.data[0].genero == 1 ? "FEMENINO" : "MASCULINO";
          this.persona.celular = response.data.data[0].celular;
          this.persona.email = response.data.data[0].email;

          this.disabled = true;
        });
    },
    async getDatosEncuesta() {
      // 0 = Vigilacontigo, 1 = Sivigila
      if (this.$route.params.accion == 0) {
        return await this.axios
          .get(
            configApi + "encuestas/encuestas/" + this.$route.params.proyecto,
            { headers }
          )
          .then((response) => {
            this.encuesta.nombre = response.data.data.nombreproyecto;
            this.encuesta.descripcion = response.data.data.descripcion;
          });
      } else {
        return await this.axios
          .get(
            configApi + "sivigila/encuestas_svg/" + this.$route.params.proyecto,
            { headers }
          )
          .then((response) => {
            this.encuesta.nombre = response.data.data[0].nombre;
          });
      }
    },
    async getEnfermedad() {
      return await this.axios
        .get(
          configApi +
            "seguimiento_profesional/enfermedad_persona/" +
            this.$route.params.persona,
          { headers }
        )
        .then((response) => {
          this.enfermedades = response.data.data;
          this.enfermedad.enfermedad = response.data.data[0].ideventosalud;

          var data_tmp = response.data.data[0].ideventosalud;
          var listado_rutas_2 = [];

          this.enfermedades.forEach(function (item) {
            if (item.ideventosalud == data_tmp) {
              listado_rutas_2.push({
                ideventosalud: item.ideventosalud,
                created_at: item.created_at,
                deleted_at: item.deleted_at,
                evenruta: item.evenruta,
                evento: item.evento,
                idevenruta: item.idevenruta,
                idriasevento: item.idriasevento,
                idruta: item.idruta,
                rias: item.rias,
                ruta: item.ruta,
                updated_at: item.updated_at,
              });
            }
          });

          this.listado_rutas = listado_rutas_2;
        });
    },
    async getSeguimientoPersona() {
      if (this.$route.params.persona > 0 && this.$route.params.proyecto > 0) {
        return await this.axios
          .get(configApi + "configuracion/barrios/" + this.$route.params.id, {
            headers,
          })
          .then((response) => {
            //
            if (
              this.$route.params.persona > 0 &&
              this.$route.params.accion == 0
            ) {
              this.disabled = false;
              this.title = "Editar";
            }
          });
      }
    },
    async getProRutas(idruta, idevento) {
      this.listado_prorutas = [];
      return await this.axios
        .get(
          configApi +
            "seguimiento_profesional/eventos_rutas_persona/" +
            idruta +
            "/" +
            this.$route.params.persona +
            "/" +
            this.$route.params.proyecto +
            "/" +
            idevento,
          { headers }
        )
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.listado_prorutas.push({
              idruta: data[i].idruta,
              ruta: data[i].ruta,
              proruta: data[i].proruta,
              idproruta: data[i].idproruta,
              idpersona: data[i].idpersona,
              idproyecto: data[i].idproyecto,
              ideventosalud: data[i].ideventosalud,
              estadofecha: data[i].estadofecha,
              observaciones: data[i].observaciones,
              idseg: data[i].idseg,
              profesional: data[i].profesional,
            });
          }
        });
    },
    showModal(disabled, title) {
      this.disabled = disabled;
      this.title = title;
    },
    async saveEventoRuta(ruta, evento) {
      if (
        this.$route.params.persona > 0 &&
        this.$route.params.proyecto > 0 &&
        this.$route.params.accion == 0
      ) {
        return await this.axios
          .post(
            configApi + "seguimiento_profesional/seguimiento",
            this.listado_prorutas,
            { headers }
          )
          .then((response) => {
            this.getProRutas(ruta, evento);
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    seleccionarRuta() {
      var data_tmp = this.enfermedad.enfermedad;
      var listado_rutas_2 = [];
      this.listado_rutas = [];

      this.enfermedades.forEach(function (item) {
        if (item.ideventosalud == data_tmp) {
          listado_rutas_2.push({
            ideventosalud: item.ideventosalud,
            created_at: item.created_at,
            deleted_at: item.deleted_at,
            evenruta: item.evenruta,
            evento: item.evento,
            idevenruta: item.idevenruta,
            idriasevento: item.idriasevento,
            idruta: item.idruta,
            rias: item.rias,
            ruta: item.ruta,
            updated_at: item.updated_at,
          });
        }
      });

      this.listado_rutas = listado_rutas_2;
    },
  },
};
</script>
<style>
.select2-container {
  min-width: 100% !important;
}
</style>
