<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Encuesta</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/encuestas" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveEncuesta">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <span
                    >Nombre de la Encuesta
                    <span class="text-danger">*</span></span
                  >
                  <input
                    type="text"
                    class="form-control"
                    :disabled="disabled"
                    v-model="model_proyectos.nombreproyecto"
                    required
                  />
                </div>

                <div class="col-md-12">
                  <span
                    >Descripción de la Encuesta
                    <span class="text-danger">*</span></span
                  >
                  <textarea
                    rows="4"
                    class="form-control"
                    :disabled="disabled"
                    required
                    v-model="model_proyectos.descripcion"
                  ></textarea>
                </div>
                <br /><br /><br /><br /><br /><br /><br />
                <div class="col-md-3">
                  <span>Fecha Inicio</span>
                  <input
                    type="date"
                    :disabled="disabled"
                    class="form-control cr-pointer flatpicker"
                    v-model="model_proyectos.fechainicio"
                  />
                </div>
                <div class="col-md-3">
                  <span>Fecha Fin</span>
                  <input
                    type="date"
                    :disabled="disabled"
                    class="form-control cr-pointer flatpicker"
                    v-model="model_proyectos.fechafin"
                  />
                </div>
                <div class="col-md-2 text-right">
                  <span>Activo</span>
                </div>
                <div class="col-md-4">
                  <div class="col-md-12">
                    <b-form-radio-group
                      v-model="model_proyectos.activo"
                      :disabled="disabled"
                      :options="options_sino"
                      class="mb-3"
                    ></b-form-radio-group>
                  </div>
                </div>
                <div class="col-md-12"><hr /></div>
                <br /><br /><br />
                <div class="col-md-10 text-right">
                  <span>Aproximado de personas a encuestar</span>
                </div>
                <div class="col-md-2">
                  <input
                    type="number"
                    class="form-control"
                    :disabled="disabled"
                    v-model="model_proyectos.minregistros"
                  />
                </div>
                <!-- Asignar Agentes -->
                <div class="col-md-12">
                  <label class="typo__label"
                    >Asignar Agentes a la Encuesta</label
                  >
                  <multiselect
                    v-model="model_proyectos.agentes"
                    :disabled="disabled"
                    tag-placeholder="Add this as new tag"
                    placeholder="Busqueda"
                    label="text"
                    track-by="id"
                    :options="options_agentes"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
                </div>
                <br /><br /><br /><br />
                <!-- Asignar Profesionales -->
                <div class="col-md-12">
                  <label class="typo__label"
                    >Asignar Profesionales a la Encuesta</label
                  >
                  <multiselect
                    v-model="model_proyectos.profesionales"
                    :disabled="disabled"
                    tag-placeholder="Add this as new tag"
                    placeholder="Busqueda"
                    label="text"
                    track-by="id"
                    :options="options_profesionales"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
                </div>
                <br /><br /><br /><br />
                <div class="form-group col-sm-12">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";
export default {
  name: "municipios_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    model_proyectos: {
      idproyecto: "",
      nombreproyecto: "",
      decripcion: "",
      fechainicio: "",
      fechafin: "",
      activo: 1,
      minregistros: 0,
      dat_personales: 1,
      agentes: [],
      profesionales: [],
    },
    options_sino: [
      { value: 1, text: "Si" },
      { value: 0, text: "No" },
    ],
    options_agentes: [],
    options_profesionales: [],
  }),
  mounted() {
    this.getDatosEncuesta();
    this.getAgentesCampo();
    this.getProfesionales();
  },
  computed: {},
  methods: {
    getAgentesCampo() {
      this.axios
        .get(
          configApi + "encuestas/agentes_encuestas/" + this.$route.params.id,
          { headers }
        )
        .then((response) => {
          this.llenar_select(
            response.data.data,
            1,
            this.model_proyectos.agentes
          );
        });

      this.axios
        .get(configApi + "usuarios/usuarios_x_roles/4", { headers })
        .then((response) => {
          this.llenar_select(response.data.data, 0, this.options_agentes);
        });
    },
    getProfesionales() {
      this.axios
        .get(
          configApi +
            "encuestas/profesionales_encuestas/" +
            this.$route.params.id,
          { headers }
        )
        .then((response) => {
          this.llenar_select(
            response.data.data,
            1,
            this.model_proyectos.profesionales
          );
        });

      this.axios
        .get(configApi + "usuarios/usuarios_x_roles/2", { headers })
        .then((response) => {
          this.llenar_select(response.data.data, 0, this.options_profesionales);
        });
    },
    llenar_select(data, param, form) {
      for (var i = 0, max = data.length; i < max; i += 1) {
        let documento = data[i].documento == null ? "N/A" : data[i].documento;

        if (param == 0) {
          form.push({
            id: data[i].id,
            text:
              "(" +
              documento +
              ") " +
              data[i].nombres +
              " " +
              data[i].apellidos,
          });
        } else {
          form.push({
            id: data[i].id,
            text:
              "(" +
              documento +
              ") " +
              data[i].nombres +
              " " +
              data[i].apellidos,
          });
        }
      }
    },
    async getDatosEncuesta() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(configApi + "encuestas/encuestas/" + this.$route.params.id, {
            headers,
          })
          .then((response) => {
            this.model_proyectos.idproyecto = response.data.data.idproyecto;
            this.model_proyectos.nombreproyecto =
              response.data.data.nombreproyecto;
            this.model_proyectos.descripcion = response.data.data.descripcion;
            this.model_proyectos.fechainicio = response.data.data.fechainicio;
            this.model_proyectos.fechafin = response.data.data.fechafin;
            this.model_proyectos.activo = response.data.data.activo;
            this.model_proyectos.minregistros = response.data.data.minregistros;
            this.model_proyectos.dat_personales =
              response.data.data.dat_personales;

            if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
              this.disabled = false;
              this.title = "Editar";
            } else {
              this.disabled = true;
              this.title = "Ver";
            }
          });
      }
    },
    async saveEncuesta() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi + "encuestas/encuestas/" + this.$route.params.id,
            this.model_proyectos,
            { headers }
          )
          .then((response) => {
            this.getDatosEncuesta();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "encuestas/encuestas", this.model_proyectos, {
            headers,
          })
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    resetForm() {
      this.model_proyectos.idproyecto = "";
      this.model_proyectos.nombreproyecto = "";
      this.model_proyectos.descripcion = "";
      this.model_proyectos.fechainicio = "";
      this.model_proyectos.fechafin = "";
      this.model_proyectos.activo = 1;
      this.model_proyectos.minregistros = 0;
      this.model_proyectos.dat_personales = "";
      this.model_proyectos.agentes = [];
      this.model_proyectos.profesionales = [];
    },
  },
};
</script>
