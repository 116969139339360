<template>
    <div>
        <b-tabs content-class="mt-3" align="center">
            <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                <component :is="item.component" :ref="item.component" />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
import listadoUsuarios from './users/listado.vue'
import privilegios from './privilegios/listado.vue'
import menus from './menus/listado.vue'
import roles from './roles/listado.vue'

export default {
    name: 'listado_usuarios',
    components: {
        listadoUsuarios,
        privilegios,
        menus,
        roles
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Usuarios', component: 'listadoUsuarios' },
          { tab: 'Permisos', component: 'privilegios' },
          { tab: 'Menús', component: 'menus' },
          { tab: 'Roles', component: 'roles' },
        ],
    })
}
</script>