<template>
  <div class="iframe-container">
    <div style="text-align: right; margin-top: 10px; margin-right: 10px; margin-bottom: 30px;">
      <button
          @click="descargarInforme"
          class="btn btn-success"
      >
        <i class="mdi mdi-magnify"></i> Descargar informe
      </button>
    </div>
    <iframe width="100%" height="600px"
            src="https://lookerstudio.google.com/embed/reporting/bf1b27b5-777e-4df3-99c2-c426851ad52e/page/mE0pD"
            frameborder="0" style="border:0" allowfullscreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    descargarInforme() {
      window.location.href = 'https://observatoriostorage.unillanos.edu.co/data-scraping-dags/Outputs/gender_violence/gender_violence.html';
    }
  }
}
</script>
