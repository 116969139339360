<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Agregar Eventos</b></div>

      <div class="card-body text-dark">
        <b-modal :id="'menu'" size="lg" title="Agregar Evento" hide-footer>
          <form>
            <div class="row">
              <div class="col-md-4">
                <label>Nombres</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="add_enfermedad.nombreenc"
                  disabled="disabled"
                />
              </div>
              <div class="col-md-4">
                <label>Apellidos</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="add_enfermedad.apelli"
                  disabled="disabled"
                />
              </div>
              <div class="col-md-4">
                <label>Documento</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="add_enfermedad.docum"
                  disabled="disabled"
                />
              </div>
              <br /><br /><br /><br />
              <div class="col-md-6">
                <span>Seleccione Programa:</span>
                <Select2
                  @change="getEnfermedad"
                  v-model="add_enfermedad.grupoevento"
                  :options="options_grupoeventos"
                />
              </div>
              <div class="col-md-6">
                <span>Seleccione Evento:</span>
                <Select2
                  v-model="add_enfermedad.idriasevento"
                  :options="options_enfermedades"
                />
              </div>
            </div>
            <br />
            <center>
              <button
                type="submit"
                class="btn btn-success"
                :disabled="disabled"
                @click.prevent="saveEnfermedad()"
              >
                Guardar
              </button>
            </center>
          </form>
        </b-modal>

        <div class="row">
          <div class="col-sm-2">
            <router-link :to="'/seguimiento-profesional/'" class="btn btn-info"
              ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
            ><br /><br />
          </div>

          <div class="form-group col-sm-4">
            <select
              v-model="encuesta"
              class="form-control"
              required
              @change="cambioEncuesta"
            >
              <option value="" disabled selected>Seleccione Encuesta</option>
              <option
                :value="enc.id"
                v-for="enc in options_encuestas"
                v-bind:key="enc.id"
              >
                {{ enc.id + "-" + enc.text }}
              </option>
            </select>
          </div>

          <div class="col-sm-4">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="text"
              placeholder="Busqueda Nombre Encuestado"
              @keyup="filtrarListado()"
            ></b-form-input>
          </div>

          <div class="col-sm-2">
            <b-button
              class="btn btn-info"
              @click="actualizarDatos()"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <div class="table-responsive" style="height: 400px">
          <table
            v-if="vTabla"
            class="table responsive table-bordered"
            id="table_field"
            stacked="md"
            small
          >
            <thead>
              <tr>
                <th>Agregar Evento</th>
                <th v-for="(item, i) in fields" v-bind:key="i">{{ item }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(rows, i) in items" v-bind:key="i">
                <td align="center">
                  <b-button
                    class="btn btn-info"
                    @click="
                      $bvModal.show('menu');
                      abrirModal(rows);
                    "
                    v-b-tooltip.hover
                    title="Agregar Evento"
                  >
                    <i class="mdi mdi-checkbox-marked-circle"></i>
                  </b-button>
                </td>
                <td v-for="(row, i) in rows" v-bind:key="i">
                  <div v-if="validarImagen(row) == true">
                    <a :href="row" class="link-primary" target="_blank">Ver Imagen</a>
                  </div>
                  <div v-else>{{ row }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#table_field thead tr th {
  width: 1% !important;
  white-space: nowrap !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #343a40 !important;
  color: white !important;
  font-size: small !important;
}
.select2-container {
  min-width: 100% !important;
}
.con-enfermedad {
  background-color: #7986cb;
}
</style>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";

export default {
  name: "listado_encuestas",
  data: () => ({
    title: "",
    encuesta: "",
    filter: "",
    disabled: false,
    loading: false,
    vTabla: false,
    options_enfermedades: [],
    options_grupoeventos: [],
    fields: [],
    items: [],
    itemsfilters: [],
    options_encuestas: [],
    selected: "",
    add_enfermedad: {
      grupoevento: "",
      idriasevento: "",
      nombreenc: "",
      apelli: "",
      docum: "",
      idpersona: "",
      idencu: "",
    },
  }),
  created() {
    this.getEncuestas();
    this.getGrupoEventos();
  },

  methods: {
    async getTitulos() {
      this.fields = [];
      return await this.axios
        .get(configApi + "informes/general_encuesta_titulos", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.fields = data[i];
            this.fields = Object.values(this.fields);
          }
        });
    },
    async getPreguntasRespuestas() {
      this.fields = [];
      this.items = [];
      this.itemsfilters = [];
      return await this.axios
        .get(
          configApi + "informes/general_encuesta_preg/" + this.encuesta + "/0",
          { headers }
        )
        .then((response) => {
          this.getTitulos();
          this.loading = false;
          if (response.data.data.length > 0) {
            this.items = response.data.data;
            this.itemsfilters = response.data.data;
            this.vTabla = true;
          } else {
            this.$Fun.alertaAccion("info", "No existen datos para la encuesta seleccionada", false);
            this.vTabla = false;
          }
        });
    },
    abrirModal(data) {
      this.add_enfermedad.idriasevento = data.idriasevento;
      this.add_enfermedad.nombreenc = data.nombreenc;
      this.add_enfermedad.apelli = data.apellidosenc;
      this.add_enfermedad.docum = data.cc;
      this.add_enfermedad.grupoevento = "";
      this.add_enfermedad.idriasevento = "";
      this.add_enfermedad.idpersona = data.idpersona;
      this.add_enfermedad.idencu = data.idenc;
    },
    async saveEnfermedad() {
      let newData = {
        idpersona: this.add_enfermedad.idpersona,
        idriasevento: this.add_enfermedad.idriasevento,
        idenc: this.add_enfermedad.idencu,
        idproyecto: this.encuesta,
      };

      return await this.axios
        .post(configApi + "encuestas/respuestas/respuestas", newData, {
          headers,
        })
        .then((response) => {
          this.$bvModal.hide("menu");
          this.$Fun.alertaAccion("success", response.data.message, false);
        });
    },
    async getEnfermedad() {
      this.options_enfermedades = [];

      return await this.axios
        .get(
          configApi +
            "grupo_eventos/enfermedades_x_grupoevento/" +
            this.add_enfermedad.grupoevento,
          { headers }
        )
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_enfermedades.push({
              id: data[i].idriasevento,
              text: data[i].evento,
            });
          }
        });
    },
    async getGrupoEventos() {
      return await this.axios
        .get(configApi + "grupo_eventos/grupos_eventos", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_grupoeventos.push({
              id: data[i].idrias,
              text: data[i].nombre_rias,
            });
          }
        });
    },
    async getEncuestas() {
      return await this.axios
        .get(configApi + "encuestas/encuestas", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_encuestas.push({
              id: data[i].idproyecto,
              text: data[i].nombreproyecto,
            });
          }
        });
    },
    cambioEncuesta() {
      this.loading = true;
      //this.getTitulos();
      this.getPreguntasRespuestas();
    },
    filtrarListado() {
      this.items = this.itemsfilters;
      if (this.filter != "" && this.filter) {
        this.items = this.items.filter((item) => {
          return item.nombreenc
            .toUpperCase()
            .includes(this.filter.toUpperCase());
        });
        return this.items;
      }
    },
    actualizarDatos() {
      if (this.encuesta != "") {
        this.filter = "";
        this.getPreguntasRespuestas();
      }
    },
    validarImagen(valor) {
      if (valor != null && valor != "") {
        var valor2 = valor.split(":");
        if (valor2[0] == "http") {
          console.log(valor);
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
