import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import error_page from '@/components/404'
import login from '@/views/auth/login'
import logout from '@/views/auth/logout'
import datospersonales from '@/views/usuarios/datos_personales/formulario'

//Municipios
import municipios_crear from '@/views/configuracion/municipios/formulario'
import comunas_crear from '@/views/configuracion/comunas/formulario'
import barrios_crear from '@/views/configuracion/barrios/formulario'

//Encuestas
import encuestas from '@/views/encuestas/listado'
import encuestas_crear from '@/views/encuestas/formulario'
import vista_previa from '@/views/encuestas/vista_previa'
import vista_preguntas from '@/views/encuestas/listado_preguntas'

//GrupoEventos
import grupoeventos_crear from '@/views/grupo_eventos/grupos_eventos/formulario'
import enfermedades_crear from '@/views/grupo_eventos/enfermedades/formulario'
import rutasatencion_crear from '@/views/grupo_eventos/rutas_atencion/formulario'
import eventosrutas_crear from '@/views/grupo_eventos/eventos_rutas/formulario'

//Seguimiento Epidemiológico
import seguimiento from '@/views/seguimiento/listado'
import seguimiento_crear from '@/views/seguimiento/formulario'

import sivigila from '@/views/sivigila/index'
import sivigila_subir from '@/views/sivigila/formulario'
import sivigila_agregar from '@/views/sivigila/formulario2'
import sivigila_tabla from '@/views/sivigila/tabla'

import sivigila_parametros_crear from '@/views/sivigila/parametros/formulario'
import sivigila_parametros from '@/views/sivigila/parametros/listado'

const router = new VueRouter({
    mode: 'history',
    fallback: false,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        {
            path: '/',
            name: 'raiz',            
            component: login
        },
        {
            path: '/404',
            name: 'error_page',
            component: error_page
        },
        {
            path: '/login',
            name: 'login',
            component: login
        },
        {
            path: '/logout',
            name: 'logout',
            component: logout,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: require('@/views/dashboard').default,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/usuarios-sistema',
            name: 'usuarios',
            component: require('@/views/usuarios').default,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/datos-personales',
            name: 'datos_personales',
            component: datospersonales,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        }, 
        {
            path: '/configuracion',
            name: 'configuracion',
            component: require('@/views/configuracion').default,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },     
        {
            path: '/configuracion/municipios/crear',
            name: 'municipios_crear',
            component: municipios_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        }, 
        {
            path: '/configuracion/municipios/editar/:id/:accion',
            name: 'municipios_editar',
            component: municipios_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        }, 
        {
            path: '/configuracion/comunas/crear',
            name: 'comunas_crear',
            component: comunas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        }, 
        {
            path: '/configuracion/comunas/editar/:id/:accion',
            name: 'comunas_editar',
            component: comunas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/configuracion/barrios/crear',
            name: 'barrios_crear',
            component: barrios_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        }, 
        {
            path: '/configuracion/barrios/editar/:id/:accion',
            name: 'barrios_editar',
            component: barrios_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        }, 
        {
            path: '/encuestas',
            name: 'encuestas',
            component: encuestas,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            },
        }, 
        {
            path: '/encuestas/crear',
            name: 'encuestas_crear',
            component: encuestas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/encuestas/ver/:id',
            name: 'encuestas_ver',
            component: encuestas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/encuestas/editar/:id/:accion',
            name: 'encuestas_editar',
            component: encuestas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/encuestas/preguntas/:id', //:id (idproyecto)
            name: 'preguntas',
            component: require('@/views/preguntas').default,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/encuestas/preguntas/vista-previa/:id', //:id (idproyecto)
            name: 'vista_previa',
            component: vista_previa,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/encuestas/preguntas/vista-preguntas/:id', //:id (idproyecto)
            name: 'vista_preguntas',
            component: vista_preguntas,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/grupo-eventos',
            name: 'grupo_eventos',
            component: require('@/views/grupo_eventos').default,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/grupo-eventos/grupos-eventos/crear',
            name: 'grupoeventos_crear',
            component: grupoeventos_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/grupos-eventos/editar/:id/:accion',
            name: 'grupoeventos_editar',
            component: grupoeventos_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/enfermedades/crear',
            name: 'enfermedades_crear',
            component: enfermedades_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/enfermedades/editar/:id/:accion',
            name: 'enfermedades_editar',
            component: enfermedades_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/rutas-atencion/crear',
            name: 'rutasatencion_crear',
            component: rutasatencion_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/rutas-atencion/editar/:id/:accion',
            name: 'rutasatencion_editar',
            component: rutasatencion_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/eventos-rutas/crear',
            name: 'eventosrutas_crear',
            component: eventosrutas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/grupo-eventos/eventos-rutas/editar/:id/:accion',
            name: 'eventosrutas_editar',
            component: eventosrutas_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/seguimiento-profesional',
            name: 'seguimiento_profesional',
            component: seguimiento,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },
        {
            path: '/seguimiento-profesional/crear/:persona/:proyecto/:accion',
            name: 'seguimiento_profesional_crear',
            component: seguimiento_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },     
        {
            path: '/informes',
            name: 'informes',
            component: require('@/views/informes').default,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },   
        {
            path: '/estadisticas',
            name: 'estadisticas',
            component: require('@/views/estadisticas').default,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },   
        {
            path: '/georeferenciacion',
            name: 'georeferenciacion',
            component: require('@/views/georeferenciacion').default,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        }, 
        {
            path: '/sivigila',
            name: 'sivigila',
            component: sivigila,
            meta: {
                requiresAuth: true,
                access: [1,2,6]
            }
        },  
        {
            path: '/sivigila/subir',
            name: 'sivigila',
            component: sivigila_subir,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },  
        {
            path: '/sivigila/agregar/:id',
            name: 'sivigila',
            component: sivigila_agregar,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },  
        {
            path: '/sivigila/ver/:id',
            name: 'sivigila_tabla',
            component: sivigila_tabla,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/sivigila/parametros',
            name: 'sivigila_parametros',
            component: sivigila_parametros,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/sivigila/parametros/crear',
            name: 'sivigila_parametros',
            component: sivigila_parametros_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },  
        {
            path: '/sivigila/parametros/ver/:id',
            name: 'sivigila_parametros',
            component: sivigila_parametros_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },
        {
            path: '/sivigila/parametros/editar/:id/:accion',
            name: 'sivigila_parametros',
            component: sivigila_parametros_crear,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        },    
        {
            path: '/pdf',
            name: 'sivigila_parametros',
            component: require('@/views/pdf').default,
            meta: {
                requiresAuth: true,
                access: [1,2]
            }
        }, 
        {
            path: '**',
            redirect: '/404'
        }
    ]
})

export default router
