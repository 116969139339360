<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Parámetro Sivigila</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/sivigila/parametros" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form
          @submit.prevent="saveParametroSivigila"
          enctype="multipart/form-data"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-md-4">
                  <span>Nombre Campo</span>
                  <input
                    type="text"
                    class="form-control"
                    v-model="model_sivigila.campo"
                    :disabled="disabled"
                    required
                  />
                </div>
                <div class="form-group col-md-4">
                  <span>Descripción</span>
                  <textarea
                    rows="1"
                    class="form-control"
                    :disabled="disabled"
                    required
                    v-model="model_sivigila.descripcion"
                  ></textarea>
                </div>
                <div class="form-group col-md-2">
                  <span>Longitud</span>
                  <input
                    type="number"
                    class="form-control"
                    :disabled="disabled"
                    required
                    v-model="model_sivigila.longitud"
                  />
                </div>
                <div class="form-group col-md-2">
                  <span>Tipo</span>
                  <input
                    type="text"
                    class="form-control"
                    :disabled="disabled"
                    required
                    v-model="model_sivigila.tipo"
                  />
                </div>
                <div class="form-group col-md-4">
                  <span>Valores Permitidos</span>
                  <textarea
                    rows="1"
                    class="form-control"
                    :disabled="disabled"
                    required
                    v-model="model_sivigila.valores_respuestas"
                  ></textarea>
                </div>
                <div class="form-group col-md-4">
                  <span>Validación</span>
                  <textarea
                    rows="1"
                    class="form-control"
                    :disabled="disabled"
                    v-model="model_sivigila.validacion"
                  ></textarea>
                </div>
                <div class="form-group col-md-2">
                  <span>¿Obligatorio?</span>
                  <b-form-select
                    v-model="model_sivigila.requerido"
                    :options="options_obligatorio"
                    :disabled="disabled"
                    required
                  ></b-form-select>
                </div>
                <div class="form-group col-md-2">
                  <span>¿Contiene Respuestas?</span>
                  <b-form-select
                    v-model="model_sivigila.traducir"
                    :options="options_sino"
                    :disabled="disabled"
                    required
                  ></b-form-select>
                </div>
                <div class="col-md-3"></div>
                <div
                  class="form-group col-md-6"
                  v-if="model_sivigila.traducir == 1"
                >
                  <div class="row" :style="display">
                    <div class="col-md-12 input-nex">
                      <span>Valor Respuesta</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="valor"
                        :disabled="disabled"
                      />
                    </div>
                    <div class="col-md-12 input-nex">
                      <span>Nombre Respuesta</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="posible_respuesta"
                        :disabled="disabled"
                      />
                    </div>
                    <div class="col-md-12 text-center">
                      <br />
                      <button
                        @click="addRespuesta"
                        type="button"
                        class="btn btn-sm btn-primary w-20"
                        :disabled="disabled"
                      >
                        Añadir Respuesta
                      </button>
                    </div>
                  </div>
                  <div class="col-md-12 scrollX mt-4">
                    <h6>Listado Respuestas</h6>
                    <table class="table responsive">
                      <tbody>
                        <tr v-if="!model_sivigila.respuestas.length">
                          <td colspan="2">No hay respuestas añadidas</td>
                        </tr>
                        <tr
                          v-for="(respuesta, key) in model_sivigila.respuestas"
                          v-bind:key="key"
                        >
                          <div class="row">
                            <div class="form-group col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                v-model="model_sivigila.respuestas[key].nombre"
                                :disabled="true"
                              />
                            </div>
                            <div class="form-group col-sm-2">
                              <i
                                @click="
                                  _removerPosibleRespuesta(key, 0)
                                  /*eliminarRespuesta(this.model_sivigila.Respuestas[key].id);*/
                                "
                                class="btn btn-danger mdi mdi-trash-can text-white cr-pointer f-20"
                                :style="display"
                              ></i>
                            </div>
                          </div>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-3"></div>
                <div class="form-group col-sm-12">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";

export default {
  name: "parametros_crear",
  data: () => ({
    disabled: false,
    display: "display:block;",
    rtaUpdate: 0,
    id_menu: 2,
    title: "Crear",
    posible_respuesta: "",
    valor: "",
    model_sivigila: {
      campo: "",
      descripcion: "",
      longitud: "",
      tipo: "",
      valores_respuestas: "",
      validacion: "",
      requerido: "",
      traducir: "",
      respuestas: [],
    },
    options_sino: [
      { value: 1, text: "SI" },
      { value: 0, text: "NO" },
    ],
    options_obligatorio: [
      { value: 1, text: "SI" },
      { value: 0, text: "NO" },
    ],
  }),
  mounted() {
    this.getDatosParametros();
    this.getDatosRtas();
  },
  computed: {},
  methods: {
    async saveParametroSivigila() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi +
              "sivigila/parametros/actualizar/" +
              this.$route.params.id +
              "/" +
              +this.model_sivigila.respuestas.length,
            this.model_sivigila,
            {
              headers,
            }
          )
          .then((response) => {
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(
            configApi +
              "sivigila/parametros/crear/" +
              this.model_sivigila.respuestas.length,
            this.model_sivigila,
            {
              headers,
            }
          )
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    _removerPosibleRespuesta(key) {
      this.model_sivigila.respuestas.splice(key, 1);
      this.rtaUpdate = 1;
    },
    addRespuesta() {
      if (this.posible_respuesta != "" && this.posible_respuesta != "") {
        this.model_sivigila.respuestas.push({
          id: "",
          nombre: this.posible_respuesta,
          valor: this.valor,
        });

        this.rtaUpdate = 1;
        this.posible_respuesta = "";
        this.valor = "";
      }
    },
    async getDatosParametros() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(configApi + "sivigila/parametros/ver/" + this.$route.params.id, {
            headers,
          })
          .then((response) => {
            this.model_sivigila.traducir = response.data.data.traducir;
            this.model_sivigila.campo = response.data.data.campo;
            this.model_sivigila.descripcion = response.data.data.descripcion;
            this.model_sivigila.longitud = response.data.data.longitud;
            this.model_sivigila.tipo = response.data.data.tipo;
            this.model_sivigila.valores_respuestas =
              response.data.data.valores_respuestas;
            this.model_sivigila.validacion = response.data.data.validacion;

            if (response.data.data.requerido == "SI") {
              this.model_sivigila.requerido = 1;
            } else {
              this.model_sivigila.requerido = 0;
            }

            if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
              this.disabled = false;
              this.display = "display:block;";
              this.title = "Editar";
            } else {
              this.disabled = true;
              this.display = "display:none;";
              this.title = "Ver";
            }
          });
      }
    },
    async getDatosRtas() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(configApi + "sivigila/parametros/rta/" + this.$route.params.id, {
            headers,
          })
          .then((response) => {
            for (var i = 0, max = response.data.data.length; i < max; i += 1) {
              this.model_sivigila.respuestas.push({
                id: response.data.data[i].id,
                nombre: response.data.data[i].nombre,
                valor: response.data.data[i].valor,
              });
            }
          });
      }
    },
    resetForm() {
      this.model_sivigila.campo = "";
      this.model_sivigila.descripcion = "";
      this.model_sivigila.longitud = "";
      this.model_sivigila.tipo = "";
      this.model_sivigila.valores_respuestas = "";
      this.model_sivigila.validacion = "";
      this.model_sivigila.requerido = "";
      this.model_sivigila.traducir = "";
      this.model_sivigila.respuestas = [];
      this.valor = "";
      this.posible_respuesta = "";
      this.rtaUpdate = 0;
    },
  },
};
</script>
