<template>
    <div>
        <b-tabs content-class="mt-3" align="center">
            <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                <component :is="item.component" :ref="item.component" />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import listado from './listado' 
import novedades from './novedades/listado'

export default {
    name: 'listado_usuarios',
    components: {
      listado,
      novedades
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Eventos', component: 'listado' },
          { tab: 'Novedades Eventos', component: 'novedades' },
        ],
    })
}
</script>