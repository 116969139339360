<template>
    <div class="row">
        <div class="card col-md-4">
            <div class="row no-gutters">
                <div class="col-md-4">
                <br>
                <img src="../../../assets/estadisticas_icons/informe-medico.png" class="img-fluid">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Total Resultados</h5>
                        <p class="card-text" v-for="caso in casos_covid" v-bind:key="caso">{{caso.prueba}}: <strong>{{ formatPrice(caso.personas) }}</strong></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-4">
            <div class="row no-gutters">
                <div class="col-md-4">
                <br>
                <img src="../../../assets/estadisticas_icons/estetoscopio.png" class="img-fluid">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Total Atención Médica</h5>
                        <p class="card-text" v-for="atencion in total_atencionmed" v-bind:key="atencion">
                            <strong v-if="atencion.prueba == 'SI'">{{ formatPrice(atencion.personas) }}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-4">
            <div class="row no-gutters">
                <div class="col-md-4">
                <br>
                <img src="../../../assets/estadisticas_icons/no-grupo.png" class="img-fluid">
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                        <h5 class="card-title">Total Aislamiento</h5>
                        <p class="card-text" v-for="aisla in total_aislamiento" v-bind:key="aisla">
                            <strong v-if="aisla.prueba == 'SI'">{{ formatPrice(aisla.personas) }}</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import {headers} from '../../../api/headers'
import {configApi} from '../../../api/configApi'

export default {
    data() {
        return {
            casos_covid: [],
            total_atencionmed: [],
            total_aislamiento: [],
        }
    },
    mounted() {
        this.getCasosCovidResul(),
        this.getCasosCovidAtencion(),
        this.getCasosCovidAislamiento()
    },
    methods: {
        async getCasosCovidResul() {

            return await this.axios.get(configApi+'estadisticas/esta_covid_result', {headers})
            .then(response => {
                
                this.casos_covid = response.data;

            })

        },
        async getCasosCovidAtencion() {

            return await this.axios.get(configApi+'estadisticas/esta_covid_atencion', {headers})
            .then(response => {
                
                this.total_atencionmed = response.data;

            })

        },
        async getCasosCovidAislamiento() {

            return await this.axios.get(configApi+'estadisticas/esta_covid_aisla', {headers})
            .then(response => {
                
                this.total_aislamiento = response.data;

            })

        },
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        } 
    }
}
</script>
