<template>
  <div>
    <div v-if="validateSession">

      <div id="App">           
        <b-navbar toggleable="sm" type="light" variant="light" sticky style="background-color: #5c6bc0 !important" id="menu_app">          
          <b-navbar-brand href="#">
            <b-button v-b-toggle.sidebar-backdrop class="btn" style="background-color: white; color:black;"><i class="mdi mdi-menu"></i></b-button>            
            <!-- <b class="text-white">&nbsp;&nbsp;&nbsp;VIGILACONTIGO</b>  -->
            &nbsp;&nbsp;&nbsp;&nbsp;<img src="../assets/logo.png" class="mr-auto" width="60%" height="50px">
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto"> 
              <b class="btn text-white" @click="navegacion('dashboard')"><i class="mdi mdi-home"></i></b>           
              <b-nav-item-dropdown right>                
                <template #button-content >                  
                  <b class="text-white"> {{datos_login.nombre}} </b>
                </template>
                <b-dropdown-item @click="navegacion('datos-personales')"><i class="mdi mdi-clipboard-account"></i> Datos Personales</b-dropdown-item>
                <b-dropdown-item @click="navegacion('logout')"><i class="mdi mdi-logout"></i> Cerrar Sesión</b-dropdown-item>
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-collapse>

        </b-navbar>

        <b-sidebar
          id="sidebar-backdrop"          
          title=""
          :backdrop-variant="variant"
          backdrop
          shadow
        >
          <div class="px-3 py-2">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="col-md-3">
                  <b-img 
                  v-bind:src="require('../assets/usuario.png')"
                  rounded="circle" 
                  v-bind="mainProps">
                  </b-img>
              </div>
              <div class="col-md-5 text-truncate">
                <!-- {{datos_login.nombre}} <br> -->
                {{datos_login.rol}} <br>
                {{datos_login.correo}}
              </div>
            </div>
            
            <hr>
            <nav class="mb-3">
              <b-nav vertical>                  
                <b-nav-item id="items_menu" class="shadow-sm p-1 mb-2 bg-white rounded" @click="navegacion(item.href)" v-for="(item,index) in items" v-bind:key="index"><i :class="item.faicon"></i> {{item.name}}</b-nav-item>
              </b-nav>
            </nav>
          </div>
          <template #footer="{ hide }">
          <div class="d-flex bg-white text-dark align-items-center px-3 py-2">
            <!-- <strong class="mr-auto">VIGILACONTIGO</strong> -->
            <img src="../assets/logo_v1.png" class="mr-auto" width="45%">
            <b-button size="sm" @click="hide">v2.0.6</b-button>
          </div>
          </template>
        </b-sidebar>
                  
          
        <router-view style="width: 95%; display: block; margin:auto; margin-top: 50px;"></router-view>
        

      </div>
      
    </div>

    <main v-if="!validateSession">

      <div class="card">
        <div class="card-body">
          <router-view></router-view>
        </div>
      </div>

    </main>
    
  </div>
</template>
<script>
import {headers} from './../api/headers'
import {configApi} from './../api/configApi'
  export default {
    components: {
      
    },
    data: () => ({ 
      drawer: null,
      items_predeterminados: [
        {
          title: 'Cerrar Sesión',
          icon: 'mdi-arrow-right-box',
          name: '/logout'
        },        
      ],
      mainProps: { width: 50, height: 50 },
      right: null,
      validateSession: false,
      initialShow: true,
      variant: 'dark',
      header: "<h3>Administrador</h3>",
      items: [],
      datos_login: {
        nombre: '',
        rol: '',
        email: ''
      }
    }),
    mounted() {
      this.validarAccesoMenu(),
      this.listarMenus()
      this.datosLogin()

      if(this.$router.currentRoute.path == '/') {

        if(this.validateSession) {
          this.$router.push('/404')
        } else {
          this.$router.push('/login')
        }
        
      }

      if(this.$router.currentRoute.path == '/login' && this.validateSession == false) {
        this.$router.push('/dashboard')
      }

    },
    methods: {
      onSidebarChanged() {},
      navegacion(menu) {
        this.$router.push('/'+menu)
      },
      async validarAccesoMenu() {

        if(localStorage.getItem('access_token')) {

          return await this.axios.get(configApi+'usuarios/acceso_menu', {headers})
          .then(response => {
            if(response.data.status == 401) {
              this.validateSession = false
            } else {
              this.validateSession = true
            }
          }).catch(error => {
            localStorage.removeItem('access_token')
          })

        } 

      },
      async listarMenus() {

        if(localStorage.getItem('access_token')) {

          return await this.axios.get(configApi+'usuarios/listado_menu_permisos', {headers})
          .then(response => {
            this.items = response.data
          })

        }

      },
      async datosLogin() {

          return this.axios.get(configApi+'usuarios/obtener_rol', {headers})
          .then(response => {   
              
              console.log(response)

              this.datos_login.rol = response.data.data[0].nombre_rol   
              this.datos_login.nombre = response.data.data[0].nombres + ' ' +  response.data.data[0].apellidos
              this.datos_login.correo = response.data.data[0].correo                  

          }) 

        },
    }
  }
</script>
