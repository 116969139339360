<template>
    <div>
        <b-card no-body>
            <b-tabs pills card>
                <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                    <component :is="item.component" :ref="item.component" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>

import info_segepidegeneral from './info_segepidegeneral.vue'

export default {
    name: 'listado_usuarios',
    components: {
      info_segepidegeneral
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Informe x Eventos', component: '' },
          { tab: 'Informe / Seguimiento  Epidemiológico', component: 'info_segepidegeneral' },
        ],
    })
}
</script>