<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Listar Seguimientos</b></div>

      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5"><br /><br /></div>
          <div class="col-sm-5">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>

          <div class="col-sm-2">
            <div class="float-right" role="group" aria-label="Basic example">
              <b-button
                style="margin-right: 30px"
                class="btn btn-info"
                @click="getSeguimientos"
                v-b-tooltip.hover
                title="Actualizar Datos"
                ><i class="mdi mdi-replay"></i
              ></b-button>

              <router-link
                :to="'/encuestas/preguntas/vista-preguntas/0'"
                class="btn btn-primary"
                v-b-tooltip.hover
                title="Agregar Eventos"
                ><i class="mdi mdi-message-text-outline"></i
              ></router-link>
            </div>
          </div>
          <div></div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          sticky-header
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <div v-if="row.item.idproyecto > 0">
              <b-button
                class="btn btn-success"
                :to="
                  '/seguimiento-profesional/crear/' +
                  row.item.idpersona +
                  '/' +
                  row.item.idproyecto +
                  '/0'
                "
                v-b-tooltip.hover
                title="Crear Seguimiento Paciente"
                ><i class="mdi mdi-plus-circle text-white"></i></b-button
              >&nbsp;
            </div>
            <div v-else>
              <b-button
                class="btn btn-success"
                :to="
                  '/seguimiento-profesional/crear/' +
                  row.item.idpersona +
                  '/' +
                  row.item.id_encsiv +
                  '/1'
                "
                v-b-tooltip.hover
                title="Crear Seguimiento Paciente"
                ><i class="mdi mdi-plus-circle text-white"></i></b-button
              >&nbsp;
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../api/headers";
import { configApi } from "./../../api/configApi";
export default {
  name: "listado_seguimiento_profesional",
  data: () => ({
    title: "",
    perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    fields: [
      { key: "nombresper", label: "Nombres Persona", sortable: true },
      {
        key: "apellidosper",
        label: "Apellidos Persona",
        sortable: true,
        class: "text-center",
      },
      /*{
        key: "fechaevento",
        label: "Fecha Encuesta",
        sortable: true,
        class: "text-center",
      },*/
      {
        key: "documento",
        label: "Documento Identidad",
        sortable: true,
        class: "text-center",
      },
      {
        key: "nombrepro",
        label: "Nombre Proyecto",
        sortable: true,
        class: "text-center",
      },
      {
        key: "fechahora",
        label: "Fecha",
        sortable: true,
        class: "text-center",
      },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.validarAccesoRutas();
  },
  created() {
    this.getSeguimientos();
  },

  methods: {
    validarAccesoRutas() {
      return this.axios
        .get(configApi + "usuarios/obtener_rol", { headers })
        .then((response) => {
          let rol_usuario = response.data.data[0].id_rol;
          let ruta_actual = this.$router.currentRoute.meta.access;
          let salir = false;

          for (let i = 0; i < ruta_actual.length; i++) {
            if (ruta_actual[i] == rol_usuario) {
              salir = false;
              break;
            } else {
              salir = true;
            }
          }

          if (salir) {
            this.$router.push("/dashboard");
          }
        });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getSeguimientos() {
      return await this.axios
        .get(configApi + "seguimiento_profesional/seguimiento", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
  },
};
</script>
