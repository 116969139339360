<template>
  <div class="iframe-container">
    <div style="text-align: right; margin-top: 10px; margin-right: 10px; margin-bottom: 30px;">
      <button
          @click="descargarInforme"
          class="btn btn-success"
      >
        <i class="mdi mdi-magnify"></i> Descargar informe
      </button>
    </div>
    <iframe width="100%" height="600px"
            src="https://lookerstudio.google.com/embed/reporting/5d136dfa-9c5c-4c91-adb3-1dba2d1aad10/page/xQhpD"
            frameborder="0" style="border:0" allowfullscreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
  </div>
</template>

<script>
export default {
  methods: {
    descargarInforme() {
      window.location.href = 'https://observatoriostorage.unillanos.edu.co/data-scraping-dags/Outputs/Desnutricion/Desnutricion.html';
    }
  }
}
</script>
