<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>Listar Encuestas</b>
          <a
            href="http://www.vigilacontigo.com/manuales/manual_encuestas.pdf"
            target="_blank"
            class="float-right"
            v-b-tooltip.hover
            title="Ver Manual"
            ><img src="http://www.vigilacontigo.com/manuales/pdf.png" style="width: 32px"
          /></a>
      </div>
      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5">
            <b-button class="btn btn-add-vg" :to="'/encuestas/crear'"
              ><i class="mdi mdi-plus"></i> Crear Encuesta</b-button
            >
            &nbsp;
            <!-- <b-button class="btn btn-info" :to="'/encuestas/clonar'"  v-b-tooltip.hover title="En Mantenimiento..." disabled><i class="mdi mdi-content-duplicate"></i> Clonar Encuesta</b-button>                        -->
          </div>
          <div class="col-sm-6">
            <!-- <b-form-input
                        class="col-sm-12"
                        v-model="filter"
                        type="search"
                        placeholder="Busqueda"
                        ></b-form-input> -->
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getEncuestas"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <div
          class="row row-cols-1 row-cols-md-3 g-4"
          style="overflow-y: scroll; height: 650px"
        >
          <div
            class="col col-sm-12 col-md-12 col-lg-6 col-xl-6"
            v-for="item in items"
            v-bind:key="item.id"
            style="margin-top: 35px"
          >
            <div class="card shadow p-3">
              <div class="card-body">
                <h5 class="card-title text-truncate">
                  {{ item.nombreproyecto }}
                </h5>
                <hr />
                <p class="card-text text-truncate">{{ item.descripcion }}</p>
                <div class="row">
                  <div class="col-md-8">
                    <b-badge
                      variant="success"
                      class="text-truncate"
                      v-if="item.fechainicio != null"
                      style="font-size: 12px"
                      ><i class="mdi mdi-checkbox-marked"></i> Fecha Inicio:
                      {{ item.fechainicio }}</b-badge
                    >
                    <b-badge
                      variant="warning"
                      class="text-truncate"
                      v-else
                      style="font-size: 12px"
                      ><i class="mdi mdi-alert"></i> Fecha Inicio por Ingresar
                    </b-badge>
                    <br />
                    <b-badge
                      variant="success"
                      class="text-truncate"
                      v-if="item.fechafin != null"
                      style="font-size: 12px"
                      ><i class="mdi mdi-checkbox-marked"></i> Fecha Fin:
                      {{ item.fechafin }}</b-badge
                    >
                    <b-badge
                      variant="warning"
                      class="text-truncate"
                      v-else
                      style="font-size: 12px"
                      ><i class="mdi mdi-alert"></i> Fecha Fin por
                      Ingresar</b-badge
                    >
                    <br />
                    <b-badge
                      variant="success"
                      class="text-truncate"
                      v-if="item.activo == 1"
                      style="font-size: 12px"
                      ><i class="mdi mdi-checkbox-marked"></i>Estado:
                      <strong>Activo</strong></b-badge
                    >
                    <b-badge
                      variant="warning"
                      class="text-truncate"
                      v-else
                      style="font-size: 12px"
                      ><i class="mdi mdi-alert"></i>Estado:
                      <strong>Inactivo</strong></b-badge
                    >
                    <!-- <b-badge variant="success" class="text-truncate" v-if="item.fechageneracion != null"><i class="mdi mdi-checkbox-marked"></i> Fecha Activación:  &nbsp; {{ item.fechageneracion }} <b-badge style="font-size: 11px; cursor:pointer;" @click="activarEncuesta(item.idproyecto, 0)" variant="danger">Click para Desactivar</b-badge></b-badge>
                                        <b-badge variant="warning" class="text-truncate" v-else><i class="mdi mdi-alert"></i> Encuesta por Activar <b-badge @click="activarEncuesta(item.idproyecto, 1)" style="font-size: 11px; cursor:pointer;" variant="info">Click para Activar</b-badge> </b-badge> -->
                  </div>
                  <div class="col-md-1"></div>
                  <!-- <div class="col-md-2">
                                        <button :class="item.activo==1 ? 'btn btn-success':'btn btn-danger'">
                                            {{ item.activo==1 ? 'Activo':'Inactivo' }}
                                        </button>
                                    </div> -->
                  <div class="col-md-1"></div>
                </div>
              </div>
              <div class="card-footer text-right">
                <b-button
                  :to="'/encuestas/preguntas/' + item.idproyecto"
                  class="btn"
                  v-b-tooltip.hover
                  title="Ver Preguntas"
                  style="float: left; background-color: #3949ab; color: white"
                  ><i class="mdi mdi-clipboard-text"></i
                ></b-button>
                <b-button
                  class="btn btn-info"
                  :to="'/encuestas/ver/' + item.idproyecto"
                  v-b-tooltip.hover
                  title="Ver Encuesta"
                  ><i class="mdi mdi-eye text-white"></i></b-button
                >&nbsp;
                <b-button
                  class="btn btn-warning"
                  :to="'/encuestas/editar/' + item.idproyecto + '/0'"
                  v-b-tooltip.hover
                  title="Editar Encuesta"
                  ><i class="mdi mdi-pencil text-white"></i></b-button
                >&nbsp;
                <b-button
                  class="btn btn-danger"
                  @click="deleteEncuesta(item)"
                  v-b-tooltip.hover
                  title="Eliminar Encuesta"
                  ><i class="mdi mdi-delete"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../api/headers";
import { configApi } from "./../../api/configApi";
import Vue from "vue";
import Swal from "sweetalert2";
Vue.prototype.$swal = Swal;
export default {
  name: "listado_barrios",
  data: () => ({
    title: "",
    perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    fields: [
      { key: "idproyecto", label: "ID", sortable: true },
      {
        key: "nombreproyecto",
        label: "Nombre Encuesta",
        sortable: true,
        class: "text-center",
      },
      {
        key: "descripcion",
        label: "Descripción",
        sortable: true,
        class: "text-center",
      },
      {
        key: "fechainicio",
        label: "Fecha Inicio",
        sortable: true,
        class: "text-center",
      },
      { key: "estado", label: "Estado", sortable: true, class: "text-center" },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },

  created() {
    this.getEncuestas();
  },

  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getEncuestas() {
      return await this.axios
        .get(configApi + "encuestas/encuestas", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
    async deleteEncuesta(data) {
      let objAlert = {
        titleAlert: "¿Desea eliminar la Encuesta?",
        url: configApi + "encuestas/encuestas/" + data.idproyecto, // url delete
        headers: { headers },
        messageError: "La encuesta no fue Eliminada",
      };

      this.$Fun.alertaConfirmarEliminarAccion(objAlert, (e) => {
        if (e) {
          this.getEncuestas();
        }
      });
    },
    activarEncuesta(proyecto, accion) {
      let accion_data = accion == 1 ? "Activar" : "Desactivar";

      Swal.fire({
        title: "¿Está seguro de " + accion_data + " la Encuesta ?",
        text:
          accion == 1
            ? "Asegúrese que estén registradas todas sus preguntas y sus respectivos parámetros, una vez sea activada, no podrá registrar nuevas preguntas."
            : "Verifique que la encuesta no tenga registros de pacientes, de lo contrario dará error.",
        showDenyButton: true,
        confirmButtonText: "Confirmar",
        confirmButtonColor: "#4caf50",
        denyButtonText: `Cancelar`,
        denyButtonColor: "#f44336",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .put(
              configApi +
                "encuestas/encuesta/estado/" +
                proyecto +
                "/" +
                accion,
              "",
              { headers }
            )
            .then((response) => {
              if (response.data == "generado") {
                this.$Fun.alertaAccion(
                  "success",
                  "Encuesta ya Activada",
                  false
                );
                this.getEncuestas();
              } else if (response.data == "fechanull") {
                this.$Fun.alertaAccion(
                  "error",
                  "No se Permite Desactivar una Encuesta que no está Generada",
                  false
                );
                this.getEncuestas();
              } else if (response.data == "ok") {
                this.$Fun.alertaAccion(
                  "success",
                  "Se ha Activado la Encuesta Seleccionada",
                  false
                );
                this.getEncuestas();
              } else if (response.data == "nodes") {
                this.$Fun.alertaAccion(
                  "warning",
                  "Se Encontraron Registros de Pacientes en la Encuesta",
                  false
                );
                this.getEncuestas();
              } else if (response.data == "okdes") {
                this.$Fun.alertaAccion(
                  "success",
                  "Se ha Desactivado la Encuesta",
                  false
                );
                this.getEncuestas();
              }
            });
        } else if (result.isDenied) {
          this.$Fun.alertaAccion("error", "Acción Cancelada", false);
        }
      });
    },
  },
};
</script>
<style>
.bi {
  width: 1em;
  height: 1em;
  fill: currentcolor;
}
</style>
