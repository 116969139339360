<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Listar Eventos</b></div>

      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5">
            <b-button
              class="btn btn-add-vg"
              :to="'/grupo-eventos/enfermedades/crear'"
              ><i class="mdi mdi-plus"></i> Crear Evento</b-button
            ><br /><br />
          </div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getEnfermedades"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          sticky-header
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-button
              class="btn btn-warning"
              :to="
                '/grupo-eventos/enfermedades/editar/' +
                row.item.idriasevento +
                '/0'
              "
              v-b-tooltip.hover
              title="Editar Evento"
              ><i class="mdi mdi-pencil text-white"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-danger"
              @click="deleteEnfermedad(row.item)"
              v-b-tooltip.hover
              title="Eliminar Evento"
              ><i class="mdi mdi-delete"></i
            ></b-button>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../../api/headers";
import { configApi } from "./../../../api/configApi";
export default {
  name: "listado_grupoeventos",
  data: () => ({
    title: "",
    perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    fields: [
      { key: "idriasevento", label: "ID", sortable: true },
      {
        key: "nombre_rias",
        label: "Nombre Programa",
        sortable: true,
        class: "text-center",
      },
      {
        key: "evento",
        label: "Nombre Evento",
        sortable: true,
        class: "text-center",
      },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },

  created() {
    this.getEnfermedades();
  },

  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getEnfermedades() {
      return await this.axios
        .get(configApi + "grupo_eventos/enfermedades", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
    async deleteEnfermedad(data) {
      let objAlert = {
        titleAlert: "¿Desea eliminar el Evento?",
        url: configApi + "grupo_eventos/enfermedades/" + data.idriasevento, // url delete
        headers: { headers },
        messageError: "El Evento no fue Eliminado",
      };

      this.$Fun.alertaConfirmarEliminarAccion(objAlert, (e) => {
        if (e) {
          this.getEnfermedades();
        }
      });
    },
  },
};
</script>
