<template>
  <div>
    <div class="row">
      <div class="card col-md-12">
        <div class="row no-gutters">
          <div class="col-md-11">
            <div class="card-body">
              <eventosSivigila />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12"><br /></div>
    </div>
  </div>
</template>
<script>
import eventosSivigila from "./esta_eventosivigila.vue";

export default {
  components: {
    eventosSivigila,
  },
};
</script>
