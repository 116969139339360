<template>
  <div>
    <div class="row">
      <div class="form-group col-sm-10">
        <label>Seleccione Evento Sivigila</label>
        <Select2
          v-model="evento"
          @change="getPersonasBarrios"
          :options="options_eventos"
          required
        />
      </div>
    </div>
    <div class="graphic-container">
      <label class="titulo-container">{{  tituloEvento }}</label>
      <VueApexCharts
        type="line"
        height="250"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "esta_eventosivigila",
  components: { VueApexCharts },
  data() {
    return {
      tituloEvento: "",
      evento: "",
      options_eventos: [],
      series: [],
      chartOptions: {
        chart: {
          height: 300,
          type: "line",
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: true,
          },
        },
        stroke: {
          width: [5, 5, 4],
          curve: "straight",
        },
        title: {
          text: "",
        },
        plotOptions: {
          bar: {
            columnWidth: "5%",
          },
        },
        markers: {
          size: 5,
        },
        xaxis: { categories: [] },
      },
    };
  },
  mounted() {
    this.getEventoSivigila();
  },
  methods: {
    async getPersonasBarrios() {
      this.series = [];
      this.chartOptions.xaxis.categories = [];
      let tempCate = [];
      return await this.axios
        .get(configApi + "estadisticas/esta_evento_sivigila/" + this.evento, {
          headers,
        })
        .then((response) => {


          let datas = response.data;

          for (var i = 0, max = datas.length; i < max; i += 1) {
            let a = [];
            a.push(datas[i].cantidad);

            this.series.push({
              name: datas[i].nmun_resi,
              data: a,
            });

            var existingValue = tempCate.find(
              (item) => item === datas[i].year
            );
            if (!existingValue) {
              tempCate.push(datas[i].year);
            }

            this.tituloEvento = datas[i].nom_eve + " (" + datas[i].cod_eve + ")";
          }

          this.chartOptions.xaxis.categories = tempCate;
          //this.chartOptions.title = tituloEvento;
          //this.nomEven = tituloEvento;
          //console.log(tituloEvento);
        });
    },
    async getEventoSivigila() {
      return await this.axios
        .get(configApi + "sivigila/sivigila", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_eventos.push({
              id: data[i].id,
              text: data[i].nombre,
            });
          }

          if (data.length > 0) {
            this.evento = data[0].id;
          }

          this.getPersonasBarrios();
        });
    },
  },
};
</script>
<style>
.graphic-container {
  overflow-y: auto;
  overflow-x: hidden;
}
.titulo-container {
  margin-top: 15px;
  font-family: Helvetica, Arial, sans-serif; opacity: 1;
  font-size: 14px;
  font-weight: 900;
  fill: #373d3f;
}
</style>
