<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Flujograma de Atención</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/grupo-eventos" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveRutaAtencion">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Nombre Flujograma</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="ruta_atencion.ruta"
                    :disabled="disabled"
                    required
                  />
                </div>
                <div class="col-sm-12 border br-6 m-0 mt-2">
                  <div class="col-md-12">
                    Asignación de Eventos <br /><br />
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <span>Seleccione Programa:</span>
                        <Select2
                          @change="getEnfermedad"
                          v-model="ruta_atencion.rias"
                          :options="options_grupoeventos"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-10">
                        <span>Seleccione Evento:</span>
                        <Select2
                          v-model="ruta_atencion.idriasevento"
                          :options="options_enfermedades"
                        />
                      </div>
                      <div class="col-md-2 mt-auto">
                        <i
                          @click="addItemRuta"
                          class="mdi mdi-plus-circle text-primary f-26 cr-pointer"
                          v-b-tooltip.hover
                          title="Añadir"
                        />
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-12">
                        <span>Listado Eventos seleccionados</span>
                      </div>
                      <div class="col-md-12 scrollX scrollY">
                        <table
                          class="table table-hover thead-color table-striped table-bordered table-sm w-100 tables_menus"
                        >
                          <thead>
                            <tr class="text-center">
                              <th>Programa</th>
                              <th>Evento</th>
                              <th class="w-10"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="!ruta_atencion.rias_eventos.length">
                              <th colspan="3" class="text-danger">
                                No hay registros agregados
                              </th>
                            </tr>
                            <tr
                              v-for="(
                                evento, key
                              ) in ruta_atencion.rias_eventos"
                              v-bind:key="key"
                            >
                              <td>{{ evento.rias }}</td>
                              <td>{{ evento.evento }}</td>
                              <td class="text-center">
                                <i
                                  @click="_removerEvento(key)"
                                  class="mdi mdi-trash-can text-danger f-20 cr-pointer"
                                  v-b-tooltip.hover
                                  title="Remover"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-sm-12">
                  <br /><br />
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
export default {
  name: "gruposeventos_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    options_grupoeventos: [],
    options_enfermedades: [],
    ruta_atencion: {
      ruta: "",
      rias: "",
      idriasevento: "",
      rias_eventos: [],
    },
  }),
  mounted() {
    this.getGrupoEventos();
  },
  computed: {},
  methods: {
    addItemRuta() {
      let enfermedad_find = this.options_enfermedades.find(
        (element) => element.id == parseInt(this.ruta_atencion.idriasevento)
      );

      if (enfermedad_find) {
        let grupoeventos_find = this.options_grupoeventos.find(
          (element) => element.id == parseInt(this.ruta_atencion.rias)
        );
        this.ruta_atencion.rias_eventos.push({
          idrias: grupoeventos_find.id,
          rias: grupoeventos_find.text,
          idriasevento: enfermedad_find.id,
          evento: enfermedad_find.text,
        });
      }

      this.ruta_atencion.rias = "";
      this.ruta_atencion.idriasevento = "";
    },
    async getEnfermedad() {
      this.options_enfermedades = [];

      return await this.axios
        .get(
          configApi +
            "grupo_eventos/enfermedades_x_grupoevento/" +
            this.ruta_atencion.rias,
          { headers }
        )
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_enfermedades.push({
              id: data[i].idriasevento,
              text: data[i].evento,
            });
          }
        });
    },
    async getGrupoEventos() {
      return await this.axios
        .get(configApi + "grupo_eventos/grupos_eventos", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_grupoeventos.push({
              id: data[i].idrias,
              text: data[i].nombre_rias,
            });
          }
        });
    },
    _removerEvento(key) {
      this.ruta_atencion.rias_eventos.splice(key, 1);
    },
    async saveRutaAtencion() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi + "grupo_eventos/enfermedades/" + this.$route.params.id,
            this.enfermedades,
            { headers }
          )
          .then((response) => {
            this.getGrupoEventos();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(
            configApi + "grupo_eventos/rutas_atencion",
            this.ruta_atencion,
            { headers }
          )
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    resetForm() {
      this.ruta_atencion.ruta = "";
      this.ruta_atencion.rias = "";
      this.ruta_atencion.idriasevento = "";
      this.ruta_atencion.rias_eventos = [];
    },
  },
};
</script>
