<template>
  <div>
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="row">
          <div class="form-group col-sm-12">
            <center>
              <button
                v-show="busqueda"
                @click="getInforme"
                class="btn btn-success"
                :disabled="disabled"
              >
                <i class="mdi mdi-magnify"></i> Buscar Datos
              </button>

              <b-button variant="primary" disabled v-show="loading">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Buscando datos ...
              </b-button>

              <download-excel
                v-show="exportar"
                class="btn btn-success"
                v-b-tooltip.hover
                title="Descargar Informe"
                :fields="json_fields"
                :data="json_data"
                :name="'informe_personas_bd_' + Date.now() + '.xls'"
              >
                <i class="mdi mdi-file-excel"></i> Exportar Datos
              </download-excel>
            </center>
          </div>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";

export default {
  data: () => ({
    encuesta: "",
    disabled: false,
    busqueda: true,
    loading: false,
    exportar: false,
    options_encuestas: [],
    json_fields: {
      "Nombres Persona": "nombrepersona",
      "Apellidos Persona": "apellidopersona",
      "Tipo de Documento Persona": "tipodocpersona",
      "Documento Persona": "documentopersona",
      "Genero Persona": "generopersona",
      "Fecha Nacimiento Persona": "fechanacimientopersona",
      "Celular Persona": "celularpersona",
      "Teléfono Fijo Persona": "fijopersona",
      "Correo Electrónico Persona": "correopersona",
      "Cabeza de Núcleo Persona": "cabenucleopersona",
      "Dirección Persona": "direccionpersona",
      "Barrio Persona": "barriopersona",
      "Profesión Persona": "profesionpersona",
      "Regimen Persona": "regimenpersona",
      "EPS Persona": "epspersona",
      "Etnia Persona": "etniapersona",
      "Programa Social Persona": "programaspersona",
      "Latitud Persona": "latitud",
      "Longitud Persona": "longitud",
    },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
  }),
  mounted() {
    this.getEncuestas();
  },
  methods: {
    async getEncuestas() {
      return await this.axios
        .get(configApi + "encuestas/encuestas", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_encuestas.push({
              id: data[i].idproyecto,
              text: data[i].nombreproyecto,
            });
          }
        });
    },
    async getInforme() {
      this.busqueda = false;
      this.loading = true;
      return await this.axios
        .get(configApi + "informes/personas_registradas", { headers })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.json_data = response.data.data;

            this.json_data.forEach(function (item) {
                if (
                  item.latitud != null &&
                  item.latitud != "" &&
                  item.latitud != "LATITUD"
                ) {
                  item.latitud = "'" + item.latitud;
                }

                if (
                  item.longitud != null &&
                  item.longitud != "" &&
                  item.longitud != "LONGITUD"
                ) {
                  item.longitud = "'" + item.longitud;
                }
              });

            this.loading = false;
            this.exportar = true;
          } else {
            this.busqueda = true;
            this.loading = false;
            this.exportar = false;
            this.$Fun.alertaAccion(
              "warning",
              "No se encontraron registros...",
              false
            );
          }
        })
        .catch((error) => {
          this.busqueda = true;
          this.loading = false;
          this.exportar = false;
          this.$Fun.alertaAccion(
            "warning",
            "Verifique que esté generada la encuesta",
            false
          );
        });
    },
  },
};
</script>
<style>
.select2-container {
  min-width: 100% !important;
}
</style>
