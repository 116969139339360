<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Listar Novedades Eventos Sivigila</b></div>

      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5"><br /><br /></div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getEventoSivigila"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          sticky-header
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <form>
              <div>
                <b-modal
                  :id="'registrarPacientes' + row.item.id"
                  size="lg"
                  title="Registrar Pacientes Encontrados"
                  hide-footer
                >
                  ¿Desea registrar los {{ row.item.conteo }} pacientes
                  encontrados? <br /><br />
                  <center>
                    <button class="btn btn-success" @click="saveNovedad">
                      Registrar
                    </button>
                  </center>
                </b-modal>
              </div>
            </form>

            <div v-if="row.item.conteo > 0">
              <b-button
                class="btn btn-warning"
                @click="
                  $bvModal.show('registrarPacientes' + row.item.id);
                  showModal(row.item);
                "
                v-b-tooltip.hover
                title="Registrar Pacientes"
                ><i class="mdi mdi-clipboard-alert"></i></b-button
              >&nbsp;
            </div>
            <div v-else>
              <b-button
                class="btn btn-success"
                v-b-tooltip.hover
                title="Sin Novedades"
                ><i class="mdi mdi-checkbox-marked"></i></b-button
              >&nbsp;
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../../api/headers";
import { configApi } from "./../../../api/configApi";
export default {
  name: "listado_barrios",
  data: () => ({
    title: "",
    perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    fields: [
      { key: "id", label: "ID", sortable: true, class: "text-center" },
      {
        key: "nombre",
        label: "Nombre Evento Sivigila",
        sortable: true,
        class: "text-center",
      },
      {
        key: "conteo",
        label: "Total Novedades",
        sortable: true,
        class: "text-center",
      },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
    id_evento: "",
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.validarAccesoRutas();
  },
  created() {
    this.getEventoSivigila();
  },

  methods: {
    validarAccesoRutas() {
      return this.axios
        .get(configApi + "usuarios/obtener_rol", { headers })
        .then((response) => {
          let rol_usuario = response.data.data[0].id_rol;
          let ruta_actual = this.$router.currentRoute.meta.access;
          let salir = false;

          for (let i = 0; i < ruta_actual.length; i++) {
            if (ruta_actual[i] == rol_usuario) {
              salir = false;
              break;
            } else {
              salir = true;
            }
          }

          if (salir) {
            this.$router.push("/dashboard");
          }
        });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getEventoSivigila() {
      this.items = [];
      return await this.axios
        .get(configApi + "sivigila/sivigila", { headers })
        .then((response) => {
          let data = response.data.data;

          for (let i = 0; i < data.length; i++) {
            this.axios
              .get(configApi + "sivigila/novedades/" + data[i].id, { headers })
              .then((response) => {
                let novedad = response.data.data;

                this.items.push({
                  id: data[i].id,
                  nombre: data[i].nombre,
                  conteo:
                    novedad[0].total == 0 ? "Sin Novedades" : novedad[0].total,
                  _rowVariant: novedad[0].total > 0 ? "warning" : "success",
                });
              });
          }

          this.loading = false;
        });
    },
    showModal(item) {
      this.id_evento = item.id;
    },
    async saveNovedad() {
      let data = {
        id: this.id_evento,
      };

      return await this.axios
        .put(
          configApi + "sivigila/registrar_pacientes/" + this.id_evento,
          data,
          { headers }
        )
        .then((response) => {
          this.getEventoSivigila();
        });
    },
  },
};
</script>
