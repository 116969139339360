<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Barrio</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/configuracion" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveBarrio">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Nombre Barrio</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="barrios.barrio"
                    :disabled="disabled"
                    required
                  />
                </div>
                <div class="col-sm-12">
                  <label>Nombre Comuna</label>
                  <Select2
                    v-model="barrios.comuna"
                    :options="options_comunas"
                  />
                </div>
                <br /><br /><br /><br />
                <div class="form-group col-sm-12">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
export default {
  name: "municipios_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    comunas: [],
    options_comunas: [],
    barrios: {
      barrio: "",
      comuna: "",
    },
  }),
  mounted() {
    this.getDatosBarrios();
    this.getComunas();
  },
  computed: {},
  methods: {
    async getComunas() {
      return await this.axios
        .get(configApi + "configuracion/comunas", { headers })
        .then((response) => {
          this.comunas = response.data.data;

          for (var i = 0, max = this.comunas.length; i < max; i += 1) {
            this.options_comunas.push({
              id: parseInt(this.comunas[i].idcomuna),
              text: this.comunas[i].comuna,
            });
          }
        });
    },
    async getDatosBarrios() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(configApi + "configuracion/barrios/" + this.$route.params.id, {
            headers,
          })
          .then((response) => {
            this.barrios.barrio = response.data.data.barrio;
            this.barrios.comuna = response.data.data.comuna;

            if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
              this.disabled = false;
              this.title = "Editar";
            }
          });
      }
    },
    async saveBarrio() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi + "configuracion/barrios/" + this.$route.params.id,
            this.barrios,
            { headers }
          )
          .then((response) => {
            this.getDatosBarrios();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "configuracion/barrios", this.barrios, { headers })
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    resetForm() {
      this.barrios.barrio = "";
      this.barrios.comuna = "";
    },
  },
};
</script>
