<template>
    <div>
        <div class="row">
            <b-card-group deck>
                <div class="card col-md-3">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                        <br>
                        <img src="../../assets/estadisticas_icons/total.png" class="img-fluid">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Total Personas Encuestadas</h5>
                                <p class="card-text"><strong> {{ formatPrice(totalPersonas) }} </strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-md-3">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                        <br>
                        <img src="../../assets/estadisticas_icons/hombres.png" class="img-fluid">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Total Hombres Encuestados</h5>
                                <p class="card-text"> {{ formatPrice(totalHombres.total_hm_p) }} (<strong> {{ totalHombres.porcentaje }} %) </strong> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-md-3">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                        <br>
                        <img src="../../assets/estadisticas_icons/mujeres.png" class="img-fluid">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Total Mujeres Encuestadas</h5>
                                <p class="card-text"> {{ formatPrice(totalMujeres.total_hm_p) }} (<strong> {{ totalMujeres.porcentaje }} %) </strong> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col-md-3">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                        <br>
                        <img src="../../assets/estadisticas_icons/otros.png" class="img-fluid">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Total Otros Géneros Encuestados</h5>
                                <p class="card-text"> {{ formatPrice(totalOtros.total_otros) }} (<strong> {{ totalOtros.porcentaje }} % </strong> ) </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <br>
                </div>
                <div class="col-md-3"></div>
                <div class="card col-md-3">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                        <br>
                        <img src="../../assets/estadisticas_icons/menores.png" class="img-fluid">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Total Menores de Edad Encuestados</h5>
                                <p class="card-text"> {{ formatPrice(totalMenores.total_menores) }}  (<strong> {{ totalMenores.porcentaje }} % </strong>) </p>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="card col-md-3">
                    <div class="row no-gutters">
                        <div class="col-md-4">
                        <br>
                        <img src="../../assets/estadisticas_icons/mayores.png" class="img-fluid">
                        </div>
                        <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Total Mayores de 65 Años Encuestados</h5>
                                <p class="card-text"> {{ formatPrice(totalMayores.total_mayores) }}  (<strong> {{ totalMayores.porcentaje }} % </strong>)</p>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="col-md-3"></div>      
            </b-card-group>
        </div>
    </div>    
</template>
<script>

import {headers} from '../../api/headers'
import {configApi} from '../../api/configApi'

export default {
    name: 'dashboard',
    data: () => ({
        totalPersonas: 0,
        totalHombres: 0,
        totalMujeres: 0,
        totalMenores: 0,
        totalOtros: 0,
        totalMayores: 0
    }),
    mounted() {
        this.total_personasPrede()
        this.total_hombresPrede()
        this.total_mujeresPrede()
        this.total_menoresPrede()
        this.total_otrosPrede()
        this.total_mayoresPrede()
    },
    methods: {
        async total_personasPrede() {
            let newData = { tipo: 1, prede: 1 }
            return await this.axios.post(configApi+'estadisticas/esta_info_personas', newData, {headers})
            .then(response => {
                this.totalPersonas = response.data[0].total_personas
            }) 
        },
        async total_hombresPrede() {
            let newData = { tipo: 2, prede: 1, genero: 0 }
            return await this.axios.post(configApi+'estadisticas/esta_info_personas_hm', newData, {headers})
            .then(response => {
                this.totalHombres = response.data[0]
            }) 

        },   
        async total_mujeresPrede() {
            let newData = { tipo: 2, prede: 1, genero: 1 }
            return await this.axios.post(configApi+'estadisticas/esta_info_personas_hm', newData, {headers})
            .then(response => {
                this.totalMujeres = response.data[0]
            }) 

        },   
        async total_otrosPrede() {
            let newData = { tipo: 4, prede: 1 }
            return await this.axios.post(configApi+'estadisticas/esta_info_personas_otros', newData, {headers})
            .then(response => {
                this.totalOtros = response.data[0]
            }) 

        },   
        async total_menoresPrede() {
            let newData = { tipo: 3, prede: 1 }
            return await this.axios.post(configApi+'estadisticas/esta_info_personas_menores', newData, {headers})
            .then(response => {
                this.totalMenores = response.data[0]
            }) 

        },    
        async total_mayoresPrede() {
            let newData = { tipo: 5, prede: 1 }
            return await this.axios.post(configApi+'estadisticas/esta_info_personas_mayores', newData, {headers})
            .then(response => {
                this.totalMayores = response.data[0]
            }) 

        },  
        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
    }
}
</script>