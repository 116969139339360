<template>
  <div>
    <a
      href="http://www.vigilacontigo.com/manuales/manual_estadisticas.pdf"
      target="_blank"
      class="float-right"
      title="Ver Manual"
      ><img
        src="http://www.vigilacontigo.com/manuales/pdf.png"
        style="width: 32px; margin-left: -35px"
    /></a>
    <b-tabs content-class="mt-3" align="center">
      <b-tab :title="item.tab" v-for="item in items" :key="item.tab">
        <component :is="item.component" :ref="item.component" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import vigilacontigo from "./vigilacontigo/index.vue";
import sivigila from "./sivigila/index.vue";

export default {
  name: "estadisticas",
  components: {
    vigilacontigo,
    sivigila,
  },
  data: () => ({
    tab: null,
    items: [
      { tab: "VIGILACONTIGO", component: "vigilacontigo" },
      { tab: "SIVIGILA", component: "sivigila" },
    ],
  }),
};
</script>
