<template>
  <section class="vh-100">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img src="./../../assets/porta-op1.png"
            class="img-fluid" alt="Sample image">
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <form @submit.prevent="login">
            <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
              <center><img src="./../../assets/logo_v1.png" width="75%">       </center>
            </div>

            <div class="divider d-flex align-items-center my-4">
              <p class="text-center fw-bold mx-3 mb-0" style="font-weight: bold; font-size: 25px;">Iniciar Sesión</p>
            </div>

            <!-- Email input -->
            <div class="form-outline mb-4">
              <label class="form-label" for="form3Example3">Correo Electrónico</label>
              <input type="email" id="form3Example3" class="form-control form-control-lg"
                placeholder="Correo Electrónico" v-model="formLogin.correo"/>              
            </div>

            <!-- Password input -->
            <div class="form-outline mb-3">
              <label class="form-label" for="form3Example4">Contraseña</label>
              <input type="password" id="form3Example4" class="form-control form-control-lg"
                placeholder="Ingrese Contraseña"  v-model="formLogin.password" />              
            </div>

            <div class="text-center text-lg-start mt-4 pt-2">
              <button type="submit" class="btn btn-success btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem;">Ingresar</button>
            </div>

          </form>
        </div>
      </div>
    </div>   
  </section>
</template>
<script>
export default {
  data: () => ({
    showPassword: false,
    alert: false,
    colorAlert: "",
    textAlert: "",
    colorBtnAlert: "",
    formLogin: {
      correo: "",
      password: ""
    },    
  }),
  methods: {
    login() {
      if(this.formLogin.correo !== null || this.formLogin.password !== null) {
        this.$store.dispatch("retrieveToken", {
          correo: this.formLogin.correo,
          password: this.formLogin.password
        }).then(response => {
          this.$router.push({name:"dashboard"})  
          window.location.reload()          
        }).catch(error => {
          this.$Fun.alertaAccion('error', 'Correo Electrónico o Contraseña incorrecta', true)
        })
      }      
    },
  }
}
</script>
<style>
  .divider:after,
    .divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
    }
    .h-custom {
    height: calc(100% - 73px);
    }
    @media (max-width: 450px) {
    .h-custom {
    height: 100%;
    }
  }
</style>