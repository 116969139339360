<template>
    <div class="card">
        <center>               
            <img src="../assets/404.jpg" class="img-fluid" width="45%">    
            <button @click="regresar" class="btn btn-primary">Ir al Dashboard</button>              
        </center>
    </div>    
</template>
<script>
export default {
    name:'error_page',
    data: () => ({ 

    }),
    methods: {
        regresar() {
            this.$router.push('/dashboard')
        }
    }
}
</script>