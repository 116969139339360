<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>Listar Menus</b>
        <a
          href="http://www.vigilacontigo.com/manuales/manual_usuarios.pdf"
          target="_blank"
          class="float-right"
          v-b-tooltip.hover
          title="Ver Manual"
          ><img
            src="http://www.vigilacontigo.com/manuales/pdf.png"
            style="width: 32px"
        /></a>
      </div>

      <div class="card-body text-dark">
        <b-modal id="crearMenu" size="lg" title="Crear Menú" hide-footer>
          <form>
            <div class="form-group">
              <label>Nombre</label>
              <input
                type="text"
                class="form-control"
                v-model="menus.nombre"
                required
              />
            </div>
            <div class="form-group">
              <label>Ícono</label>
              <input
                type="text"
                class="form-control"
                v-model="menus.icono"
                required
              />
            </div>
            <div class="form-group">
              <label>URL</label>
              <input
                type="text"
                class="form-control"
                v-model="menus.url"
                required
              />
            </div>
            <center>
              <button
                type="submit"
                @click.prevent="saveMenu"
                class="btn btn-success"
              >
                Guardar
              </button>
            </center>
          </form>
        </b-modal>

        <div class="row">
          <div class="col-sm-5">
            <b-button
              class="btn btn-add-vg"
              v-b-modal.crearMenu
              @click="
                resetForm();
                editedIndex = 1;
              "
              ><i class="mdi mdi-plus"></i> Crear Menú</b-button
            ><br /><br />
          </div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getMenus"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-modal
              :id="'menu' + row.item.id"
              size="lg"
              :title="title"
              hide-footer
            >
              <form>
                <div class="form-group">
                  <label>Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="menus.nombre"
                    :disabled="disabled"
                  />
                </div>
                <div class="form-group">
                  <label>Ícono</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="menus.icono"
                    :disabled="disabled"
                  />
                </div>
                <div class="form-group">
                  <label>URL</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="menus.url"
                    :disabled="disabled"
                  />
                </div>
                <center>
                  <button
                    type="submit"
                    @click.prevent="saveMenu"
                    class="btn btn-success"
                    :disabled="disabled"
                  >
                    Guardar
                  </button>
                </center>
              </form>
            </b-modal>
            <b-button
              class="btn btn-info"
              @click="
                $bvModal.show('menu' + row.item.id);
                showModal(row.item, true, 'Ver Menú');
              "
              v-b-tooltip.hover
              title="Ver Menú"
              ><i class="mdi mdi-eye"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-warning"
              @click="
                $bvModal.show('menu' + row.item.id);
                showModal(row.item, false, 'Editar Menú');
                editedIndex = -1;
              "
              v-b-tooltip.hover
              title="Editar Menú"
              ><i class="mdi mdi-pencil text-white"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-danger"
              @click="deleteMenu(row.item)"
              v-b-tooltip.hover
              title="Eliminar Menú"
              ><i class="mdi mdi-delete"></i
            ></b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../../api/headers";
import { configApi } from "./../../../api/configApi";
export default {
  name: "listado_menus",
  data: () => ({
    title: "",
    perPage: 5,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    columns: [
      {
        name: "id",
        th: "ID",
      },
      {
        name: "nombre",
        th: "Nombre",
      },
      {
        name: "url",
        th: "Ruta",
      },
      {
        name: "icono",
        th: "Ícono",
      },
      {
        name: "posicion",
        th: "Orden",
      },
    ],
    fields: [
      { key: "id", label: "ID", sortable: true },
      { key: "nombre", label: "Nombre", sortable: true, class: "text-center" },
      { key: "url", label: "URL", sortable: true, class: "text-center" },
      { key: "icono", label: "Ícono", sortable: true, class: "text-center" },
      { key: "posicion", label: "Orden", sortable: true, class: "text-center" },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
    menus: {
      id: "",
      nombre: "",
      icono: "",
      url: "",
    },
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },

  created() {
    this.getMenus();
  },

  methods: {
    showModal(datos, disabled, title) {
      this.disabled = disabled;
      this.title = title;
      this.menus.id = datos.id;
      this.menus.nombre = datos.nombre;
      this.menus.icono = datos.icono;
      this.menus.url = datos.url;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMenus() {
      return await this.axios
        .get(configApi + "usuarios/menus", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
    async saveMenu() {
      if (this.editedIndex == -1) {
        return await this.axios
          .put(configApi + "usuarios/menus/" + this.menus.id, this.menus, {
            headers,
          })
          .then((response) => {
            this.$bvModal.hide("menu" + this.menus.id);
            this.resetForm();
            this.getMenus();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "usuarios/menus", this.menus, { headers })
          .then((response) => {
            this.$bvModal.hide("crearMenu");
            this.resetForm();
            this.getMenus();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    async deleteMenu(data) {
      let objAlert = {
        titleAlert: "¿Desea eliminar el Menú?",
        url: configApi + "usuarios/menus/" + data.id, // url delete
        headers: { headers },
        messageError: "El Menú no fue Eliminado",
      };

      this.$Fun.alertaConfirmarEliminarAccion(objAlert, (e) => {
        if (e) {
          this.getMenus();
        }
      });
    },
    resetForm() {
      (this.menus.id = ""), (this.menus.nombre = "");
      this.menus.icono = "";
      this.menus.url = "";
    },
  },
};
</script>
