<template>
  <div>
    <div class="row">
      <div class="card col-md-6">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <segui_emfermedades />
            </div>
          </div>
        </div>
      </div>
      <div class="card col-md-6">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <esta_segui_persegui />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import segui_emfermedades from "./esta_segui_enfermedades.vue";
import esta_segui_persegui from "./esta_segui_persegui.vue";

export default {
  components: {
    segui_emfermedades,
    esta_segui_persegui,
  },
};
</script>
