<template>
  <div class="row">
    <div class="col-md-4">
      <label for="">Seleccione Encuesta</label>
      <Select2 v-model="options_map.idencuesta" :options="options_encuestas" />
    </div>
    <div class="col-md-4">
      <label for="">Seleccione Barrio</label>

      <Select2 v-model="options_map.idbarrio" :options="options_barrios" />
    </div>

    <div class="col-md-4">
      <label for="">Seleccione Comuna</label>

      <Select2 v-model="options_map.idcomuna" :options="options_comunas" />
    </div>

    <div class="col-md-4">
      <label for="">Seleccione Fecha Inicio</label>

      <b-input-group class="mb-3">
        <b-form-input
          v-model="options_map.fecha_inicio"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="true"
        ></b-form-input>
        <b-input-group-append>
          <b-form-datepicker
            v-model="options_map.fecha_inicio"
            button-only
            right
            locale="co-CO"
            @context="onContext"
          ></b-form-datepicker>
        </b-input-group-append>
      </b-input-group>
    </div>

    <div class="col-md-4">
      <label for="">Seleccione Fecha Final</label>

      <b-input-group class="mb-3">
        <b-form-input
          v-model="options_map.fecha_final"
          type="text"
          placeholder="YYYY-MM-DD"
          autocomplete="true"
        ></b-form-input>
        <b-input-group-append>
          <b-form-datepicker
            v-model="options_map.fecha_final"
            button-only
            right
            locale="co-CO"
            @context="onContext"
          ></b-form-datepicker>
        </b-input-group-append>
      </b-input-group>
    </div>

    <div class="col-md-4">
      <br />
      <button @click="buscarMaps" class="btn btn-primary">
        <i class="mdi mdi-magnify"></i> Buscar
      </button>
      &nbsp;
      <button
        @click="clear_form"
        class="btn btn-danger"
        v-b-tooltip.hover
        title="Formatear Formulario"
      >
        <i class="mdi mdi-delete"></i>
      </button>
    </div>
    <br /><br /><br /><br /><br />

    <gmap-map
      :center="center"
      :zoom="12"
      style="width: 100%; height: 400px"
      map-type-id="roadmap"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        @click="center = m"
        :icon="m.icon"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";
export default {
  name: "geo_gen_encuestas",
  data() {
    return {
      options_encuestas: [],
      options_barrios: [],
      options_comunas: [],
      options_map: {
        idencuesta: 0,
        idbarrio: 0,
        idcomuna: 0,
        fecha_inicio: "",
        fecha_final: "",
      },
      formatted: "",
      selected: "",
      center: { lat: 4.1436563, lng: -73.6419066 },
      markers: [],
      currentPlace: null,
    };
  },
  mounted() {
    this.getEncuestas(), this.getComunas(), this.getBarrios();
  },
  methods: {
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
    },
    async getEncuestas() {
      return await this.axios
        .get(configApi + "encuestas/encuestas", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            if (data[i].fechageneracion != null) {
              this.options_encuestas.push({
                id: data[i].idproyecto,
                text: data[i].nombreproyecto,
              });
            }
          }
        });
    },
    async getBarrios() {
      this.options_barrios = [];
      return await this.axios
        .get(configApi + "configuracion/barrios", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_barrios.push({
              id: data[i].idbarrio,
              text: data[i].barrio,
            });
          }
        });
    },
    async getComunas() {
      this.options_comunas = [];
      return await this.axios
        .get(configApi + "configuracion/comunas", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_comunas.push({
              id: data[i].idcomuna,
              text: data[i].comuna,
            });
          }
        });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    buscarMaps() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });

      this.axios
        .post(configApi + "informes/geo_general", this.options_map, { headers })
        .then((response) => {
          if (response.data.length) {
            this.markers = [];

            for (let i = 0; i < response.data.length; i++) {
              this.markers[i] = {
                lat: parseFloat(response.data[i].lat),
                lng: parseFloat(response.data[i].lng),
                icon: require("../../assets/maps/position.png"),
              };
            }
          } else {
            this.markers = [];

            this.$Fun.alertaAccion(
              "warning",
              "No se encontraron datos...",
              false
            );
          }
        });
    },
    clear_form() {
      this.options_map.idencuesta = 0;
      this.options_map.idbarrio = 0;
      this.options_map.idcomuna = 0;
      this.options_map.fecha_inicio = "";
      this.options_map.fecha_final = "";
      this.markers = [];
    },
  },
};
</script>
<style>
.select2-container {
  min-width: 100% !important;
}
</style>
