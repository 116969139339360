<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Listar Eventos Sivigila</b></div>

      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5">
            <b-button class="btn btn-add-vg" :to="'/sivigila/subir'"
              ><i class="mdi mdi-upload"></i> Subir Evento Sivigila</b-button
            >
            &nbsp;
            <b-button class="btn btn-info" :to="'/sivigila/parametros'"
              ><i class="mdi mdi-toggle-switch"></i> Configurar
              Parámetros</b-button
            >
            <br /><br />
          </div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getEventoSivigila"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          sticky-header
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-button
              class="btn btn-info"
              :to="'/sivigila/ver/' + row.item.id"
              v-b-tooltip.hover
              title="Ver Información Evento"
              ><i class="mdi mdi-eye text-white"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-success"
              :to="'/sivigila/agregar/' + row.item.id"
              v-b-tooltip.hover
              title="Agregar Registros"
              ><i class="mdi mdi-plus text-white"></i></b-button
            >&nbsp;
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../api/headers";
import { configApi } from "./../../api/configApi";
export default {
  name: "listado_barrios",
  data: () => ({
    title: "",
    perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    fields: [
      { key: "id", label: "ID", sortable: true, class: "text-center" },
      {
        key: "nombre",
        label: "Nombre Evento Sivigila",
        sortable: true,
        class: "text-center",
      },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.validarAccesoRutas();
  },
  created() {
    this.getEventoSivigila();
  },

  methods: {
    validarAccesoRutas() {
      return this.axios
        .get(configApi + "usuarios/obtener_rol", { headers })
        .then((response) => {
          let rol_usuario = response.data.data[0].id_rol;
          let ruta_actual = this.$router.currentRoute.meta.access;
          let salir = false;

          for (let i = 0; i < ruta_actual.length; i++) {
            if (ruta_actual[i] == rol_usuario) {
              salir = false;
              break;
            } else {
              salir = true;
            }
          }

          if (salir) {
            this.$router.push("/dashboard");
          }
        });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getEventoSivigila() {
      return await this.axios
        .get(configApi + "sivigila/sivigila", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
  },
};
</script>
