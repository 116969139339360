<template>
  <div>
    <div class="row">
      <div class="form-group col-sm-10">
        <label>Seleccione Encuesta</label>
        <Select2
          v-model="encuesta"
          @change="getPersonasBarrios"
          :options="options_encuestas"
          required
        />
      </div>
    </div>
    <div class="graphic-container">
      <VueApexCharts
        :height="sizeC"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "esta_personabarrios",
  components: { VueApexCharts },
  data() {
    return {
      encuesta: "",
      options_encuestas: [],
      sizeC: 0,
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 6,
            horizontal: true,
            distributed: false,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#000000"],
          },
          formatter(val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [],
        },
        title: {
          text: "Personas Encuestadas por Barrio",
          align: "center",
          floating: true,
        },
        subtitle: {
          text: "Cantidad de Personas Encuestadas por Barrio",
          align: "center",
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter() {
                return "";
              },
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getEncuestas();
  },
  methods: {
    async getPersonasBarrios() {
      this.series[0].data = [];
      this.chartOptions.xaxis.categories = [];
      return await this.axios
        .get(
          configApi + "estadisticas/esta_personas_barrios/" + this.encuesta,
          { headers }
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.series[0].data.push(response.data[i].personas);
            this.chartOptions.xaxis.categories.push(response.data[i].barrios);
          }

          if (response.data.length == 1) {
            this.sizeC = response.data.length * 120;
          } else if(response.data.length < 10) {
            this.sizeC = response.data.length * 70;
          } else {
            this.sizeC = response.data.length * 30;
          }
        });
    },
    async getEncuestas() {
      return await this.axios
        .get(configApi + "encuestas/encuestas", { headers })
        .then((response) => {
          let data = response.data.data;

          if (data.length > 0) {
            this.options_encuestas.push({
              id: 0,
              text: "TODAS",
            });

            this.encuesta = 0;
          }

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_encuestas.push({
              id: data[i].idproyecto,
              text: data[i].nombreproyecto,
            });
          }

          this.getPersonasBarrios();
        });
    },
  },
};
</script>
<style>
.graphic-container {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
