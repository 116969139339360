<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Evento Sivigila</b>
      </div>
      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-11">
            <router-link to="/sivigila" class="btn btn-info"
              ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
            >
          </div>
          <div class="col-sm-1">
            <download-excel
              class="btn btn-success"
              v-b-tooltip.hover
              title="Descargar Formato"
              :data="itemsFormato"
              :name="'Formato ' + model_encuesta_sivigila.nombre + '.xls'"
            >
              <i class="mdi mdi-file-excel"></i>
            </download-excel>
          </div>
        </div>
        <br />
        <form
          @submit.prevent="saveEventoSivigila"
          enctype="multipart/form-data"
        >
          <div class="card">
            <div class="card-body" style="margin: auto">
              <div class="row">
                <b-card
                  border-variant="info"
                  header="Homologación Enfermedad Sivigila con Vigilacontigo"
                  header-bg-variant="info"
                  header-text-variant="white"
                  class="col-sm-12"
                >
                  <div class="row">
                    <div class="form-group col-sm-6">
                      <label>Grupo de Eventos </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="model_encuesta_sivigila.nombrerias"
                        :disabled="true"
                      />
                    </div>

                    <div class="form-group col-sm-6">
                      <label>Enfermedad </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="model_encuesta_sivigila.evento"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </b-card>
                <div class="col-md-12"><br /></div>
                <b-card
                  border-variant="info"
                  header="Información Cargue de Evento"
                  header-bg-variant="info"
                  header-text-variant="white"
                  class="col-sm-12"
                >
                  <div class="row">
                    <div class="form-group col-md-12">
                      <span>Nombre Evento Sivigila</span>
                      <input
                        type="text"
                        class="form-control"
                        :disabled="true"
                        v-model="model_encuesta_sivigila.nombre"
                      />
                    </div>

                    <div class="form-group col-md-12">
                      <input
                        type="file"
                        name="image"
                        id="archivoSivigila"
                        @change="getFile"
                        accept=".xlsx, .csv"
                      />
                    </div>
                  </div>
                </b-card>
                <div class="form-group col-sm-12">
                  <center>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";

import readXlsFile from "read-excel-file";

export default {
  name: "municipios_crear",
  data: () => ({
    disabled: false,
    title: "Agregar Registros ",
    model_sivigila: {
      items: [],
    },
    itemsFormato: [],
    model_encuesta_sivigila: {
      idEncuesta: "",
      nombre: "",
      idriasevento: "",
      evento: "",
      idrias: "",
      nombrerias: "",
      itemsEvento: [],
    },
    id_enfermedad: "",
    id_grupoevento: "",
  }),
  mounted() {
    this.getEventoSivigila();
    this.getFormatoSivigila();
  },
  computed: {},
  methods: {
    async getEventoSivigila() {
      return await this.axios
        .get(
          configApi + "sivigila/encuestas_svg_agregar/" + this.$route.params.id,
          {
            headers,
          }
        )
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.model_encuesta_sivigila.idEncuesta = data[i].id;
            this.model_encuesta_sivigila.nombre = data[i].nombre;
            this.model_encuesta_sivigila.evento = data[i].evento;
            this.model_encuesta_sivigila.nombrerias = data[i].nombre_rias;
            this.model_encuesta_sivigila.idriasevento = data[i].id_enfermedad;
            this.model_encuesta_sivigila.idrias = data[i].rias;
            this.id_enfermedad = data[i].id_enfermedad;
            this.id_grupoevento = data[i].rias;
          }
        });
    },
    async getFormatoSivigila() {
      return await this.axios
        .get(configApi + "sivigila/formato/" + this.$route.params.id, {
          headers,
        })
        .then((response) => {
          this.itemsFormato = response.data.data;
        });
    },
    getFile() {
      const input = document.getElementById("archivoSivigila");
      readXlsFile(input.files[0]).then((rows) => {
        console.log(rows);
        //this.model_sivigila.items = rows;
        this.model_encuesta_sivigila.itemsEvento = rows;
      });
    },
    async saveEventoSivigila() {
      return await this.axios
        .post(configApi + "sivigila/agregarEvento", this.model_encuesta_sivigila, { headers })
        .then((response) => {
          console.log(response);
          this.resetForm();
          this.$Fun.alertaAccion("success", response.data.message, false);
        });
    },
    resetForm() {
      //this.model_sivigila.items = [];
      this.model_encuesta_sivigila.itemsEvento = [];
    },
  },
};
</script>
