<template>
  <div class="row">
    <div class="col-md-6">
      <label for="">Seleccione Evento Sivigila</label>
      <Select2 v-model="options_map.idencuesta" :options="options_encuestas" />
    </div>

    <div class="col-md-4">
      <br />
      <button
        @click="
          listMarkers = true;
          buscarMaps();
        "
        class="btn btn-primary"
      >
        <i class="mdi mdi-magnify"></i> Buscar</button
      >&nbsp;
      <button
        @click="clear_form"
        class="btn btn-danger"
        v-b-tooltip.hover
        title="Formatear Formulario"
      >
        <i class="mdi mdi-delete"></i>
      </button>
    </div>
    <br /><br /><br /><br /><br />

    <gmap-map
      :center="center"
      :zoom="12"
      style="width: 100%; height: 400px"
      map-type-id="roadmap"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        @click="center = m"
        :icon="m.icon"
      ></gmap-marker>
    </gmap-map>
    <div class="col-md-12"><br /><br /></div>
    <div class="col-md-12">
      <div class="row" v-if="listMarkers">
        <div
          class="card col-md-3"
          v-for="rias in rias_pointer"
          v-bind:key="rias"
        >
          <div class="row no-gutters">
            <div class="col-md-4">
              <br />
              <img
                :src="require('../../assets/maps/colors/' + rias.color)"
                class="img-fluid"
              />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">{{ rias.nombre_rias }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { configApi } from "../../api/configApi";
import { headers } from "../../api/headers";

export default {
  name: "geo_gen_encuestas",
  components: {},
  data() {
    return {
      options_encuestas: [],
      options_map: {
        idencuesta: 0,
        idria: 0,
        idriasevento: 0,
      },
      formatted: "",
      selected: "",
      center: { lat: 4.1436563, lng: -73.6419066 },
      markers: [],
      currentPlace: null,
      rias_pointer: [],
      listMarkers: false,
    };
  },
  mounted() {
    this.listEncuestas(),
      this.getRiasPointer()
  },
  methods: {
    clear_form() {
      this.options_map.idencuesta = 0;
      this.options_map.idria = 0;
      this.options_map.idriasevento = 0;
      this.markers = [];
    },
    onContext(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
    },
    async getRiasPointer() {
      return await this.axios
        .get(configApi + "grupo_eventos/grupos_eventos", { headers })
        .then((response) => {
          this.rias_pointer = response.data.data;
          this.rias_pointer.push({
            nombre_rias: "Sivigila",
            color: "position-sivigila.png",
          });

          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_rias.push({
              id: data[i].idrias,
              text: data[i].nombre_rias,
            });
          }
        });
    },
    async listEncuestas() {
      return await this.axios
        .get(configApi + "sivigila/sivigila", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
              this.options_encuestas.push({
                id: data[i].id,
                text: data[i].nombre,
              });
          }
        });
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    buscarMaps() {
      this.listMarkers = true;

      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });

      this.axios
        .post(configApi + "informes/geo_enfermedades_sivigila", this.options_map, {
          headers,
        })
        .then((response) => {
          if (response.data.length > 0) {
            this.markers = [];

            for (let i = 0; i < response.data.length; i++) {
              this.markers[i] = {
                lat: parseFloat(response.data[i].lat),
                lng: parseFloat(response.data[i].lng),
                icon: require("../../assets/maps/colors/" +
                  response.data[i].color),
              };
            }
          } else {
            this.markers = [];

            this.$Fun.alertaAccion(
              "warning",
              "No se encontraron datos...",
              false
            );
          }
        });
    },
  },
};
</script>
