<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Evento</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/grupo-eventos" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveEnfermedad">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="enfermedades.evento"
                    :disabled="disabled"
                    required
                  />
                </div>
                <div class="form-group col-sm-12">
                  <label>Programa</label>
                  <Select2
                    v-model="enfermedades.rias"
                    :options="options_grupoeventos"
                    required
                  />
                </div>
                <div class="form-group col-sm-12">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
export default {
  name: "gruposeventos_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    options_grupoeventos: [],
    enfermedades: {
      idriasevento: "",
      rias: "",
      evento: "",
    },
  }),
  mounted() {
    this.getEnfermedad();
    this.getGrupoEventos();
  },
  computed: {},
  methods: {
    async getGrupoEventos() {
      return await this.axios
        .get(configApi + "grupo_eventos/grupos_eventos", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_grupoeventos.push({
              id: data[i].idrias,
              text: data[i].nombre_rias,
            });
          }
        });
    },
    async getEnfermedad() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(
            configApi + "grupo_eventos/enfermedades/" + this.$route.params.id,
            { headers }
          )
          .then((response) => {
            this.enfermedades.idriasevento = response.data.data.idriasevento;
            this.enfermedades.rias = response.data.data.rias;
            this.enfermedades.evento = response.data.data.evento;

            if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
              this.disabled = false;
              this.title = "Editar";
            }
          });
      }
    },
    async saveEnfermedad() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi + "grupo_eventos/enfermedades/" + this.$route.params.id,
            this.enfermedades,
            { headers }
          )
          .then((response) => {
            this.getEnfermedad();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "grupo_eventos/enfermedades", this.enfermedades, {
            headers,
          })
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    resetForm() {
      this.enfermedades.idriasevento = "";
      this.enfermedades.evento = "";
      this.enfermedades.rias = "";
    },
  },
};
</script>
