<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>Listar Usuarios</b>
        <a
          href="http://www.vigilacontigo.com/manuales/manual_usuarios.pdf"
          target="_blank"
          class="float-right"
          v-b-tooltip.hover
          title="Ver Manual"
          ><img
            src="http://www.vigilacontigo.com/manuales/pdf.png"
            style="width: 32px"
        /></a>
      </div>
      <div class="card-body text-dark">
        <b-modal id="crearUsuario" size="lg" title="Crear Usuario" hide-footer>
          <form @submit.prevent="saveUser">
            <div class="row">
              <div class="form-group col-sm-12">
                <label>Nombres <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="users.nombres"
                  required
                />
              </div>
              <div class="form-group col-sm-12">
                <label>Apellidos <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="users.apellidos"
                  required
                />
              </div>
              <div class="form-group col-sm-12">
                <label
                  >Correo Electrónico <span class="text-danger">*</span></label
                >
                <input
                  type="email"
                  class="form-control"
                  v-model="users.correo"
                  required
                />
              </div>
              <div class="form-group col-sm-12">
                <label>Teléfono <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="users.telefono"
                  required
                />
              </div>
              <div class="form-group col-sm-12">
                <label>Documento <span class="text-danger">*</span></label>
                <input
                  type="text"
                  class="form-control"
                  v-model="users.documento"
                  required
                />
              </div>
              <div class="form-group col-sm-12">
                <label
                  >Seleccionar Rol <span class="text-danger">*</span></label
                >
                <select v-model="users.id_rol" class="form-control" required>
                  <option
                    :value="rols.id"
                    v-for="rols in roles"
                    v-bind:key="rols.id"
                  >
                    {{ rols.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-12">
                <center>
                  <button type="submit" class="btn btn-success">Guardar</button>
                </center>
              </div>
            </div>
          </form>
        </b-modal>

        <div class="row">
          <div class="col-sm-5">
            <b-button
              class="btn btn-add-vg"
              v-b-modal.crearUsuario
              @click="
                resetForm();
                editedIndex = 1;
              "
              ><i class="mdi mdi-plus"></i> Crear Usuario</b-button
            ><br /><br />
          </div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getUsers"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-modal
              :id="'user' + row.item.id"
              size="lg"
              :title="title"
              hide-footer
            >
              <form @submit.prevent="saveUser">
                <div class="row">
                  <div class="form-group col-sm-12">
                    <label>Nombres <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="users.nombres"
                      :disabled="disabled"
                      required
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Apellidos <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="users.apellidos"
                      :disabled="disabled"
                      required
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <label
                      >Correo Electrónico
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      v-model="users.correo"
                      :disabled="disabled"
                      required
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Teléfono <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="users.telefono"
                      :disabled="disabled"
                      required
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Documento <span class="text-danger">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="users.documento"
                      :disabled="disabled"
                      required
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <label
                      >Seleccionar Rol <span class="text-danger">*</span></label
                    >
                    <select
                      v-model="users.id_rol"
                      class="form-control"
                      :disabled="disabled"
                    >
                      <option
                        :value="rols.id"
                        v-for="rols in roles"
                        v-bind:key="rols.id"
                      >
                        {{ rols.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-12">
                    <label>Estado <span class="text-danger">*</span></label>
                    <b-form-select
                      v-model="users.estado"
                      class="form-control"
                      :options="options_estados"
                      value-field="id"
                      text-field="text"
                      :disabled="disabled"
                      required
                    ></b-form-select>
                  </div>
                  <div v-show="disabled" class="form-group col-sm-12">
                    <label>Fecha de Creación </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="users.created_at"
                      :disabled="disabled"
                    />
                  </div>
                  <div class="form-group col-sm-12">
                    <center>
                      <button
                        type="submit"
                        :disabled="disabled"
                        class="btn btn-success"
                      >
                        Guardar
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </b-modal>

            <b-button
              class="btn btn-info"
              @click="
                $bvModal.show('user' + row.item.id);
                showModal(row.item, true, 'Ver Usuario');
              "
              v-b-tooltip.hover
              title="Ver Usuario"
              ><i class="mdi mdi-eye"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-warning"
              @click="
                $bvModal.show('user' + row.item.id);
                showModal(row.item, false, 'Editar Usuario');
                editedIndex = -1;
              "
              v-b-tooltip.hover
              title="Editar Usuario"
              ><i class="mdi mdi-pencil text-white"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-danger"
              @click="deleteUser(row.item)"
              v-b-tooltip.hover
              title="Eliminar Usuario"
              ><i class="mdi mdi-delete"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-success"
              @click="restablecer(row.item)"
              v-b-tooltip.hover
              title="Restablecer Contraseña"
              ><i class="mdi mdi-backup-restore"></i></b-button
            >&nbsp;
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import VueLoading from 'vue-loading-overlay';
// import 'vue-loading-overlay/dist/vue-loading.css';

import { headers } from "./../../../api/headers";
import { configApi } from "./../../../api/configApi";
export default {
  name: "listado_users",
  components: {
    // VueLoading
  },
  data: () => ({
    disabled: false,
    title: "",
    items: [],
    roles: [],
    isLoading: true,
    fullPage: true,
    perPage: 5,
    loading: true,
    filter: null,
    currentPage: 1,
    editedIndex: -1,
    users: {
      id: "",
      nombres: "",
      apellidos: "",
      correo: "",
      telefono: "",
      documento: "",
      id_rol: "",
      estado: "",
      created_at: "",
    },
    options_estados: [
      { id: 1, text: "Activo" },
      { id: 0, text: "Inactivo" },
    ],
    columns: [
      {
        name: "id",
        th: "ID",
      },
      {
        name: "nombres",
        th: "Nombre",
      },
      {
        name: "correo",
        th: "Correo Electrónico",
      },
      {
        name: "telefono",
        th: "Teléfono",
      },
      {
        name: "documento",
        th: "Documento",
      },
    ],
    fields: [
      { key: "id", label: "ID", sortable: true },
      { key: "nombres", label: "Nombre", sortable: true, class: "text-center" },
      {
        key: "correo",
        label: "Correo Electrónico",
        sortable: true,
        class: "text-center",
      },
      {
        key: "telefono",
        label: "Teléfono",
        sortable: true,
        class: "text-center",
      },
      {
        key: "documento",
        label: "Documento",
        sortable: true,
        class: "text-center",
      },
      {
        key: "estado",
        label: "Estado",
        sortable: true,
        class: "text-center",
      },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Usuario" : "Editar Usuario";
    },
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.getRoles();
  },
  watch: {},

  created() {
    this.getUsers();
  },

  methods: {
    showModal(datos, disabled, title) {
      this.disabled = disabled;
      this.title = title;
      this.users.id = datos.id;
      this.users.nombres = datos.nombres;
      this.users.apellidos = datos.apellidos;
      this.users.correo = datos.correo;
      this.users.telefono = datos.telefono;
      this.users.documento = datos.documento;
      this.users.id_rol = datos.id_rol;
      //this.users.estado = datos.estado;
      this.users.created_at = datos.created_at;

      if (datos.estado == "ACTIVO") {
        this.users.estado = 1;
      } else {
        this.users.estado = 0;
      }
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getUsers() {
      return await this.axios
        .get(configApi + "usuarios/users", { headers })
        .then((response) => {
          this.items = response.data.data;

          this.items.forEach(function (item) {
            if (item.estado == 1) {
              item.estado = "ACTIVO";
            } else {
              item.estado = "INACTIVO";
            }
          });

          this.loading = false;
        });
    },
    async getRoles() {
      return await this.axios
        .get(configApi + "usuarios/roles", { headers })
        .then((response) => {
          this.roles = response.data.data;
        });
    },
    async saveUser() {
      if (this.editedIndex == -1) {
        return await this.axios
          .put(configApi + "usuarios/users/" + this.users.id, this.users, {
            headers,
          })
          .then((response) => {
            this.$bvModal.hide("user" + this.users.id);
            this.resetForm();
            this.getUsers();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "usuarios/users", this.users, { headers })
          .then((response) => {
            console.log(response.data);
            this.$bvModal.hide("crearUsuario");
            this.resetForm();
            this.getUsers();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    async deleteUser(data) {
      let objAlert = {
        titleAlert: "¿Desea eliminar el Usuario?",
        url: configApi + "usuarios/users/" + data.id, // url delete
        headers: { headers },
        messageError: "El Usuario no fue Eliminado",
      };

      this.$Fun.alertaConfirmarEliminarAccion(objAlert, (e) => {
        console.log(objAlert.messageError);
        if (e) {
          this.getUsers();
        } else {
          this.$Fun.alertaAccion(
            "warning",
            "El Usuario No Se Puede Eliminar",
            false
          );
        }
      });
    },
    async restablecer(data) {
      let objAlert = {
        titleAlert: "¿Desea restablecer la contraseña?",
        url: configApi + "usuarios/restablecer_password/" + data.correo,
        request: data,
        headers: { headers },
        messageError: "No se restablecerá.",
      };

      this.$Fun.restablecerPassword(objAlert, (e) => {
        if (e) {
          this.getUsers();
        }
      });
    },
    async activarUsuario(data) {
      let objAlert = {
        titleAlert: "¿Desea activar este usuario?",
        url: configApi + "usuarios/activa_usuario/" + data.id,
        request: data,
        headers: { headers },
        messageError: "El Usuario no fue activado",
      };

      this.$Fun.alertaConfirmarEstadoAccion(objAlert, (e) => {
        if (e) {
          this.getUsers();
        }
      });
    },
    resetForm() {
      this.users.id = "";
      this.users.nombres = "";
      this.users.apellidos = "";
      this.users.correo = "";
      this.users.telefono = "";
      this.users.documento = "";
      this.users.id_rol = "";
      this.users.estado = "";
    },
  },
};
</script>
