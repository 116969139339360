<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>Listar Municipios</b>
        <a
          href="http://www.vigilacontigo.com/manuales/manual_configuracion.pdf"
          target="_blank"
          class="float-right"
          v-b-tooltip.hover
          title="Ver Manual"
          ><img
            src="http://www.vigilacontigo.com/manuales/pdf.png"
            style="width: 32px"
        /></a>
      </div>
      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5">
            <b-button
              class="btn btn-add-vg"
              :to="'/configuracion/municipios/crear'"
              ><i class="mdi mdi-plus"></i> Crear Municipio</b-button
            ><br /><br />
          </div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getMunicipios"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          sticky-header
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-button
              class="btn btn-warning"
              :to="'/configuracion/municipios/editar/' + row.item.codmun + '/0'"
              v-b-tooltip.hover
              title="Editar Municipio"
              ><i class="mdi mdi-pencil text-white"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-danger"
              @click="deleteMunicipio(row.item)"
              v-b-tooltip.hover
              title="Eliminar Municipio"
              ><i class="mdi mdi-delete"></i
            ></b-button>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../../api/headers";
import { configApi } from "./../../../api/configApi";
export default {
  name: "listado_menus",
  data: () => ({
    title: "",
    perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    columns: [
      {
        name: "codmun",
        th: "Cod. Municipio",
      },
      {
        name: "municipio",
        th: "Nombre",
      },
      {
        name: "departamento",
        th: "Departamento",
      },
      {
        name: "latitud",
        th: "Latitud",
      },
      {
        name: "longitud",
        th: "Longitud",
      },
    ],
    fields: [
      { key: "codmun", label: "Cod. Municipio", sortable: true },
      {
        key: "municipio",
        label: "Nombre",
        sortable: true,
        class: "text-center",
      },
      {
        key: "departamento",
        label: "Departamento",
        sortable: true,
        class: "text-center",
      },
      {
        key: "latitud",
        label: "Latitud",
        sortable: true,
        class: "text-center",
      },
      {
        key: "longitud",
        label: "Longitud",
        sortable: true,
        class: "text-center",
      },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    editedIndex: -1,
    menus: {
      id: "",
      nombre: "",
      icono: "",
      url: "",
    },
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },

  created() {
    this.getMunicipios();
  },

  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMunicipios() {
      return await this.axios
        .get(configApi + "configuracion/municipios", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
    async deleteMunicipio(data) {
      let objAlert = {
        titleAlert: "¿Desea eliminar el Municipio?",
        url: configApi + "configuracion/municipios/" + data.codmun, // url delete
        headers: { headers },
        messageError: "El Municipio no fue Eliminado",
      };

      this.$Fun.alertaConfirmarEliminarAccion(objAlert, (e) => {
        if (e) {
          this.getMunicipios();
        }
      });
    },
  },
};
</script>
