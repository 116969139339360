<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Listar Registros Cargados</b></div>

      <div class="card-body text-dark">
        <div class="row">
          <div class="col-sm-5">
            <router-link to="/sivigila" class="btn btn-info"
              ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
            ><br /><br />
          </div>
          <div class="col-sm-5">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getEventoSivigila"
              v-b-tooltip.hover
              title="Actualizar Datos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>

          <div class="col-sm-1">
            <download-excel
              class="btn btn-success"
              v-b-tooltip.hover
              title="Descargar Listado"
              :data="items"
              :name="'listado_registros_cargados_' + nombre_evento + '.xls'"
            >
              <i class="mdi mdi-file-excel"></i>
            </download-excel>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>

        <b-table
          class="table table-responsive table-bordered"
          stacked="md"
          show-empty
          sticky-header
          :small="true"
          :items="items"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
          style="overflow-y: scroll; height: 650px"
        >
        </b-table>

        <!-- <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                ></b-pagination> -->
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../api/headers";
import { configApi } from "./../../api/configApi";
export default {
  name: "listado_barrios",
  data: () => ({
    title: "",
    nombre_evento: "",
    // perPage: 10,
    filter: null,
    currentPage: 1,
    disabled: false,
    viewModal: false,
    loading: true,
    items: [],
    editedIndex: -1,
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mounted() {
    this.validarAccesoRutas();
  },
  created() {
    this.getEventoSivigila();
  },

  methods: {
    validarAccesoRutas() {
      return this.axios
        .get(configApi + "usuarios/obtener_rol", { headers })
        .then((response) => {
          let rol_usuario = response.data.data[0].id_rol;
          let ruta_actual = this.$router.currentRoute.meta.access;
          let salir = false;

          for (let i = 0; i < ruta_actual.length; i++) {
            if (ruta_actual[i] == rol_usuario) {
              salir = false;
              break;
            } else {
              salir = true;
            }
          }

          if (salir) {
            this.$router.push("/dashboard");
          }
        });
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getEventoSivigila() {
      return await this.axios
        .get(configApi + "sivigila/sivigila/" + this.$route.params.id, {
          headers,
        })
        .then((response) => {
          this.items = response.data.data;
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.nombre_evento = "evento_sivigila_" + data[i].cod_eve;
            break;
          }

          this.loading = false;
        });
    },
  },
};
</script>
