<template>
    <div>
        <b-tabs content-class="mt-3" align="center">
            <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                <component :is="item.component" :ref="item.component" />
            </b-tab>
        </b-tabs>
        
    </div>
    
</template>
<script>

import municipios from './municipios/listado.vue'
import comunas from './comunas/listado.vue'
import barrios from './barrios/listado.vue'

export default {
    name: 'listado_usuarios',
    components: {
        barrios,
        comunas,
        municipios
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Barrios', component: 'barrios' },
          { tab: 'Comunas', component: 'comunas' },
          { tab: 'Municipios', component: 'municipios' },
        ],
    })
}
</script>