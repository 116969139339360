import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueAxios from 'vue-axios'
import router from './routes/router'
import store from './routes/store'
import * as VueGoogleMaps from "vue2-google-maps"
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Select2 from 'v-select2-component'
import Multiselect from 'vue-multiselect'
import JsonExcel from "vue-json-excel"

Vue.component("downloadExcel", JsonExcel)
Vue.component('multiselect', Multiselect)

Vue.component('Select2', Select2);

Vue.use(VueAxios, Axios)

Vue.use(BootstrapVue)

import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2)

// Estadisticas
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

//Georeferenciación
Vue.use(VueGoogleMaps, {
  load: {    
    //key: "AIzaSyBsVBG9jaGVjPy3aO8FWdsS-Oilu7Wm9fU",
    key: "AIzaSyBBr-DAldZT6NsDmbVk_320AYqf2IV6-t4",
    libraries: "places", // necessary for places input
    version: "3.26",
    v:"weekly",
    map_ids:"8e0a97af9386fef",
  }
});

import Fun from "./funciones.js";
Vue.prototype.$Fun = Fun;

Vue.config.productionTip = false

require("bootstrap-vue-datatable")

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {

    if(!store.getters.loggedIn) {

      next({name:'login'})

    } else {
        next()
    }

  } else {
    next()
  }

})

new Vue({
  BootstrapVue,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
