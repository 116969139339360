<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Evento Sivigila</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/sivigila" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form
          @submit.prevent="saveEventoSivigila"
          enctype="multipart/form-data"
        >
          <div class="card">
            <div class="card-body" style="margin: auto">
              <div class="row">
                <b-card
                  border-variant="info"
                  header="Homologación Enfermedad Sivigila con Vigilacontigo"
                  header-bg-variant="info"
                  header-text-variant="white"
                  class="col-sm-12"
                >
                  <div class="row">
                    <div class="form-group col-md-6">
                      <span>Seleccione Grupo de Eventos</span>
                      <Select2
                        @change="getEnfermedad"
                        v-model="model_sivigila.idrias"
                        :options="options_grupoeventos"
                      />
                    </div>
                    <div class="form-group col-md-6">
                      <span>Seleccione Enfermedad</span>
                      <Select2
                        v-model="model_sivigila.idriasevento"
                        :options="options_enfermedades"
                      />
                    </div>
                  </div>
                </b-card>
                <div class="col-md-12"><br /></div>
                <b-card
                  border-variant="info"
                  header="Información Cargue de Evento"
                  header-bg-variant="info"
                  header-text-variant="white"
                  class="col-sm-12"
                >
                  <div class="row">
                    <div class="form-group col-md-12">
                      <span>Nombre Evento Sivigila</span>
                      <input
                        type="text"
                        class="form-control"
                        :disabled="disabled"
                        v-model="model_sivigila.nombre"
                      />
                    </div>

                    <div class="form-group col-md-12">
                      <input
                        type="file"
                        name="image"
                        id="archivoSivigila"
                        @change="getFile"
                        accept=".xlsx, .csv"
                      />
                    </div>
                  </div>
                </b-card>
                <div class="form-group col-sm-12">
                  <center>
                    <br />
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";

import readXlsFile from "read-excel-file";

export default {
  name: "municipios_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    model_sivigila: {
      nombre: "",
      documento: "",
      items: [],
      idriasevento: "",
      idrias: "",
    },
    options_enfermedades: [],
    options_grupoeventos: [],
    options_sino: [
      { value: 1, text: "Si" },
      { value: 0, text: "No" },
    ],
    options_agentes: [],
    options_profesionales: [],
  }),
  mounted() {
    this.getGrupoEventos();
  },
  computed: {},
  methods: {
    async getGrupoEventos() {
      return await this.axios
        .get(configApi + "grupo_eventos/grupos_eventos", { headers })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_grupoeventos.push({
              id: data[i].idrias,
              text: data[i].nombre_rias,
            });
          }
        });
    },
    async getEnfermedad() {
      this.options_enfermedades = [];

      return await this.axios
        .get(
          configApi +
            "grupo_eventos/enfermedades_x_grupoevento/" +
            this.model_sivigila.idrias,
          { headers }
        )
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_enfermedades.push({
              id: data[i].idriasevento,
              text: data[i].evento,
            });
          }
        });
    },
    getFile() {
      const input = document.getElementById("archivoSivigila");
      readXlsFile(input.files[0]).then((rows) => {
        console.log(rows);
        this.model_sivigila.items = rows;
      });
    },
    async saveEventoSivigila() {
      return await this.axios
        .post(configApi + "sivigila/sivigila", this.model_sivigila, { headers })
        .then((response) => {
          this.resetForm();
          this.$Fun.alertaAccion("success", response.data.message, false);
        });
    },
    resetForm() {
      this.model_sivigila.nombre = "";
      this.model_sivigila.documento = "";
      this.model_sivigila.items = [];
      this.model_sivigila.idriasevento = "";
      this.model_sivigila.idrias = "";
    },
  },
};
</script>
