<template>
  <div>
    <b-card no-body>
      <b-tabs pills card>
        <b-tab :title="item.tab" v-for="item in items" :key="item.tab">
          <component :is="item.component" :ref="item.component" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import esta_eventos_sivigila from "./esta_eventos_sivigila.vue";

export default {
  name: "estadistica_sivigila",
  components: {
    esta_eventos_sivigila,
  },
  data: () => ({
    tab: null,
    items: [{ tab: "Estadísticas / Eventos Sivigila", component: "esta_eventos_sivigila" }],
  }),
};
</script>
