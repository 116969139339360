<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>Listar Permisos</b>
        <a
          href="http://www.vigilacontigo.com/manuales/manual_usuarios.pdf"
          target="_blank"
          class="float-right"
          v-b-tooltip.hover
          title="Ver Manual"
          ><img
            src="http://www.vigilacontigo.com/manuales/pdf.png"
            style="width: 32px"
        /></a>
      </div>
      <div class="card-body text-dark">
        <b-modal id="crearPermiso" size="lg" title="Crear Permiso" hide-footer>
          <form>
            <div class="row">
              <div class="form-group col-sm-6">
                <label>Crear <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="permisos.crear"
                  :options="lista_permisos"
                ></b-form-select>
              </div>
              <div class="form-group col-sm-6">
                <label>Leer <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="permisos.leer"
                  :options="lista_permisos"
                ></b-form-select>
              </div>
              <div class="form-group col-sm-6">
                <label>Editar <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="permisos.editar"
                  :options="lista_permisos"
                ></b-form-select>
              </div>
              <div class="form-group col-sm-6">
                <label>Eliminar <span class="text-danger">*</span></label>
                <b-form-select
                  v-model="permisos.eliminar"
                  :options="lista_permisos"
                ></b-form-select>
              </div>
              <div class="form-group col-sm-12">
                <label
                  >Seleccionar Menú <span class="text-danger">*</span></label
                >
                <select v-model="permisos.menu" class="form-control">
                  <option
                    :value="menus.id"
                    v-for="menus in list_menus"
                    v-bind:key="menus.id"
                  >
                    {{ menus.nombre }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-12">
                <label
                  >Seleccionar Rol <span class="text-danger">*</span></label
                >
                <select v-model="permisos.rol" class="form-control">
                  <option
                    :value="rols.id"
                    v-for="rols in list_roles"
                    v-bind:key="rols.id"
                  >
                    {{ rols.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-sm-12">
                <center>
                  <button
                    type="submit"
                    @click.prevent="savePermiso"
                    class="btn btn-success"
                  >
                    Guardar
                  </button>
                </center>
              </div>
            </div>
          </form>
        </b-modal>

        <div class="row">
          <div class="col-sm-5">
            <b-button
              class="btn btn-add-vg"
              v-b-modal.crearPermiso
              @click="
                resetForm();
                editedIndex = -1;
              "
              ><i class="mdi mdi-plus"></i> Crear Permiso</b-button
            ><br /><br />
          </div>
          <div class="col-sm-6">
            <b-form-input
              class="col-sm-12"
              v-model="filter"
              type="search"
              placeholder="Busqueda"
            ></b-form-input>
          </div>
          <div class="col-sm-1">
            <b-button
              class="btn btn-info"
              style="float: right"
              @click="getPermisos"
              ><i class="mdi mdi-replay"></i
            ></b-button>
          </div>
        </div>

        <div class="text-center" v-if="loading">
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <b-table
          class="table responsive table-bordered"
          stacked="md"
          show-empty
          small
          :per-page="perPage"
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-modal
              :id="'permiso' + row.item.idpriv"
              size="lg"
              :title="title"
              hide-footer
            >
              <form>
                <div class="row">
                  <div class="form-group col-sm-6">
                    <label>Crear <span class="text-danger">*</span></label>
                    <b-form-select
                      v-model="permisos.crear"
                      :options="lista_permisos"
                      :disabled="disabled"
                    ></b-form-select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Leer <span class="text-danger">*</span></label>
                    <b-form-select
                      v-model="permisos.leer"
                      :options="lista_permisos"
                      :disabled="disabled"
                    ></b-form-select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Editar <span class="text-danger">*</span></label>
                    <b-form-select
                      v-model="permisos.editar"
                      :options="lista_permisos"
                      :disabled="disabled"
                    ></b-form-select>
                  </div>
                  <div class="form-group col-sm-6">
                    <label>Eliminar <span class="text-danger">*</span></label>
                    <b-form-select
                      v-model="permisos.eliminar"
                      :options="lista_permisos"
                      :disabled="disabled"
                    ></b-form-select>
                  </div>
                  <div class="form-group col-sm-12">
                    <label
                      >Seleccionar Menú
                      <span class="text-danger">*</span></label
                    >
                    <select
                      v-model="permisos.menu"
                      value="15"
                      class="form-control"
                      :disabled="disabled"
                    >
                      <option
                        :value="menus.id"
                        v-for="menus in list_menus"
                        v-bind:key="menus.id"
                      >
                        {{ menus.nombre }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-12">
                    <label
                      >Seleccionar Rol <span class="text-danger">*</span></label
                    >
                    <select
                      v-model="permisos.rol"
                      class="form-control"
                      :disabled="disabled"
                    >
                      <option
                        :value="rols.id"
                        v-for="rols in list_roles"
                        v-bind:key="rols.id"
                      >
                        {{ rols.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-sm-12">
                    <center>
                      <button
                        type="submit"
                        @click.prevent="savePermiso"
                        class="btn btn-success"
                        :disabled="disabled"
                      >
                        Guardar
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </b-modal>

            <b-button
              class="btn btn-info"
              @click="
                $bvModal.show('permiso' + row.item.idpriv);
                showModal(row.item, true, 'Ver Permiso');
              "
              v-b-tooltip.hover
              title="Ver Permiso"
              ><i class="mdi mdi-eye"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-warning"
              @click="
                $bvModal.show('permiso' + row.item.idpriv);
                showModal(row.item, false, 'Editar Permiso');
                editedIndex = 1;
              "
              v-b-tooltip.hover
              title="Editar Permiso"
              ><i class="mdi mdi-pencil text-white"></i></b-button
            >&nbsp;
            <b-button
              class="btn btn-danger"
              @click="deletePermiso(row.item)"
              v-b-tooltip.hover
              title="Eliminar Permiso"
              ><i class="mdi mdi-delete"></i
            ></b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
        ></b-pagination>

        <!-- Record data -->
        <!-- <table class="table responsive table-bordered">
                    <thead>
                        <tr align="center">
                            <th>#</th>
                            <th>Menú</th>
                            <th>Rol</th>
                            <th>Acciones</th> 
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in items" v-bind:key="item.id">
                            <td align="center"><b>{{item.id}}</b></td>
                            <td>{{item.menu}}</td>
                            <td>{{item.rol}}</td>
                            <td align="center">
                                <b-button class="btn btn-info" @click="$bvModal.show('permiso'+item.id); showModal(item, true, 'Ver Menú')"><i class="mdi mdi-eye"></i></b-button>&nbsp;
                                <b-button class="btn btn-warning" @click="$bvModal.show('permiso'+item.id); showModal(item, false, 'Editar Menú'); editedIndex= 1;"><i class="mdi mdi-pencil text-white"></i></b-button>&nbsp;
                                <b-button class="btn btn-danger" @click="deletePermiso(item)"><i class="mdi mdi-delete"></i></b-button> 
                            </td> -->
        <!-- Modal Actions -->
        <!-- <b-modal :id="'permiso'+item.id" size="lg" :title="title" hide-footer>
                                
                                <form>
                                    <div class="row">
                                        <div class="form-group col-sm-6">
                                            <label>Crear</label>                     
                                            <b-form-select v-model="permisos.crear" :options="lista_permisos"></b-form-select>
                                        </div>
                                        <div class="form-group col-sm-6">
                                            <label>Leer</label>                     
                                            <b-form-select v-model="permisos.leer" :options="lista_permisos"></b-form-select>
                                        </div>
                                        <div class="form-group col-sm-6">
                                            <label>Editar</label>                     
                                            <b-form-select v-model="permisos.editar" :options="lista_permisos"></b-form-select>
                                        </div>
                                        <div class="form-group col-sm-6">
                                            <label>Eliminar</label>                     
                                            <b-form-select v-model="permisos.eliminar" :options="lista_permisos"></b-form-select>
                                        </div>    
                                        <div class="form-group col-sm-12">
                                            <label>Seleccionar Menú</label>                     
                                            <select v-model="permisos.id_menu" class="form-control">
                                                <option :value="menus.id" v-for="menus in list_menus" v-bind:key="menus.id">{{ menus.nombre }}</option>
                                            </select>
                                        </div>  
                                        <div class="form-group col-sm-12">
                                            <label>Seleccionar Rol</label>                     
                                            <select v-model="permisos.id_rol" class="form-control">
                                                <option :value="rols.id" v-for="rols in list_roles" v-bind:key="rols.id">{{ rols.nombre }}</option>
                                            </select>
                                        </div> 
                                        <div class="form-group col-sm-12">
                                            <center>                                        
                                                <button type="submit" @click.prevent="savePermiso" class="btn btn-success">Guardar</button>                                    
                                            </center>
                                        </div>                                         
                                    </div>
                                </form>

                            </b-modal>  
                        </tr>                        
                    </tbody>
                </table>                             -->
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "./../../../api/headers";
import { configApi } from "./../../../api/configApi";
export default {
  name: "listado_menus",
  data: () => ({
    items: [],
    perPage: 5,
    filter: null,
    currentPage: 1,
    loading: true,
    title: "",
    list_roles: [],
    list_menus: [],
    editedIndex: -1,
    disabled: false,
    columns: [
      {
        name: "idpriv",
        th: "ID",
      },
      {
        name: "menu",
        th: "Menú",
      },
      {
        name: "rol",
        th: "Rol",
      },
    ],
    fields: [
      { key: "idpriv", label: "ID", sortable: true },
      { key: "menu", label: "Menú", sortable: true, class: "text-center" },
      { key: "rol", label: "Rol", sortable: true, class: "text-center" },
      { key: "actions", label: "Acciones", class: "text-center" },
    ],
    permisos: {
      id: "",
      crear: "",
      leer: "",
      editar: "",
      eliminar: "",
      menu: "",
      rol: "",
    },
    lista_permisos: [
      { value: 0, text: "No" },
      { value: 1, text: "Sí" },
    ],
    rules: {
      required: (value) => !!value || "Campo Requerido.",
    },
  }),
  computed: {
    rows() {
      return this.items.length;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getPermisos();
  },

  mounted() {
    this.getRoles(), this.getMenus();
  },
  methods: {
    showModal(datos, disabled, title) {
      console.log(datos);
      this.disabled = disabled;
      this.title = title;
      this.permisos.id = datos.idpriv;
      this.permisos.crear = datos.crear;
      this.permisos.leer = datos.leer;
      this.permisos.editar = datos.editar;
      this.permisos.eliminar = datos.eliminar;
      this.permisos.menu = datos.id_menu;
      this.permisos.rol = datos.id_rol;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async getPermisos() {
      return await this.axios
        .get(configApi + "usuarios/permisos", { headers })
        .then((response) => {
          this.items = response.data.data;
          this.loading = false;
        });
    },
    async getRoles() {
      return await this.axios
        .get(configApi + "usuarios/roles", { headers })
        .then((response) => {
          this.list_roles = response.data.data;
        });
    },
    async getMenus() {
      return await this.axios
        .get(configApi + "usuarios/menus", { headers })
        .then((response) => {
          this.list_menus = response.data.data;
        });
    },
    async savePermiso() {
      if (this.editedIndex == 1) {
        return await this.axios
          .put(
            configApi + "usuarios/permisos/" + this.permisos.id,
            this.permisos,
            { headers }
          )
          .then((response) => {
            this.$bvModal.hide("permiso" + this.permisos.id);
            this.resetForm();
            this.getPermisos();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "usuarios/permisos", this.permisos, { headers })
          .then((response) => {
            this.$bvModal.hide("crearPermiso");
            this.resetForm();
            this.getPermisos();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    async deletePermiso(data) {
      let objAlert = {
        titleAlert: "¿Desea eliminar el Permiso?",
        url: configApi + "usuarios/permisos/" + data.idpriv, // url delete
        headers: { headers },
        messageError: "El Permiso no fue Eliminado",
      };

      this.$Fun.alertaConfirmarEliminarAccion(objAlert, (e) => {
        if (e) {
          this.getPermisos();
        }
      });
    },
    resetForm() {
      this.permisos.id = "";
      this.permisos.crear = "";
      this.permisos.leer = "";
      this.permisos.editar = "";
      this.permisos.eliminar = "";
      this.permisos.menu = "";
      this.permisos.rol = "";
    },
  },
};
</script>
