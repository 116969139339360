<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header"><b>Vista Previa</b></div>
      <div class="card-body text-dark">
        <div class="row">
          <div class="col-md-8">
            <router-link
              :to="'/encuestas/preguntas/' + $route.params.id"
              class="btn btn-info"
              ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
            ><br /><br />
          </div>
          <div class="col-md-4">
            <button class="btn btn-info" @click="resetEncuesta">
              <i class="mdi mdi-replay"></i>
            </button>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-3"></div>
            <div
              id="listPreguntas"
              class="col-md-5"
              style="overflow-y: scroll; height: 700px"
            >
              <img
                src="../../assets/vista_mobile.jpeg"
                style="width: 100%; position: sticky; top: 0; z-index: 1"
              />

              <div
                v-for="(preguntas, index) in preguntas_encuesta"
                v-bind:key="index"
              >
                <div
                  class="card"
                  style="margin-top: 30px"
                  v-show="preguntas.visible"
                >
                  <div
                    class="card-header text-white"
                    style="background-color: #43a047"
                  >
                    {{ preguntas.orden }} - {{ preguntas.pregunta }}
                  </div>
                  <div class="card-body">
                    <!-- Tipo Texto -->
                    <input
                      type="text"
                      :v-model="form.text[preguntas.idpregunta]"
                      v-if="preguntas.tipodato == 0"
                      class="form-control"
                      disabled
                    />
                    <!-- Tipo Combo -->
                    <b-form-select
                      v-model="form.rtaopt[preguntas.idpregunta]"
                      :options="preguntas.respuestas"
                      value-field="idrtaopt"
                      text-field="opttexto"
                      v-if="preguntas.tipodato == 1"
                      @change="mostrarPreguntas(preguntas.idpregunta)"
                    ></b-form-select>
                    <!-- Tipo Radio -->
                    <b-form-radio-group
                      v-model="form.rtaopt[preguntas.idpregunta]"
                      :options="preguntas.respuestas"
                      class="mb-3"
                      value-field="idrtaopt"
                      text-field="opttexto"
                      v-if="preguntas.tipodato == 2"
                      @change="mostrarPreguntas(preguntas.idpregunta)"
                    ></b-form-radio-group>
                    <!-- Tipo Checkbox -->
                    <b-form-checkbox-group
                      v-model="form.rtaopt[preguntas.idpregunta]"
                      :options="preguntas.respuestas"
                      class="mb-3"
                      value-field="idrtaopt"
                      text-field="opttexto"
                      v-if="preguntas.tipodato == 3"
                      @change="mostrarPreguntas(preguntas.idpregunta)"
                    ></b-form-checkbox-group>
                    <!-- Tipo Foto -->
                    <b-form-group>
                      <b-form-file
                        v-if="preguntas.tipodato == 5"
                        placeholder="Seleccione una foto"
                        browse-text="Buscar"
                        :v-model="form.text[preguntas.idpregunta]"
                      ></b-form-file>
                    </b-form-group>
                    <!-- Tipo Fecha -->
                    <b-form-datepicker
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      locale="es"
                      placeholder="Seleccione una Fecha"
                      :v-model="form.text[preguntas.idpregunta]"
                      v-if="preguntas.tipodato == 6"
                    ></b-form-datepicker>
                    <!-- Tipo Númerico -->
                    <input
                      type="number"
                      disabled
                      :v-model="form.text[preguntas.idpregunta]"
                      v-if="preguntas.tipodato == 7"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";

export default {
  name: "vista_previa",
  data: () => ({
    preguntas_encuesta: [],
    condiciones: [],
    form: {
      rtaopt: [],
      text: [],
    },
    result: "",
  }),
  mounted() {
    this.getPreguntasEncuesta();
  },
  computed: {},
  methods: {
    async getPreguntasEncuesta() {
      return await this.axios
        .get(
          configApi + "encuestas/preguntas/encuesta/" + this.$route.params.id,
          { headers }
        )
        .then((response) => {
          let data = response.data.data;

          for (let i = 0; i < data.length; i++) {

            this.preguntas_encuesta.push({
              idpregunta: data[i].idpregunta,
              idproyecto: data[i].idproyecto,
              pregunta: data[i].pregunta,
              tiporta: data[i].tiporta,
              requerida: data[i].requerida,
              tipodato: data[i].tipodato,
              salto_where: data[i].salto_where,
              salto_rta: data[i].salto_rta,
              orden: data[i].orden,
              asociada: data[i].asociada,
              idrtawhere: data[i].idrtawhere,
              defval: data[i].defval,
              inactivo: data[i].inactivo,
              idriasevento: data[i].idriasevento,
              visible: true,
              respuestas: data[i].respuestas,
            });
          }
        });
    },
    async mostrarPreguntas(pregunta) {
      this.result = this.form.rtaopt[pregunta];
      let array_principal = this.preguntas_encuesta;
      let condiciones = this.condiciones;

      //Ocultar las preguntas de las diferentes respuestas
      let buscar_anidadas = array_principal.filter(
        (element) => element.salto_rta === pregunta
      );

      let respuesta_selected = this.form.rtaopt[pregunta];

      let nivel = 1;

      for (let i = 0; i < buscar_anidadas.length; i++) {
        if (
          buscar_anidadas[i].salto_rta === pregunta &&
          buscar_anidadas[i].idrtawhere !== respuesta_selected
        ) {
          array_principal.forEach(function (item) {
            //
            if (
              item.idpregunta === buscar_anidadas[i].idpregunta &&
              item.idrtawhere !== respuesta_selected
            ) {
              item.visible = false;

              condiciones.push({
                nivel: nivel,
                pregunta: buscar_anidadas[i].idpregunta,
              });
            }
          });
        }

        if (
          buscar_anidadas[i].salto_rta === pregunta &&
          buscar_anidadas[i].idrtawhere === respuesta_selected
        ) {
          this.preguntas_encuesta.forEach(function (item) {
            if (
              item.idpregunta === buscar_anidadas[i].idpregunta &&
              item.idrtawhere === respuesta_selected
            ) {
              item.visible = true;
            }
          });
        }
      }

      let bucle = true;

      while (bucle) {
        bucle = false;

        condiciones.forEach(function (item) {
          if (item.nivel == nivel) {
            let preguntas = array_principal.filter(
              (element) => element.salto_rta === item.pregunta
            );

            for (let p = 0; p < preguntas.length; p++) {
              condiciones.push({
                nivel: nivel + 1,
                pregunta: preguntas[p].idpregunta,
              });

              bucle = true;
            }
          }
        });

        nivel++;
      }

      buscar_anidadas.forEach(function (item) {
        if (item.salto_rta === pregunta) {
          if (item.idrtawhere === respuesta_selected) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        }
      });
    },
    resetEncuesta() {
      this.preguntas_encuesta = [];
      this.condiciones = [];
      this.form.rtaopt = [];
      this.form.text = [];
      this.result = "";

      this.getPreguntasEncuesta();
    },
  },
};
</script>
