<template>
    <div>
        <b-card no-body>
            <b-tabs pills card>
                <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                    <component :is="item.component" :ref="item.component" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>

import info_evento_dengue from './info_evento_dengue.vue'
import info_evento_aprt from "./info_evento_aprt.vue";
import info_evento_desnutricion from "./info_evento_desnutricion.vue";
import info_evento_irac from "./info_evento_irac.vue";
import info_evento_tuberculosis from "./info_evento_tuberculosis.vue";
import info_evento_violencia_genero from "./info_evento_violencia_genero.vue";
import info_evento_cancer from "./info_evento_cancer.vue";
import info_evento_intento_suicidio from "./info_evento_intento_suicidio.vue";
import info_evento_mme from "./info_evento_mme.vue";
import info_evento_varicela from "./info_evento_varicela.vue";
import info_evento_vih from "./info_evento_vih.vue";

export default {
    name: 'listado_usuarios',
    components: {
      info_evento_cancer,
      info_evento_dengue,
      info_evento_aprt,
      info_evento_desnutricion,
      info_evento_irac,
      info_evento_intento_suicidio,
      info_evento_tuberculosis,
      info_evento_mme,
      info_evento_varicela,
      info_evento_vih,
      info_evento_violencia_genero
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'APTR', component: 'info_evento_aprt' },
          { tab: 'Cancer', component: 'info_evento_cancer' },
          { tab: 'Dengue', component: 'info_evento_dengue' },
          { tab: 'Desnutrición', component: 'info_evento_desnutricion' },
          { tab: 'IRAG', component: 'info_evento_irac' },
          { tab: 'Intento de Suicidio', component: 'info_evento_intento_suicidio' },
          { tab: 'Tuberculosis', component: 'info_evento_tuberculosis' },
          { tab: 'Morbilidad Materna Extrema', component: 'info_evento_mme' },
          { tab: 'Varicela', component: 'info_evento_varicela' },
          { tab: 'VIH', component: 'info_evento_vih' },
          { tab: 'Violencia de Género', component: 'info_evento_violencia_genero' },
        ],
    })
}
</script>
