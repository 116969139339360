<template>
    <div>

        <div class="row">
            <div class="card col-md-12">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="card-body">
                            <esta_segui_covid />
                        </div>
                    </div>
                </div>
            </div>                                         
        </div>   

    </div>
</template>
<script>

import esta_segui_covid from './esta_segui_covid.vue'

export default {
    components: {   
       esta_segui_covid
    }
}
</script>