<template>
    <div>
        <b-card no-body>
            <b-tabs pills card>
                <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                    <component :is="item.component" :ref="item.component" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>

import info_generalencuesta from './info_generalencuesta.vue'
import info_enfermedades from './info_enfermedades.vue'
import info_segepidegeneral from './info_segepidegeneral.vue'
import info_segepidexprofe from './info_segepidexprofe.vue'
import info_personasencu from './info_personasencu.vue'
import info_bdpersonas from './info_bdpersonas.vue'

export default {
    name: 'listado_usuarios',
    components: {
      info_generalencuesta,
      info_enfermedades,
      info_segepidegeneral,
      info_segepidexprofe,
      info_personasencu,
      info_bdpersonas
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Informe / General Encuesta', component: 'info_generalencuesta' },
          { tab: 'Informe / Enfermedades Encontradas', component: 'info_enfermedades' },
          { tab: 'Informe / Seguimiento  Epidemiológico', component: 'info_segepidegeneral' },
          { tab: 'Informe / Seguimiento  Epidemiológico Por Profesional', component: 'info_segepidexprofe' },
          { tab: 'Informe / Personas Encuestadas', component: 'info_personasencu' },
          { tab: 'Informe / Base de Datos Personas', component: 'info_bdpersonas' },
        ],
    })
}
</script>