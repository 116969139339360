<template>
    <div>
        <b-tabs content-class="mt-3" align="center">
            <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                <component :is="item.component" :ref="item.component" />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import grupoEventos from './grupos_eventos/listado.vue'
import enfermedades from './enfermedades/listado.vue'
import rutasAtencion from './rutas_atencion/listado.vue'
import eventosRutas from './eventos_rutas/listado.vue'

export default {
    name: 'listado_usuarios',
    components: {
        grupoEventos,
        enfermedades,
        rutasAtencion,
        eventosRutas
    },
    data: () => ({
        tab: null,
        items: [
          { tab: '1. Programa', component: 'grupoEventos' },
          { tab: '2. Eventos', component: 'enfermedades' },
          { tab: '3. Flujograma de Atención', component: 'rutasAtencion' },
          { tab: '4. Intervenciones', component: 'eventosRutas' },
        ],
    })
}
</script>