<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b>{{ title }} Comuna</b>
      </div>
      <div class="card-body text-dark">
        <router-link to="/configuracion" class="btn btn-info"
          ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
        ><br /><br />

        <form @submit.prevent="saveComuna">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-sm-12">
                  <label>Nombre Comuna</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="comuna.comuna"
                    :disabled="disabled"
                    required
                  />
                </div>
                <div class="col-sm-12">
                  <label>Nombre Municipio</label>
                  <Select2
                    v-model="comuna.codmun"
                    :options="options_municipios"
                  />
                </div>
                <br /><br /><br /><br />
                <div class="form-group col-sm-12">
                  <center>
                    <button
                      type="submit"
                      class="btn btn-success"
                      :disabled="disabled"
                    >
                      Guardar
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../../api/headers";
import { configApi } from "../../../api/configApi";
export default {
  name: "municipios_crear",
  data: () => ({
    disabled: false,
    id_menu: 2,
    title: "Crear",
    municipios: [],
    options_municipios: [],
    comuna: {
      codmun: "",
      comuna: "",
    },
  }),
  mounted() {
    this.getDatosComuna();
    this.getMunicipios();
  },
  computed: {},
  methods: {
    async getMunicipios() {
      return await this.axios
        .get(configApi + "configuracion/municipios", { headers })
        .then((response) => {
          this.municipios = response.data.data;

          for (var i = 0, max = this.municipios.length; i < max; i += 1) {
            this.options_municipios.push({
              id: parseInt(this.municipios[i].codmun),
              text: this.municipios[i].municipio,
            });
          }
        });
    },
    async getDatosComuna() {
      if (this.$route.params.id > 0) {
        return await this.axios
          .get(configApi + "configuracion/comunas/" + this.$route.params.id, {
            headers,
          })
          .then((response) => {
            this.comuna.codmun = parseInt(response.data.data.codmun);
            this.comuna.comuna = response.data.data.comuna;

            if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
              this.disabled = false;
              this.title = "Editar";
            }
          });
      }
    },
    async saveComuna() {
      if (this.$route.params.id > 0 && this.$route.params.accion == 0) {
        return await this.axios
          .put(
            configApi + "configuracion/comunas/" + this.$route.params.id,
            this.comuna,
            { headers }
          )
          .then((response) => {
            this.getDatosComuna();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      } else {
        return await this.axios
          .post(configApi + "configuracion/comunas", this.comuna, { headers })
          .then((response) => {
            this.resetForm();
            this.$Fun.alertaAccion("success", response.data.message, false);
          });
      }
    },
    resetForm() {
      this.comuna.codmun = "";
      this.comuna.comuna = "";
    },
  },
};
</script>
