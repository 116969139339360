<template>
  <div>
    <div class="card" style="max-width: 100%">
      <div class="card-header">
        <b><i class="mdi mdi-clipboard-text"></i> {{ nombre_encuesta }}</b>
      </div>
      <div class="card-body text-dark">
        <b-modal
          id="clonarPreguntas"
          size="lg"
          title="Clonar Preguntas"
          hide-footer
          :no-close-on-backdrop="true"
        >
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <div class="row">
                <div class="form-group col-sm-12"></div>
                <div class="form-group col-sm-6">
                  <label>Seleccione Encuesta a Clonar</label>
                  <select
                    v-model="encuesta"
                    class="form-control"
                    required
                    @change="cambioEncuesta"
                  >
                    <option
                      :value="enc.id"
                      v-for="enc in options_encuestas"
                      v-bind:key="enc.id"
                    >
                      {{ enc.id + "-" + enc.text }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-sm-6">
                  <label>Seleccione Opción de Clonado</label>
                  <b-form-select
                    v-model="opciones_clonar"
                    :options="options_clonado"
                    value-field="id"
                    text-field="text"
                    @change="getPreguntasClonar"
                  ></b-form-select>
                </div>
                <div class="form-group col-sm-12"></div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
              <label v-if="vClonar"
                >Seleccione las preguntas que desea clonar</label
              >
              <b-table
                v-if="vClonar"
                class="table responsive table-bordered"
                stacked="md"
                show-empty
                small
                :items="items_preguntas_clonar"
                :fields="fields"
              >
                <template #cell(actions)="row">
                  <input
                    type="checkbox"
                    style="margin-left: -5px"
                    class="form-check-input"
                    @click="agregarPreguntaClonar(row.item, $event, row.index)"
                  />
                </template>
              </b-table>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="form-group col-sm-12" style="margin-top: 10px">
            <center>
              <button @click="clonarPreguntasEncuesta" class="btn btn-success">
                Clonar Preguntas
              </button>
            </center>
          </div>
        </b-modal>

        <div class="row">
          <div class="col-md-9">
            <router-link to="/encuestas" class="btn btn-info"
              ><i class="mdi mdi-undo-variant"></i> Regresar</router-link
            ><br /><br />
          </div>
          <div class="col-md-2">
            <div
              class="btn-group float-right">
              <button
                class="btn btn-outline-warning"
                v-b-tooltip.hover
                title="Clonar Preguntas"
                @click="
                  $bvModal.show('clonarPreguntas');
                  showModal();
                "
              >
                <i class="mdi mdi-content-copy"></i>
              </button>
              <router-link
                :to="'/encuestas/preguntas/vista-previa/' + $route.params.id"
                class="btn btn-outline-info"
                v-b-tooltip.hover
                title="Vista Previa"
                ><i class="mdi mdi-eye"></i
              ></router-link>
              <download-excel
                class="btn btn-outline-success"
                v-b-tooltip.hover
                title="Descargar Preguntas"
                :data="preguntas"
                :fields="json_fields"
                :name= "'listado_preguntas_proyecto_' + nombre_encuesta + '.xls'"
              >
                <i class="mdi mdi-file-excel"></i>
              </download-excel>
            </div>
          </div>
          <br /><br />
          <div class="col-md-2">
            <div class="card-header border-secondary">TIPO DE CAMPO</div>
            <div style="overflow-y: scroll; height: 650px">
              <ul class="list-group list-group-flush">
                <li
                  class="list-group-item"
                  v-for="t_campo in tipos_campos"
                  v-bind:key="t_campo.id"
                >
                  <div
                    class="card shadow"
                    style="cursor: pointer"
                    @click="agregarPregunta(t_campo.id, t_campo.tiporta)"
                  >
                    <b
                      ><p style="font-size: 16px; padding: 10px 0px 0px 20px">
                        <i :class="t_campo.icon"></i> {{ t_campo.text }}
                      </p></b
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-5">
            <div class="card-header border-secondary">
              <div class="d-flex justify-content-between">
                LISTADO DE PREGUNTAS

                <div>
                  <button
                    v-if="vOrdenar"
                    class="btn btn-outline-info btn-sm"
                    v-b-tooltip.hover
                    title="Restablecer Orden"
                    @click="restablecerOrden"
                  >
                    <i class="mdi mdi-replay"></i></button
                  >&nbsp;

                  <button
                    v-if="vOrdenar"
                    class="btn btn-outline-success btn-sm"
                    v-b-tooltip.hover
                    title="Guardar Orden"
                    @click="guardarOrden"
                  >
                    <i class="mdi mdi-swap-vertical"></i>
                  </button>
                </div>
              </div>
            </div>
            <div id="listPreguntas" style="overflow-y: scroll; height: 650px">
              <div class="alert alert-warning" role="alert" v-if="vAlerta">
                No hay preguntas registradas...
              </div>
              <draggable
                v-model="preguntas"
                ghost-class="ghost"
                class="list-group"
                @end="onEnd"
                :scroll-sensitivity="200"
              >
                <transition-group type="transition" name="flip-list">
                  <div
                    class="list-group-item"
                    style="border-width: 0 0 1px; cursor: move"
                    :id="pregunta.idpregunta"
                    v-for="(pregunta, index) in preguntas"
                    :key="pregunta.idpregunta"
                  >
                    <div
                      class="card shadow p-3"
                      style="cursor: pointer"
                      @click="preguntaSeleccionada(pregunta)"
                      :id="
                        seleccionada == pregunta.idpregunta
                          ? 'border-pregunta'
                          : ''
                      "
                    >
                      <div class="input-group mb-2 mr-sm-2">
                        <div class="input-group-prepend">
                          <span
                            class="btn btn-primary"
                            v-b-tooltip.hover
                            title="Orden"
                            >{{ index + 1 }}</span
                          >
                          <div class="input-group-text">
                            <i
                              class="mdi mdi-format-size"
                              v-b-tooltip.hover
                              title="Texto"
                              v-if="pregunta.tipodato == 0"
                            ></i>
                            <i
                              class="mdi mdi-menu-down"
                              v-b-tooltip.hover
                              title="Combo"
                              v-if="pregunta.tipodato == 1"
                            ></i>
                            <i
                              class="mdi mdi-checkbox-marked-circle-outline"
                              v-b-tooltip.hover
                              title="Radio"
                              v-if="pregunta.tipodato == 2"
                            ></i>
                            <i
                              class="mdi mdi-checkbox-marked-outline"
                              v-b-tooltip.hover
                              title="Checkbox"
                              v-if="pregunta.tipodato == 3"
                            ></i>
                            <i
                              class="mdi mdi-map-marker-radius"
                              v-b-tooltip.hover
                              title="Ubicación"
                              v-if="pregunta.tipodato == 4"
                            ></i>
                            <i
                              class="mdi mdi-camera"
                              v-b-tooltip.hover
                              title="Foto"
                              v-if="pregunta.tipodato == 5"
                            ></i>
                            <i
                              class="mdi mdi-calendar"
                              v-b-tooltip.hover
                              title="Fecha"
                              v-if="pregunta.tipodato == 6"
                            ></i>
                            <i
                              class="mdi mdi-numeric"
                              v-b-tooltip.hover
                              title="Númerico"
                              v-if="pregunta.tipodato == 7"
                            ></i>
                          </div>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          v-model="pregunta.pregunta"
                          disabled="disabled"
                          placeholder="Nombre de la pregunta"
                        />&nbsp;
                        <i
                          @click="_removerPosiblePregunta(pregunta.idpregunta)"
                          class="btn btn-outline-danger mdi mdi-trash-can cr-pointer f-20"
                          v-b-tooltip.hover
                          title="Eliminar"
                        ></i
                        >&nbsp;
                        <i
                          @click="clonarPregunta(pregunta.idpregunta)"
                          class="btn btn-outline-warning mdi mdi-content-copy cr-pointer f-20"
                          v-b-tooltip.hover
                          title="Clonar Pregunta"
                        ></i>
                      </div>
                    </div>
                  </div>
                </transition-group>
              </draggable>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card-header border-secondary">PROPIEDADES</div>
            <div style="overflow-y: scroll; height: 650px">
              <b-tabs>
                <b-tab active>
                  <template #title>
                    <i class="mdi mdi-message-draw"></i
                    ><strong> Opciones</strong>
                  </template>
                  <br />
                  <div>
                    <div v-if="form_pregunta.idpregunta > 0">
                      <b-card
                        :header="
                          'Parámetros Pregunta: ' + form_pregunta.pregunta
                        "
                        header-tag="header"
                      >
                        <div class="form-group">
                          <label>Nombre de la pregunta</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="form_pregunta.pregunta"
                          />
                        </div>
                        <div class="form-group form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            v-model="form_pregunta.requerida"
                          />
                          <label class="form-check-label" for="exampleCheck1"
                            >La pregunta es requerida</label
                          >
                        </div>
                        <hr />
                      </b-card>
                    </div>
                    <div
                      v-if="form_pregunta.tiporta == 0"
                      v-show="form_pregunta.idpregunta > 0"
                      style="margin-top: 25px"
                    >
                      <b-card
                        header="Posibles Respuestas"
                        header-tag="header"
                        style="overflow-y: scroll; height: 450px"
                      >
                        <form>
                          <div class="row">
                            <div class="col-md-12 input-nex">
                              <span>Respuesta</span>
                              <input
                                type="text"
                                class="form-control"
                                v-model="posible_respuesta"
                              />
                            </div>
                            <div class="col-md-12">
                              &nbsp;
                              <input
                                type="checkbox"
                                v-model="default_respuesta"
                                value="1"
                              />
                              <label>&nbsp;¿Marcar por Defecto?</label>
                            </div>
                            <div class="col-md-12 text-center">
                              <button
                                @click="addRespuesta"
                                type="button"
                                class="btn btn-sm btn-primary w-20"
                              >
                                Añadir Respuesta
                              </button>
                            </div>
                          </div>
                          <div class="col-md-12 scrollX mt-4">
                            <h6>Listado Respuestas</h6>
                            <table class="table responsive">
                              <tbody>
                                <tr v-if="!form_pregunta.respuestas.length">
                                  <td colspan="2">
                                    No hay respuestas añadidas
                                  </td>
                                </tr>
                                <tr
                                  v-for="(
                                    respuesta_cerrada, key
                                  ) in form_pregunta.respuestas"
                                  v-bind:key="key"
                                >
                                  <div class="row">
                                    <div class="form-group col-sm-10">
                                      <input
                                        type="text"
                                        class="form-control"
                                        v-model="
                                          form_pregunta.respuestas[key].opttexto
                                        "
                                      />
                                    </div>
                                    <div class="form-group col-sm-2">
                                      <i
                                        @click="
                                          _removerPosibleRespuesta(key, 0);
                                          eliminarRespuesta(
                                            respuesta_cerrada.idrtaopt
                                          );
                                        "
                                        class="btn btn-danger mdi mdi-trash-can text-white cr-pointer f-20"
                                      ></i>
                                    </div>
                                  </div>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="form_pregunta.idpregunta > 0" class="form-group">
                      <center>
                        <button
                          class="btn btn-success"
                          @click="actualizarEncuesta"
                        >
                          Actualizar Pregunta
                        </button>
                      </center>
                    </div>
                  </div>
                </b-tab>

                <b-tab>
                  <template #title>
                    <i class="mdi mdi-link-variant"></i
                    ><strong @click="preguntaSeleccionada(form_pregunta)">
                      Saltos</strong
                    >
                  </template>
                  <br />
                  <b-card
                    :header="
                      'Crear Salto de Pregunta: ' + form_pregunta.pregunta
                    "
                    header-tag="header"
                    v-if="
                      form_pregunta.tipodato == 1 ||
                      form_pregunta.tipodato == 2 ||
                      form_pregunta.tipodato == 3
                    "
                  >
                    <div class="col-sm-12 form-group">
                      <label>Cuando: </label>
                      <b-form-select
                        v-model="salto_pregunta.salto_where"
                        :options="options_tipo_dato"
                        value-field="id"
                        text-field="text"
                      ></b-form-select>
                    </div>
                    <div class="col-sm-12 form-group">
                      <label>Respuesta: </label>
                      <b-form-select
                        v-model="salto_pregunta.idrtawhere"
                        :options="options_respuestas"
                        value-field="id"
                        text-field="text"
                      ></b-form-select>
                    </div>
                    <div class="col-sm-12 form-group">
                      <label>Ir a: </label>
                      <b-form-select
                        v-model="salto_pregunta.asociada"
                        :options="options_preguntas"
                        value-field="id"
                        text-field="text"
                      ></b-form-select>
                    </div>
                    <div class="col-md-12 text-center">
                      <button class="btn btn-success" @click="addSaltoPregunta">
                        Crear Salto
                      </button>
                    </div>

                    <hr />

                    <div class="col-md-12 scrollX mt-4">
                      <h6>Listado Saltos</h6>
                      <!-- {{saltos_creados}} -->
                      <table class="table responsive">
                        <tbody>
                          <tr v-if="!saltos_creados.length">
                            <td colspan="2">No hay respuestas añadidas</td>
                          </tr>
                          <tr
                            v-for="(pregunta, key) in saltos_creados"
                            v-bind:key="key"
                          >
                            <div class="row">
                              <div class="form-group col-sm-3">
                                <p v-if="pregunta.salto_where == 1">
                                  Si La Respuesta Es
                                </p>
                                <p v-if="pregunta.salto_where == 2">
                                  Si La Respuesta No Es
                                </p>
                              </div>
                              <div class="form-group col-sm-4">
                                <p>{{ pregunta.opttexto }}</p>
                              </div>
                              <div class="form-group col-sm-4">
                                <p>Ir a: {{ pregunta.pregunta }}</p>
                              </div>
                              <div class="form-group col-sm-1">
                                <i
                                  @click="
                                    eliminarSaltoPregunta(
                                      pregunta.idupdate,
                                      key
                                    )
                                  "
                                  class="btn btn-danger mdi mdi-trash-can text-white cr-pointer f-20"
                                ></i>
                              </div>
                            </div>
                            <hr />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-card>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { headers } from "../../api/headers";
import { configApi } from "../../api/configApi";
import Vue from "vue";
import Swal from "sweetalert2";
import draggable from "vuedraggable";
Vue.prototype.$swal = Swal;

export default {
  name: "preguntas",
  components: {
    draggable,
  },
  data: () => ({
    disabled: false,
    nombre_encuesta: "Encuesta",
    seleccionada: 0,
    vAlerta: false,
    vOrdenar: false,
    vClonar: false,
    preguntas: [],
    options_encuestas: [],
    items_preguntas_clonar: [],
    preguntas_para_clonar: [],
    encuesta: "",
    opciones_clonar: "",
    oldIndex: "",
    newIndex: "",
    pregunta_seleccionada: "",
    tipos_campos: [
      {
        id: 0,
        text: "Texto",
        type: "text",
        icon: "mdi mdi-format-size",
        tiporta: "1",
      },
      {
        id: 1,
        text: "Combo",
        type: "combo",
        icon: "mdi mdi-menu-down",
        tiporta: "0",
      },
      {
        id: 2,
        text: "Radio",
        type: "radio",
        icon: "mdi mdi-checkbox-marked-circle-outline",
        tiporta: "0",
      },
      {
        id: 3,
        text: "Check",
        type: "checkbox",
        icon: "mdi mdi-checkbox-marked-outline",
        tiporta: "0",
      },
      {
        id: 5,
        text: "Foto",
        type: "foto",
        icon: "mdi mdi-camera",
        tiporta: "1",
      },
      {
        id: 6,
        text: "Fecha",
        type: "date",
        icon: "mdi mdi-calendar",
        tiporta: "1",
      },
      {
        id: 7,
        text: "Númerico",
        type: "number",
        icon: "mdi mdi-numeric",
        tiporta: "1",
      },
    ],
    form_pregunta: {
      idpregunta: 0,
      idproyecto: "",
      pregunta: "",
      tiporta: "",
      requerida: "",
      tipodato: "",
      salto_where: "",
      salto_rta: "",
      asociada: "",
      idrtawhere: "",
      defval: "",
      inactivo: "",
      idriasevento: "",
      respuestas: [],
    },
    salto_pregunta: {
      salto_where: "", //TIPO SALTO
      salto_rta: "", // ID PREGUNTA CLICKED
      idrtawhere: "", // ID RTAOPT
      asociada: "", // PREGUNTA QUE SE ACTUALIZA
    },
    saltos_creados: [],
    options_respuestas: [],
    options_preguntas: [],
    grupos_eventos: [],
    posible_respuesta: "",
    default_respuesta: 0,
    idpregunta: 0,
    options_tipo_dato: [
      { id: 1, text: "La respuesta ES" },
      { id: 2, text: "La respuesta NO ES" },
    ],
    options_clonado: [
      { id: 1, text: "Clonar todas las preguntas" },
      { id: 2, text: "Clonar preguntas seleccionadas" },
    ],
    json_fields: {
      ID: "idpregunta",
      "Nombre Pregunta": "pregunta",
    },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    fields: [
      { key: "idpregunta", label: "ID" },
      { key: "pregunta", label: "Pregunta", class: "text-left" },
      { key: "actions", label: "Seleccionar", class: "text-center" },
    ],
  }),
  mounted() {
    this.getPreguntas();
    this.getEncuesta();
    this.getGruposEventos();

    window.localStorage.setItem("selected", 0);
  },
  computed: {},
  methods: {
    onEnd: function (evt) {
      this.oldIndex = evt.oldIndex;
      this.newIndex = evt.newIndex;
      this.vOrdenar = true;
    },
    async getEncuesta() {
      return await this.axios
        .get(configApi + "encuestas/encuestas/" + this.$route.params.id, {
          headers,
        })
        .then((response) => {
          this.nombre_encuesta = response.data.data.nombreproyecto;
        });
    },
    async getPreguntas() {
      this.options_preguntas = [];

      return await this.axios
        .get(
          configApi + "encuestas/preguntas/encuesta/" + this.$route.params.id,
          { headers }
        )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.preguntas = response.data.data;

            let data = this.preguntas;

            for (var i = 0, max = data.length; i < max; i += 1) {
              this.options_preguntas.push({
                id: data[i].idpregunta,
                text: data[i].orden + "-" + data[i].pregunta,
              });

              /*this.json_data.push({
                pregunta: data[i].pregunta,
                id: data[i].idpregunta,
              });*/
            }
          } else {
            this.vAlerta = true;
          }

          // this.options_preguntas.push({
          //     id: 0,
          //     text: 'Terminar Formulario',
          // })
        });
    },
    async getPreguntasClonar() {
      this.preguntas_para_clonar = [];

      if (this.opciones_clonar == 2) {
        this.vClonar = true;

        this.items_preguntas_clonar = [];

        return await this.axios
          .get(configApi + "encuestas/preguntas/encuesta/" + this.encuesta, {
            headers,
          })
          .then((response) => {
            if (response.data.data.length > 0) {
              this.items_preguntas_clonar = response.data.data;
              //this.loading = false;
            }
          });
      } else {
        this.vClonar = false;
      }
    },
    cambioEncuesta() {
      this.items_preguntas_clonar = [];
      this.opciones_clonar = 1;
      this.vClonar = false;

      this.preguntas_para_clonar = [];
    },
    async getUltimaPregunta() {
      return await this.axios
        .get(
          configApi + "encuestas/preguntas/encuesta/" + this.$route.params.id,
          { headers }
        )
        .then((response) => {
          this.preguntas = response.data.data;

          let data = this.preguntas;
          var posi = 0;
          for (var i = 0, max = data.length; i < max; i += 1) {
            posi = i;
          }

          this.preguntaSeleccionada(data[posi]);
          var myDiv = document.getElementById("listPreguntas");
          myDiv.scrollTop = myDiv.scrollHeight;
          myDiv.animate({ scrollTop: myDiv.scrollHeight });
        });
    },
    _removerPosiblePregunta(idpregunta) {
      Swal.fire({
        title: "¿Está seguro de eliminar esta pregunta?",
        showDenyButton: true,
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#4caf50",
        denyButtonText: `No Eliminar`,
        denyButtonColor: "#f44336",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(configApi + "encuestas/preguntas/" + idpregunta, {
              headers,
            })
            .then((response) => {
              this.preguntas.splice(idpregunta, 1);
              setTimeout(() => {
                if (!this.preguntas.length) {
                  this.vAlerta = true;
                }
              }, 500);
              this.getPreguntas();
              this.limpiarPropiedades();
              this.$Fun.alertaAccion("success", response.data.message, false);
            });
        } /*else if (result.isDenied) {
          this.$Fun.alertaAccion("success", "No se eliminará", false);
        }*/
      });
    },
    async agregarPregunta(tipo, tiporta) {
      this.form_pregunta.idpregunta = 0;
      (this.form_pregunta.idproyecto = this.$route.params.id),
        (this.form_pregunta.pregunta = "");
      this.form_pregunta.tiporta = tiporta;
      this.form_pregunta.requerida = 0;
      this.form_pregunta.tipodato = tipo;
      this.form_pregunta.salto_where = "";
      this.form_pregunta.salto_rta = "";
      this.form_pregunta.asociada = "";
      this.form_pregunta.idrtawhere = "";
      this.form_pregunta.idriasevento = "";
      this.form_pregunta.defval = "";
      this.form_pregunta.inactivo = "";
      this.vAlerta = false;

      return await this.axios
        .post(configApi + "encuestas/preguntas", this.form_pregunta, {
          headers,
        })
        .then((response) => {
          this.getPreguntas();
          this.getUltimaPregunta();
        });
    },
    async preguntaSeleccionada(data) {
      window.localStorage.removeItem("selected");

      window.localStorage.setItem("selected", data.idpregunta);

      this.seleccionada = window.localStorage.getItem("selected");

      this.form_pregunta.respuestas = [];
      this.options_respuestas = [];

      this.form_pregunta.idpregunta = data.idpregunta;
      this.form_pregunta.idproyecto = data.idproyecto;
      this.form_pregunta.pregunta = data.pregunta;
      this.form_pregunta.tiporta = data.tiporta;
      this.form_pregunta.requerida = data.requerida;
      this.form_pregunta.tipodato = data.tipodato;
      this.form_pregunta.salto_where = data.salto_where;
      this.form_pregunta.salto_rta = data.idpregunta;
      this.form_pregunta.asociada = data.asociada;
      this.form_pregunta.idrtawhere = data.idrtawhere;
      this.form_pregunta.idriasevento = data.idriasevento;
      this.form_pregunta.defval = data.defval;
      this.form_pregunta.inactivo = data.inactivo;

      return await this.axios
        .get(configApi + "encuestas/preguntas/respuestas/" + data.idpregunta, {
          headers,
        })
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.form_pregunta.respuestas.push({
              idrtaopt: data[i].idrtaopt,
              idpregunta: data[i].idpregunta,
              opttexto: data[i].opttexto,
              defval: data[i].defval,
            });

            this.options_respuestas.push({
              id: data[i].idrtaopt,
              text: data[i].opttexto,
            });
          }

          this.getSaltosPregunta(data.idpregunta);
        });
    },
    _removerPosibleRespuesta(key) {
      this.form_pregunta.respuestas.splice(key, 1);
    },
    async eliminarRespuesta(idrtaopt) {
      return await this.axios
        .delete(configApi + "encuestas/preguntas/eliminar/" + idrtaopt, {
          headers,
        })
        .then((response) => {
          this.$Fun.alertaAccion("success", response.data.message, false);
          this.getPreguntas();
          this.getEncuesta();
        });
    },
    addRespuesta() {
      if (this.posible_respuesta != "") {
        this.form_pregunta.respuestas.push({
          idrtaopt: "",
          idpregunta: this.form_pregunta.idpregunta,
          opttexto: this.posible_respuesta,
          default: this.default_respuesta,
        });
        this.posible_respuesta = "";
        this.default_respuesta = "";
      } else {
        this.$Fun.alertaAccion("error", "Campo Vacío", false);
      }
    },
    async actualizarEncuesta() {
      return await this.axios
        .put(
          configApi + "encuestas/preguntas/" + this.form_pregunta.idpregunta,
          this.form_pregunta,
          { headers }
        )
        .then((response) => {
          this.$Fun.alertaAccion("success", response.data.message, false);
          this.getPreguntas();
          this.getEncuesta();
        });
    },
    async getGruposEventos() {
      return await this.axios
        .get(configApi + "grupo_eventos/grupos_eventos", { headers })
        .then((response) => {
          this.grupos_eventos = response.data.data;
        });
    },
    async addSaltoPregunta() {
      let formData = {
        salto_rta: this.form_pregunta.idpregunta,
        salto_where: this.salto_pregunta.salto_where,
        idrtawhere: this.salto_pregunta.idrtawhere,
        asociada: this.salto_pregunta.asociada,
      };

      return await this.axios
        .put(
          configApi +
            "encuestas/preguntas/salto_pregunta/" +
            this.form_pregunta.idpregunta,
          formData,
          { headers }
        )
        .then((response) => {
          this.salto_pregunta.salto_rta = "";
          this.salto_pregunta.salto_where = "";
          this.salto_pregunta.idrtawhere = "";
          this.salto_pregunta.asociada = "";

          this.getSaltosPregunta();
        });
    },
    async getSaltosPregunta() {
      return await this.axios
        .get(
          configApi +
            "encuestas/preguntas/salto_pregunta/" +
            this.form_pregunta.idpregunta,
          { headers }
        )
        .then((response) => {
          this.saltos_creados = response.data;
        });
    },
    async eliminarSaltoPregunta(idpregunta, key) {
      Swal.fire({
        title: "¿Está seguro de eliminar el salto de pregunta?",
        showDenyButton: true,
        confirmButtonText: "Eliminar",
        confirmButtonColor: "#4caf50",
        denyButtonText: `No Eliminar`,
        denyButtonColor: "#f44336",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .delete(
              configApi + "encuestas/preguntas/eliminar_salto/" + idpregunta,
              { headers }
            )
            .then((response) => {
              this.saltos_creados.splice(key, 1);
              this.getSaltosPregunta();
              this.$Fun.alertaAccion("success", response.data.message, false);
            });
        }
      });
    },
    async cambioPosicion(data, pos) {
      Swal.fire({
        title: "¿Está seguro de modificar el orden de esta pregunta?",
        showDenyButton: true,
        confirmButtonText: "Modificar",
        confirmButtonColor: "#4caf50",
        denyButtonText: `No Modificar`,
        denyButtonColor: "#f44336",
      }).then((result) => {
        if (result.isConfirmed) {
          data.new_posicion = data.orden + pos;

          this.axios
            .post(configApi + "encuestas/preguntas/ordenar_pregunta", data, {
              headers,
            })
            .then((response) => {
              this.$Fun.alertaAccion("success", response.data.message, false);
              this.getPreguntas();
            });
        } else if (result.isDenied) {
          this.$Fun.alertaAccion("success", "No se modificará", false);
        }
      });
    },
    async guardarOrden() {
      const obj = Object.assign({}, this.preguntas);

      this.axios
        .post(
          configApi +
            "encuestas/preguntas/save_ordenar/" +
            this.preguntas.length,
          obj,
          {
            headers,
          }
        )
        .then((response) => {
          if (response.data.success) {
            this.$Fun.alertaAccion("success", response.data.message, false);
            this.vOrdenar = false;
            this.getPreguntas();
          } else {
            this.$Fun.alertaAccion("error", "Orden no guardado", false);
          }
        });
    },
    limpiarPropiedades() {
      this.form_pregunta.respuestas = [];
      this.options_respuestas = [];

      this.form_pregunta.idpregunta = 0;
      this.form_pregunta.idproyecto = "";
      this.form_pregunta.pregunta = "";
      this.form_pregunta.tiporta = "";
      this.form_pregunta.requerida = 0;
      this.form_pregunta.tipodato = "";
      this.form_pregunta.salto_where = "";
      this.form_pregunta.salto_rta = "";
      this.form_pregunta.asociada = "";
      this.form_pregunta.idrtawhere = "";
      this.form_pregunta.idriasevento = "";
      this.form_pregunta.defval = "";
      this.form_pregunta.inactivo = "";

      this.saltos_creados = [];
    },
    showModal() {
      this.resetearModal();

      this.axios
        .get(
          configApi + "encuestas/encuestas/clonar/" + this.$route.params.id,
          { headers }
        )
        .then((response) => {
          let data = response.data.data;

          for (var i = 0, max = data.length; i < max; i += 1) {
            this.options_encuestas.push({
              id: data[i].idproyecto,
              text: data[i].nombreproyecto,
            });
          }
        });
    },
    resetearModal() {
      this.items_preguntas_clonar = []; //array de preguntas de la encuesta seleccionada
      this.preguntas_para_clonar = []; //array de preguntas seleccionadas para clonar
      this.options_encuestas = []; //array de encuestas cargadas en el select
      this.encuesta = ""; //v-model id escuesta seleccionada
      this.opciones_clonar = ""; //v-model opcion de clonado seleccionado
      this.vClonar = false;
    },
    clonarPreguntasEncuesta() {
      if (this.encuesta != "") {
        Swal.fire({
          title:
            "Se van a clonar las preguntas de la encuesta seleccionada, ¿Desea Continuar?",
          showDenyButton: true,
          confirmButtonText: "Clonar",
          confirmButtonColor: "#4caf50",
          denyButtonText: `Cancelar`,
          denyButtonColor: "#f44336",
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.opciones_clonar == 1) {
              this.axios
                .post(
                  configApi +
                    "encuestas/preguntas/save_clonar/" +
                    this.$route.params.id +
                    "/" +
                    this.encuesta,
                  this.encuesta,
                  {
                    headers,
                  }
                )
                .then((response) => {
                  if (response.status == 200) {
                    this.$bvModal.hide("clonarPreguntas");
                    this.vAlerta = false;
                    this.getPreguntas();
                    this.$Fun.alertaAccion(
                      "success",
                      "Preguntas Clonadas",
                      false
                    );
                  } else {
                    this.$Fun.alertaAccion(
                      "error",
                      "Preguntas No Clonadas",
                      false
                    );
                  }
                });
            } else if (this.opciones_clonar == 2) {
              if (this.preguntas_para_clonar.length > 0) {
                const objpreguntas = Object.assign(
                  {},
                  this.preguntas_para_clonar
                );
                this.axios
                  .post(
                    configApi +
                      "encuestas/preguntas/save_clonar_seleccionadas/" +
                      this.$route.params.id +
                      "/" +
                      this.preguntas_para_clonar.length,
                    objpreguntas,
                    {
                      headers,
                    }
                  )
                  .then((response) => {
                    if (response.status == 200) {
                      this.$bvModal.hide("clonarPreguntas");
                      this.vAlerta = false;
                      this.getPreguntas();
                      this.$Fun.alertaAccion(
                        "success",
                        "Preguntas Clonadas",
                        false
                      );
                    } else {
                      this.$Fun.alertaAccion(
                        "error",
                        "Preguntas No Clonadas",
                        false
                      );
                    }
                  });
              } else {
                this.$Fun.alertaAccion(
                  "warning",
                  "No existen preguntas seleccionadas para clonar",
                  false
                );
              }
            }
          }
        });
      }
    },
    clonarPregunta(idpregunta) {
      Swal.fire({
        title: "Se va a clonar la pregunta seleccionada, ¿Desea Continuar?",
        showDenyButton: true,
        confirmButtonText: "Clonar",
        confirmButtonColor: "#4caf50",
        denyButtonText: `Cancelar`,
        denyButtonColor: "#f44336",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              configApi +
                "encuestas/preguntas/clonar_pregunta/" +
                idpregunta +
                "/" +
                this.$route.params.id,
              this.encuesta,
              {
                headers,
              }
            )
            .then((response) => {
              this.$Fun.alertaAccion("success", response.data.message, false);
              this.getPreguntas();
            });
        }
      });
    },
    restablecerOrden() {
      this.vOrdenar = false;
      this.getPreguntas();
    },
    agregarPreguntaClonar(pregclon, evento, index) {
      if (evento.target.checked) {
        this.preguntas_para_clonar.push(pregclon);
      } else {
        for (
          var i = 0, max = this.preguntas_para_clonar.length;
          i < max;
          i += 1
        ) {
          if (this.preguntas_para_clonar[i].idpregunta == pregclon.idpregunta) {
            this.preguntas_para_clonar.splice(i, 1);
            break;
          }
        }
      }
    },
  },
};
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#border-pregunta {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1),
    0px 8px 8px 1px rgba(0, 0, 0, 0.07), 0px 3px 8px 2px rgba(0, 0, 0, 0.08),
    0px 0px 0px 2px rgb(55 64 255);
  transition: transform 200ms linear, box-shadow 200ms linear,
    background 400ms ease, border-color 400ms ease;
  border-color: rgb(55 64 255);
  background-color: #f7f7f7;
}
</style>
<style lang="scss">
#listPreguntas .sortable-drag {
  opacity: 0;
}

.flip-list-move {
  transition: 0.5s;
}

.ghost {
  border-left: 5px solid rgb(0, 136, 23) !important;
  box-shadow: 10px 10x 5px -1px rgba(0, 0, 0, 0.14) !important;
  opacity: 0.7;

  &::before {
    content: " ";
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: -50px;
  }
}

.btn-outline-warning:hover {
  color: #ffffff !important;
}
</style>
