var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card",staticStyle:{"max-width":"100%"}},[_vm._m(0),_c('div',{staticClass:"card-body text-dark"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-sm-5"},[_c('b-form-input',{staticClass:"col-sm-12",attrs:{"type":"search","placeholder":"Busqueda"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"float-right",attrs:{"role":"group","aria-label":"Basic example"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-info",staticStyle:{"margin-right":"30px"},attrs:{"title":"Actualizar Datos"},on:{"click":_vm.getSeguimientos}},[_c('i',{staticClass:"mdi mdi-replay"})]),_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-primary",attrs:{"to":'/encuestas/preguntas/vista-preguntas/0',"title":"Agregar Eventos"}},[_c('i',{staticClass:"mdi mdi-message-text-outline"})])],1)]),_c('div')]),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})],1):_vm._e(),_c('b-table',{staticClass:"table responsive table-bordered",attrs:{"stacked":"md","show-empty":"","sticky-header":"","small":"","per-page":_vm.perPage,"items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"filter":_vm.filter},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(row){return [(row.item.idproyecto > 0)?_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-success",attrs:{"to":'/seguimiento-profesional/crear/' +
                row.item.idpersona +
                '/' +
                row.item.idproyecto +
                '/0',"title":"Crear Seguimiento Paciente"}},[_c('i',{staticClass:"mdi mdi-plus-circle text-white"})]),_vm._v("  ")],1):_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-success",attrs:{"to":'/seguimiento-profesional/crear/' +
                row.item.idpersona +
                '/' +
                row.item.id_encsiv +
                '/1',"title":"Crear Seguimiento Paciente"}},[_c('i',{staticClass:"mdi mdi-plus-circle text-white"})]),_vm._v("  ")],1)]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('b',[_vm._v("Listar Seguimientos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-5"},[_c('br'),_c('br')])
}]

export { render, staticRenderFns }