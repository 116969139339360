<template>
  
  <Menu/>
  
</template>

<script>

import Menu from './components/Menu.vue'

export default {
  name: 'App',
  components: {
    Menu
  },
  data: () => ({}),
};
</script>
<style scped>

  .btn-add-vg {
    background-color: #002984 !important;
    color: white;
  }
  .btn-add-vg:hover {
    background-color: #002984 !important;
    color: white;
  }
  .btn-add-vg:checked {
    background-color: #002984 !important;
    color: white;
  }
  .btn-add-vg:active {
    background-color: #002984 !important;
    color: white;
  }
  
  .visible > .bar1 {
    background-color: black !important;
  }
  .visible > .bar2 {
    background-color: black !important;
  }
  .visible > .bar3 {
    background-color: black !important;
  }

</style>
