<template>
    <div>
        <b-card no-body>
            <b-tabs pills card>
                <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                    <component :is="item.component" :ref="item.component" />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>
<script>

import esta_generalencuestas from './esta_generalencuestas.vue'
import esta_seguienferme from './esta_seguienferme.vue'
import esta_covid from './esta_covid.vue'

export default {
    name: 'estadistica_vigilacontigo',
    components: {
        esta_generalencuestas,
        esta_seguienferme,
        esta_covid
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Estadísticas / General Encuesta', component: 'esta_generalencuestas' },
          { tab: 'Estadísticas / Seguimiento  Epidemiológico', component: 'esta_seguienferme' },
          { tab: 'Estadísticas / COVID 19', component: 'esta_covid' }
        ],
    })
}
</script>