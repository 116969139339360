<template>
    <div>
        <b-tabs content-class="mt-3" align="center">
            <b-tab :title="item.tab"  v-for="item in items" :key="item.tab">
                <component :is="item.component" :ref="item.component" />
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import geo_general from './geo_general.vue'
import geo_enfermedades from './geo_enfermedades.vue'
import geo_enfermedades_sivigila from './geo_enfermedades_sivigila.vue'

export default {
    name: 'listado_usuarios',
    components: {
        geo_general,
        geo_enfermedades,
        geo_enfermedades_sivigila
    },
    data: () => ({
        tab: null,
        items: [
          { tab: 'Georeferenciación / General Encuestas', component: 'geo_general' },
          { tab: 'Georeferenciación / General Eventos', component: 'geo_enfermedades' },
          { tab: 'Georeferenciación / General Eventos Sivigila', component: 'geo_enfermedades_sivigila' }
        ],
    })
}
</script>