<template>
  <div>
    <div class="row">
      <div class="card col-md-6">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <personasBarrios />
            </div>
          </div>
        </div>
      </div>
      <div class="card col-md-6">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <esta_personaseps />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12"><br /></div>
      <div class="card col-md-6">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <esta_personaszonas />
            </div>
          </div>
        </div>
      </div>
      <div class="card col-md-6">
        <div class="row no-gutters">
          <div class="col-md-12">
            <div class="card-body">
              <esta_personasregimen />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import personasBarrios from "./esta_personasbarrios.vue";
import esta_personaseps from "./esta_personaseps.vue";
import esta_personaszonas from "./esta_personaszonas.vue";
import esta_personasregimen from "./esta_personasregimen.vue";

export default {
  components: {
    personasBarrios,
    esta_personaseps,
    esta_personaszonas,
    esta_personasregimen,
  },
};
</script>
